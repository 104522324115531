import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../../Services/api';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import axios from 'axios';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import '../../../../../App/Components/Form/Forms_Apps/PicPay/css/PicPay.css';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    },
    pTempoRetirada:{
        marginBottom: '0',
        marginLeft: '.5rem',
    },
    divTempoPrazo:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: '100%'
    },

  }));

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        prefix="R$"
  
         format={currencyFormatter}
      />
    );
  }

  function retiradaPrazo(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
         format="##"
      />
    );
  }

  function currencyFormatter(value) {
    if (!Number(value)) return "";
  
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value / 100);
  
    return `${amount}`;
  }
  
  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  retiradaPrazo.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
  
 

export default function FormRetirarLocal() {
    const history = useHistory();
    const classes = useStyles();
    const [id, setId] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [name, setName] = React.useState('');
    const [statusApp, setStatusApp] = React.useState('');
    const [enableApp, setEnableApp] = React.useState('');
    const [checked, setChecked] = React.useState('');
    const [cep, setCep] = React.useState('');
    const [uf, setUf] = React.useState('');
    const [bairro, setBairro] = React.useState('');
    const [numero, setNumero] = React.useState('');
    const [cidade, setCidade] = React.useState('');
    const [cepfsm, setCepSm] = React.useState('');
    const [rua, setRua] = React.useState('');
    const [tempoEntrega, setTempoEntrega] = React.useState('');
    const [complemento, setComplemento] = React.useState('');
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [verificaVazioCep, setVerificaVazioCep] = React.useState(false);
    const [verificaVazioUF, setVerificaVazioUF] = React.useState(false);
    const [verificaVazioBairro, setVerificaVazioBairro] = React.useState(false);
    const [verificaVazioNumero, setVerificaVazioNumero] = React.useState(false);
    const [verificaVazioRua, setVerificaVazioRua] = React.useState(false);
    const [verificaVazioCidade, setVerificaVazioCidade] = React.useState(false);
    const [verificaVazioTempoEntrega, setVerificaTempoEntrega] = React.useState(false);
    const [verificaVazioAmount, setVerificaAmount] = React.useState(false);
    const [environment, setEnvironment] = React.useState('gratis');

    const [valor, setValor] = React.useState({
        numberformat: '',
      });

      const handleChange = (event) => {
        setValor({
          ...valor,
          [event.target.name]: event.target.value,
        });
      };
    
   function mostraStatus(){
    var alert = document.getElementById("AlertStatus");
       alert.style.display = 'block';
   }
   
   function removeStatus(){
    var alert = document.getElementById("AlertStatus");
       alert.style.display = 'none';
   }

   function checkEnable(){
    if(checked === true) {
        setChecked(false);
        setEnableApp(0);
    }
    else{
        setChecked(true);
        setEnableApp(1); 
    }
   }

  function cancelarApp(){
    history.push('/configuracoes-frete');
  }


  const capturaBairro = (prop) => (event) => {
    setBairro(event.target.value );
};

const capturaCep= (prop) => (event) => {
    setCep(event.target.value );
    let cepSm = event.target.value.replace(/\D/g, '');
    setCepSm(cepSm);
};

const capturaUF = (prop) => (event) => {
    setUf(event.target.value );
};
const capturaRua = (prop) => (event) => {
    setRua(event.target.value );
};
const capturaNumero = (prop) => (event) => {
    setNumero(event.target.value );
};
const capturaCidade = (prop) => (event) => {
    setCidade(event.target.value );
};
const capturaComplemento = (prop) => (event) => {
    setComplemento(event.target.value );
};
const capturaTempoEntrega = (prop) => (event) => {
  setTempoEntrega(event.target.value );
};


async function buscaApp(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/shipping/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
        for(let i = 0; dados.length > i; i++){
            if(dados[i].slug === 'retirar-local'){
                setId(dados[i].slug);
                setPhoto(dados[i].photo);
                setName(dados[i].name);
                setStatusApp(dados[i].authenticated);
                setEnableApp(dados[i].enable);
                setChecked(dados[i].enable);
                buscaDadosApp(dados[i].credentials);
                var amount = dados[i].credentials.shipping.amount;
                setValor({numberformat: `${amount}`})
            if(dados[i].postal_code!==null){  
                setCep(dados[i].postal_code_mask);
              }
              
        }
    }
    })
    .catch((error) => {

    })
}
 
  useEffect(() => {
    buscaApp();
  },[]) 

    async function enviaFormApp(event){
    event.preventDefault();
    var ceP = cep.replace(/[^\d]+/g, '');

    var amount = '';
    if(environment==='gratis'){
        amount = 0;
    }else{
        amount = valor.numberformat
    }

        var data = {
            "enable": enableApp,
            "amount": amount,
            "delivery_time": tempoEntrega,
            "company_id" : idCompanies,
            "integration_slug" : "retirar-local",
            "street" : rua,
            "number" : numero,
            "city" : cidade,
            "postal_code" : ceP,
            "neighborhood" : bairro,
            "state" : uf,
            "complement" : complemento
        }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
          if(amount > 99 && environment==='pago'){
          if(tempoEntrega !=='' && amount !=='' && uf !=='' && bairro !=='' && numero !=='' && cidade  !=='' && rua !=='' && cep !==''){
            await api.post('/v1/integrations/shipping', data, {
                headers: headers
              })
              .then((response) => {
                setMessageErro('');
                setMessageSuccess('Informações salvas com sucesso');
                setTimeout(() => {
                    setMessageSuccess('');
              }, 1500);
                setTimeout(() => {
                history.push('/configuracoes-frete');
            }, 2000);
              })
              .catch((error) => {
                setMessageSuccess('');
                  setMessageErro(error.response.data.errors.message);
                  setTimeout(() => {
                    setMessageErro('');
              }, 1500);
              })
            }else{
                if(bairro ==='' ){
                    setVerificaVazioBairro(true);
                } 
                if(uf ===''){
                    setVerificaVazioUF(true);
                }
                if(numero ===''){
                    setVerificaVazioNumero(true);
                }
                if(cidade ===''){
                    setVerificaVazioCidade(true);
                }
                if(rua ===''){
                    setVerificaVazioRua(true);
                }
                if(cep ===''){
                    setVerificaVazioCep(true);
                }
                if(tempoEntrega ===''){
                    setVerificaTempoEntrega(true);
                }
                if(valor ===''){
                    setVerificaAmount(true);
                }
                return true;
            }
        } else if(environment!=='pago'){
                if(tempoEntrega !=='' && amount !=='' && uf !=='' && bairro !=='' && numero !=='' && cidade  !=='' && rua !=='' && cep !==''){
                  await api.post('/v1/integrations/shipping', data, {
                      headers: headers
                    })
                    .then((response) => {
                      setMessageErro('');
                      setMessageSuccess('Informações salvas com sucesso');
                      setTimeout(() => {
                          setMessageSuccess('');
                    }, 1500);
                      setTimeout(() => {
                      history.push('/configuracoes-frete');
                  }, 2000);
                    })
                    .catch((error) => {
                      setMessageSuccess('');
                        setMessageErro(error.response.data.errors.message);
                        setTimeout(() => {
                          setMessageErro('');
                    }, 1500);
                    })
                  }else{
                      if(bairro ==='' ){
                          setVerificaVazioBairro(true);
                      } 
                      if(uf ===''){
                          setVerificaVazioUF(true);
                      }
                      if(numero ===''){
                          setVerificaVazioNumero(true);
                      }
                      if(cidade ===''){
                          setVerificaVazioCidade(true);
                      }
                      if(rua ===''){
                          setVerificaVazioRua(true);
                      }
                      if(cep ===''){
                          setVerificaVazioCep(true);
                      }
                      if(tempoEntrega ===''){
                          setVerificaTempoEntrega(true);
                      }
                      if(valor ===''){
                          setVerificaAmount(true);
                      }
                      return true;
                  }
        }else{
            setMessageSuccess('');
                  setMessageErro('O valor para retirada precisa ser no mínimo R$ 1,00 real.');
                  setTimeout(() => {
                    setMessageErro('');
              }, 1500);
        }
  }

 function buscaDadosApp(credentials){


    setUf(credentials.shipping.address.state);
    setBairro(credentials.shipping.address.neighborhood);
    setNumero(credentials.shipping.address.number);
    setCidade(credentials.shipping.address.city);
    setRua(credentials.shipping.address.street); 
    setComplemento(credentials.shipping.address.complement);  
    setTempoEntrega(credentials.shipping.delivery_time);  
 
    }
  

    function clickRadio(radio){
        if(radio ==='dev'){
            setEnvironment('gratis');
        }else{
            setEnvironment('pago');
        }
       }

// via Cep
function limpa_formulário_cep() {
    //Limpa valores do formulário de cep.
    setRua('');
    setBairro('');
    setUf('');
    setCidade('');
}

function meu_callback(conteudo) {
    //Atualiza os campos com os valores.
    setRua(conteudo.data.logradouro);
    setBairro(conteudo.data.bairro);
    setUf(conteudo.data.uf);
    setCidade(conteudo.data.localidade);
} 
 
const pesquisacep= (prop) => (event) => {

//Nova variável "cep" somente com dígitos.
var cep = event.target.value.replace(/\D/g, '');

//Verifica se campo cep possui valor informado.
if (cep != "") {

    //Expressão regular para validar o CEP.
    var validacep = /^[0-9]{8}$/;

    //Valida o formato do CEP.
    if(validacep.test(cep)) {

        //Preenche os campos com "..." enquanto consulta webservice.
        setRua('...');
        setBairro('...');
        setUf('...');
        setCidade('...');

        //chama a api do via cep
        dadosViaCep();

    } //end if.
    else {
        //cep é inválido.
        limpa_formulário_cep();
       setMessageErro('Formato de CEP inválido.');
       setMessageSuccess('');
       setTimeout(() => {
        setMessageErro('');
  }, 1500);
    }
} //end if.
else {
    //cep sem valor, limpa formulário.
    limpa_formulário_cep();
}
};

async function dadosViaCep(){
   await axios.get('https://viacep.com.br/ws/'+ cepfsm + '/json')
      .then((response) => {
      meu_callback(response)
      })
      .catch((error) => {
            //CEP não Encontrado.
    limpa_formulário_cep();
    setMessageErro('CEP não encontrado.');
    setMessageSuccess('');
    setTimeout(() => {
        setMessageErro('');
  }, 1500);
      })
}

const mascaraCEP = (prop) =>  (object) => {
  
    var cnpj = object.target;
            if(cnpj.value.length == 5)
                cnpj.value = cnpj.value + '-'; 
}


return (
    <div className="Apps-form-style">
        <div className="App-suporte">
            <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft}/></a>
        </div>
    <div className="App-form-style">
    <form onSubmit={enviaFormApp} className="PicPay-form" noValidate autoComplete="off">
        <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
            <div className={checked ? "App-circle-status App-circle-status-true": "App-circle-status"} onMouseEnter={mostraStatus} onMouseOut={removeStatus}></div>
                <img className="PicPay-logo" src={photo}/>
                    <h1 className="PicPay-title">{name}</h1>
            </div>
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    onClick={checkEnable}
                    value={enableApp}
                    checked={checked}
                    control={<Switch color="secondary" />}
                    label="Ativo:"
                    labelPlacement="start"
                />
            </FormGroup>
        </div>
        <div id="AlertStatus" className="AlertStatus">
            { checked ?
             <Alert variant="filled" severity="success">
                Status: Ativo
            </Alert>
                :
            <Alert variant="filled" severity="error">
                Status: Inativo
            </Alert>
            }
        </div>
        <div className="PicPay-link-tutoriais">
        <p className="PicPay-p-form">Informações da aplicação:</p>
            <a target="_blank" href="https://blog.payhow.com.br/2021/04/13/configuracoes-de-frete-para-retirada-diretamente-na-sua-loja-fisica/">Como obter as informações da Retirada no Local?</a>
        </div>
        {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                     {messageSuccess!== ''?
                     <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                     :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                    <div className="PicPay-envolve-radio-teste-prod">
                    <h4 className="mt-2">1. Preencha os detalhes da retirada:</h4>
                    <p>A retirada será gratuita ou paga?</p>
                    <RadioGroup  aria-label="position" className="radio-grupo" name="position" defaultValue="top">
                        <FormControlLabel
                        className="mr"
                            onClick={()=>clickRadio('dev')}
                            name="environment"
                            checked={environment === 'gratis' ? true : false}
                            value="gratis" control={<Radio color="secondary" />} 
                            label="Gratuita"
                        />
                        <FormControlLabel 
                            onClick={()=>clickRadio('prod')}
                            name="environment"
                            checked={environment === 'pago' ? true : false} 
                            value="pago" 
                            control={<Radio color="secondary" />} 
                            label="Paga" 
                        />
                    </RadioGroup>
                    </div>
                    {environment==='pago'?
                    <TextField
                        className={clsx(classes.textStyle)} 
                        color="secondary"
                        label="Digite o valor que será cobrado para retirada"
                        value={valor.numberformat}
                        onChange={handleChange}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined" 
                        error={verificaVazioAmount ? true:false}
                        helperText={verificaVazioAmount ?"*Campo obrigatório":''}
                        InputProps={{
                        inputComponent: NumberFormatCustom,
                        }}
                    />
                    :''}
                    <div className={classes.divTempoPrazo}>
                        <TextField
                            className={clsx(classes.textStyle)} 
                            color="secondary"
                            id="tempoEntrega"
                            label="Prazo mínimo para retirada"
                            type="tel"
                            value={tempoEntrega}  
                            onChange={capturaTempoEntrega()}
                            variant="outlined" 
                            required
                            InputProps={{
                                inputComponent: retiradaPrazo,
                            }}
                            error={verificaVazioTempoEntrega && tempoEntrega ==='' ? true:false}
                            helperText={verificaVazioTempoEntrega && tempoEntrega ==='' ?"*Campo obrigatório":''}
                            /> 
                            <p className={classes.pTempoRetirada}>dia(s)</p>
                    </div>
                    <h4 className="mt-2">2. Preencha os dados do endereço:</h4>
                    <TextField
                        className={clsx(classes.textStyle)} 
                        color="secondary" 
                        id="cep" 
                        label="CEP" 
                        type="tel" 
                        maxLength = "9"
                        inputProps ={{maxLength:9}}
                        onKeyPress={mascaraCEP()}
                        value={cep} 
                        onChange={capturaCep()} 
                        onBlur={pesquisacep()}
                        variant="outlined" 
                        required
                        error={verificaVazioCep ? true:false}
                        helperText={verificaVazioCep && cep ==='' ?"*Campo obrigatório":''}
                        helperText={verificaVazioCep && cep !=='' ?"*CEP inválido":''}
                        />
                            <TextField
                        className={clsx(classes.textStyle,classes.inputEstado)} 
                        color="secondary"
                        id="estado"
                        label="UF"
                        type="text"
                        value={uf} 
                        onChange={capturaUF}
                        variant="outlined" 
                        required
                        disabled
                        error={verificaVazioUF ? true:false}
                        helperText={verificaVazioUF && uf ==='' ?"*Campo obrigatório":''}
                         />
                            <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="bairro"
                        label="Bairro"
                        type="text"
                        value={bairro}  
                        onChange={capturaBairro()}
                        variant="outlined" 
                        required
                        error={verificaVazioBairro && bairro ==='' ? true:false}
                        helperText={verificaVazioBairro && bairro ==='' ?"*Campo obrigatório":''}
                         /> 
                                 <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="cidade"
                        label="Cidade"
                        type="text"
                        value={cidade}  
                        onChange={capturaCidade()}
                        variant="outlined" 
                        required
                        disabled = {cidade !=='' ? true:false} 
                        error={verificaVazioCidade && cidade ==='' ? true:false}
                        helperText={verificaVazioCidade && cidade ==='' ?"*Campo obrigatório":''}
                         />
                                  <TextField
                        className={clsx(classes.textStyle)} 
                        color="secondary" 
                        id="rua" 
                        label="Rua" 
                        type="text"
                        value={rua} 
                        onChange={capturaRua()} 
                        variant="outlined" 
                        required
                        error={verificaVazioRua ? true:false}
                        helperText={verificaVazioRua && rua ==='' ?"*Campo obrigatório":''}
                        />
                            <TextField
                        className={clsx(classes.textStyle,classes.inputEstado)} 
                        color="secondary"
                        id="numero"
                        label="Número"
                        type="tel"
                        value={numero} 
                        onChange={capturaNumero()}
                        variant="outlined" 
                        required
                        error={verificaVazioNumero ? true:false}
                        helperText={verificaVazioNumero && numero ==='' ?"*Campo obrigatório":''}
                         />  
                        <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="complemento"
                        label="Complemento"
                        type="text"
                        value={complemento}  
                        onChange={capturaComplemento()}
                        variant="outlined" 
                         />
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button variant="contained" onClick={cancelarApp}>Cancelar</Button>
                <Button type="submit" variant="contained" color="secondary">Salvar informações</Button>
            </div>
        </form>
    </div>
    </div>
);
}