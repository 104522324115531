import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import api from '../../../Services/api';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Logo from '../../../Assets/images/logo.fw.svg';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    },
    formControl: {
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


export default function FormPicPay(InfoApp) {
    const classes = useStyles();
    const [agencia, setAgencia] = React.useState('');
    const [agencia_id, setAgenciaId] = React.useState('');
    const [conta, setConta] = React.useState('');
    const [cpf, setCpf] = React.useState('');
    const [cnpj, setCnpj] = React.useState('');
    const [digito, setDigito] = React.useState('');
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [verificaVazioAgencia, setVerificaVazioAgencia] = React.useState(false);
    const [verificaVazioConta, setVerificaVazioConta] = React.useState(false);
    const [verificaVazioCpf, setVerificaVazioCpf] = React.useState(false);
    const [verificaVazioCnpj, setVerificaVazioCnpj] = React.useState(false);
    const [verificaVazioDigito, setVerificaVazioDigito] = React.useState(false);
    const [environment, setEnvironment] = React.useState('cpf');
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [banco, setBanco] = React.useState('');
    const [cnpjsm, setCnpjSm] = React.useState('');
    const [cpfsm, setCpfSm] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');

    const handleChange = (event) => {
      setBanco(event.target.value);
    };

    const capturaAgencia = (prop) => (event) => {
        setAgencia(event.target.value );
    };
    const capturaConta = (prop) => (event) => {
        setConta(event.target.value );
    };
    const capturaCpf = (prop) => (event) => {
        setCpf(event.target.value );
        let cpfsm = event.target.value.replace(/[^\d]+/g, '');
        setCpfSm(cpfsm);
    };
    const capturaCnpj = (prop) => (event) => {
        setCnpj(event.target.value );
        let cnpjsm = event.target.value.replace(/[^\d]+/g, '');
        setCnpjSm(cnpjsm);
    };
    const capturaDigito = (prop) => (event) => {
        setDigito(event.target.value );
    };

    const handleClose = () => {
        setOpen(false);
      };

  function pegaToken(){
    setToken(localStorage.getItem('tokenUser'));
    setIdCompanies(localStorage.getItem('companiesId'));
    if(token !== null && idCompanies !== null){
        getDadosBanco()
    }
  } 

  useEffect(() => {
    setTimeout(() => {
      pegaToken();
    },200);
  },[idCompanies]) 
  
async function enviaDadosBanc(event){
    event.preventDefault();
    var data = '';
    if(cpf !== '' && cpf.length >1){
        verificaCPF()
     data = {
        "bank_account_id": agencia_id,
        "bank": banco,
        "agency": agencia,
        "account": conta,
        "account_digit": digito,
        "document": cpfsm,
    }
}else{
    ValidarCNPJ()
    data = {
        "bank_account_id": agencia_id,
        "bank": banco,
        "agency": agencia,
        "account": conta,
        "account_digit": digito,
        "document": cnpjsm,
    } 
}

    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
      }
      if(verificaVazioCnpj !== false || verificaVazioCpf !== false){
      if(conta !=='' &&  agencia !=='' && digito !=='' && cpf !=='' || cnpj !==''){
    await api.put('/v1/customers/company/' + idCompanies + '/bank_account', data, {
        headers: headers
      })
      .then((response) => {
        setMessageSuccess('Dados bancários atualizado com sucesso');
        setTimeout(() => {
            setMessageSuccess('');
      }, 1500);
      })
      .catch((error) => {
        setMessageErro(error.response.data.errors.message.document[0]);
        setTimeout(() => {
            setMessageErro('');
      }, 1500);

      })
    }else{
        if(conta  ==='' ){
            setVerificaVazioConta(true);
        } 
        if(agencia ===''){
            setVerificaVazioAgencia(true);
        }
        if(digito ===''){
            setVerificaVazioDigito(true);
        }
        return true;
    }
}
}
async function getDadosBanco(){
      const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
      }
        await api.get('/v1/customers/company/'+idCompanies+'/bank_account', {
            headers: headers
        })
        .then((response) => {
            if(response.data.agency && response.data.agency !=='' && response.data.agency !== null && response.data.agency!= undefined){
            setAgencia(response.data.agency);
            }
            if(response.data.id && response.data.id !=='' && response.data.id !== null && response.data.id!= undefined){
            setAgenciaId(response.data.id);
            }
            if(response.data.account && response.data.account !=='' && response.data.account !== null && response.data.account!= undefined){
            setConta(response.data.account);
            }
            if(response.data.account_digit && response.data.account_digit !=='' && response.data.account_digit !== null && response.data.account_digit!= undefined){
            setDigito(response.data.account_digit);
            }
            if(response.data.bank && response.data.bank !=='' && response.data.bank !== null && response.data.bank!= undefined){
            setBanco(response.data.bank);
            }else{
                setBanco('Banco Inter');
            }
            if(response.data.document && response.data.document !=='' && response.data.document !== null && response.data.document!= undefined){
            if(response.data.document.length <12){
                setCpfSm(response.data.document_mask);
                setCpf(response.data.document_mask);
                setEnvironment('cpf');
            }
            if(response.data.document.length >12){
            setCnpjSm(response.data.document_mask);
            setCnpj(response.data.document_mask);
            setEnvironment('cnpj');
            }
        }
            return true;
        })
}
async function solicitarSaldo(){
    const data = {}
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
      }
        await api.post('/v1/company/'+idCompanies+'/balance',data, {
            headers: headers
        })
        .then((response) => {
            setOpen(true);
            setMessage('Solicitação de saldo disponível para saque');
        })
        .catch((error) => {
            setMessageErro('Erro ao solicitar saldo disponível para saque. Tente mais tarde!');
            setTimeout(() => {
                setMessageErro('');
          }, 1500);
        })
}
async function solicitarSaque(){
    const data = {};
    const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
      await api.post('/v1/company/'+idCompanies+'/cashout',data, {
          headers: headers
      })
      .then((response) => {
        setOpen(true);
        setMessage('Solicitação de saque');
      })
      .catch((error) => {
        setMessageErro('Erro ao solicitar saque. Tente mais tarde!');
        setTimeout(() => {
            setMessageErro('');
      }, 1500);
      })
}



const mascaraCPF = (prop) =>  (object) => {
  
    var cpf = object.target;
    if(cpf.value.length == 3)
    cpf.value = cpf.value + '.';
            if(cpf.value.length == 7)
                cpf.value = cpf.value + '.'; 
            if(cpf.value.length == 11)
                cpf.value = cpf.value + '-';
}

const mascaraCNPJ = (prop) =>  (object) => {
  
    var cnpj = object.target;
    if(cnpj.value.length == 2)
    cnpj.value = cnpj.value + '.';
            if(cnpj.value.length == 6)
                cnpj.value = cnpj.value + '.'; 
            if(cnpj.value.length == 10)
                cnpj.value = cnpj.value + '/';
                if(cnpj.value.length == 15)
                cnpj.value = cnpj.value + '-';
}


const maxLengthCheck = (prop) =>  (object) => {
    if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
    }


    function clickRadio(radio){
        if(radio ==='cpf'){
            setEnvironment('cpf');
            setCnpj('');
        }else{
            setEnvironment('cnpj');
            setCpf('');
        }
       }

       function TestaCPF(strCPF) {
        var Soma;
        var Resto;
        var i;
        Soma = 0;
        if (strCPF == "00000000000") return false;

        for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }
    function verificaCPF() {
        var retorno = TestaCPF(cpfsm);
        if (retorno === false) {
            setVerificaVazioCpf(true);
        } else {
            setVerificaVazioCpf(false);
        }
    }
    function ValidarCNPJ() {
        var i;
        var valida = new Array(6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
        var dig1 = new Number;
        var dig2 = new Number;
        var digito = new Number(eval(cnpjsm.charAt(12) + cnpjsm.charAt(13)));
        for (i = 0; i < valida.length; i++) {
            dig1 += (i > 0 ? (cnpjsm.charAt(i - 1) * valida[i]) : 0);
            dig2 += cnpjsm.charAt(i) * valida[i];
        }
        dig1 = (((dig1 % 11) < 2) ? 0 : (11 - (dig1 % 11)));
        dig2 = (((dig2 % 11) < 2) ? 0 : (11 - (dig2 % 11)));
        if (((dig1 * 10) + dig2) != digito) {
            setVerificaVazioCnpj(true);
        } else {
            setVerificaVazioCnpj(false);
        }
    }

return (
    <div className="Apps-form-style">
    <div className="App-form-style">
        <div className="Dados-banc-links-title">
        <h2 className="title-abas">Dados Bancários</h2>
            <div  className="Dados-banc-links">
            <a href="#" onClick={solicitarSaldo}>Solicitar saldo disponível para saque</a>
            <a href="#" onClick={solicitarSaque}>Solicitar saque</a>
            </div>
        </div>
    <form onSubmit={enviaDadosBanc} className="FormMeusDados" noValidate autoComplete="off">
    {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                       {messageSuccess !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                    :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                     <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel color="secondary" id="demo-simple-select-outlined-label">Banco</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={banco}
                            onChange={handleChange}
                            label="Banco"
                            color="secondary"
                        >
                        <MenuItem value="Banco Inter">Banco Inter</MenuItem>
                        <MenuItem value="Nubank">Nubank</MenuItem>
                        <MenuItem value="Itau">Itau</MenuItem>
                        <MenuItem value="Banco do Brasil">Banco do Brasil</MenuItem>
                        <MenuItem value="Bradesco">Bradesco</MenuItem>
                        <MenuItem value="Caixa Economica">Caixa Econômica</MenuItem>
                        <MenuItem value="Safra">Safra</MenuItem>
                        <MenuItem value="Santander">Santander</MenuItem>
                        <MenuItem value="Sicredi">Sicredi</MenuItem>
                        <MenuItem value="Bancoob">Bancoob</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="agencia"
                        label="Agência"
                        type="number"
                        value={agencia}  
                        onChange={capturaAgencia()}
                        variant="outlined" 
                        maxLength = "3"
                        inputProps ={{maxLength:3}}
                        onKeyPress={maxLengthCheck()}
                        required
                        error={verificaVazioAgencia && agencia ==='' ? true:false}
                        helperText={verificaVazioAgencia && agencia ==='' ?"*Campo obrigatório":''}
                         />
                    <TextField
                        className={classes.textStyle,"maiorInput"} 
                        color="secondary" 
                        id="conta" 
                        label="Conta" 
                        type="number" 
                        value={conta} 
                        onChange={capturaConta()} 
                        variant="outlined" 
                        maxLength = "6"
                        inputProps ={{maxLength:6}}
                        onKeyPress={maxLengthCheck()}
                        required
                        error={verificaVazioConta && conta ==='' ? true:false}
                        helperText={verificaVazioConta && conta ==='' ?"*Campo obrigatório":''}
                        />
                         <TextField
                        className={classes.textStyle,"menorInput"} 
                        color="secondary" 
                        id="digito" 
                        label="Dígito" 
                        type="number" 
                        maxLength = "0"
                        inputProps ={{maxLength:0}}
                        onKeyPress={maxLengthCheck()}
                        value={digito} 
                        onChange={capturaDigito()} 
                        variant="outlined" 
                        required
                        error={verificaVazioDigito && digito ==='' ? true:false}
                        helperText={verificaVazioDigito && digito ==='' ?"*Campo obrigatório":''}
                        />
                     <div className={classes.textStyle}>
                        <FormControlLabel
                            onClick={()=>clickRadio('cpf')}
                            name="environment"
                            checked={environment === 'cpf' ? true : false}
                            value="cpf"
                            control={<Radio color="secondary" />} 
                            label="CPF"
                        />
                        <FormControlLabel 
                            onClick={()=>clickRadio('cnpj')}
                            name="environment"
                            checked={environment === 'cnpj' ? true : false} 
                            value="cnpj" 
                            control={<Radio color="secondary" />} 
                            label="CNPJ" 
                        />
              </div>
                    {environment === 'cpf' ?
                            <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="cpf" 
                        label="CPF" 
                        type="tel" 
                        maxLength = "14"
                        inputProps ={{maxLength:14}}
                        onKeyPress={mascaraCPF()}
                        value={cpf} 
                        onChange={capturaCpf()} 
                        variant="outlined" 
                        required
                        error={verificaVazioCpf ? true:false}
                        helperText={verificaVazioCpf && cpf ==='' ?"*Campo obrigatório":''}
                        helperText={verificaVazioCpf && cpf !=='' ?"*CPF inválido":''}
                        />
                        :
                           <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="cnpj" 
                        label="CNPJ" 
                        type="tel" 
                        maxLength = "18"
                        inputProps ={{maxLength:18}}
                        onKeyPress={mascaraCNPJ()}
                        value={cnpj} 
                        onChange={capturaCnpj()} 
                        variant="outlined" 
                        required
                        error={verificaVazioCnpj ? true:false}
                        helperText={verificaVazioCnpj && cnpj ==='' ?"*Campo obrigatório":''}
                        helperText={verificaVazioCnpj && cnpj !=='' ?"*CNPJ inválido":''}
                        />
}
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button type="submit" variant="contained" color="secondary">Salvar alterações</Button>
            </div>
        </form>
    </div>
    {/* Modal solicitação de saque e solicitação de dados */}
    <div>
      <Dialog
      className="modal-app"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"><img src={Logo}/></DialogTitle>
        <DialogContent>
        <DialogTitle id="alert-dialog-slide-title">{message}</DialogTitle>
          <DialogContentText id="alert-dialog-slide-description">
            Um e-mail com as informações solicitadas será enviado em breve.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" onClick={handleClose} >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </div>
);
} 