import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import clsx from  'clsx';
import CheckIcon from '@material-ui/icons/Check';
import api from '../../../../../Services/api';
import ImgAme from '../../../../../Assets/images/pay/ame_-1.png';
import ImgPicPay from '../../../../../Assets/images/pay/pic_pay_fundo_branco.png';
import ImgMercadoPago from '../../../../../Assets/images/pay/mercado_pago_fundo_branco_-1.png';
import ImgPix from '../../../../../Assets/images/pay/pix_fundo_branco.png';
import ImgElo from '../../../../../Assets/images/pay/pagar_me_fundo_branco_.png';
import ImgVisa from '../../../../../Assets/images/pay/pagseguro_fundo_branco.png';
import ImgMaster from '../../../../../Assets/images/pay/cielo_fundo_branco_.png';
import ImgHiper from '../../../../../Assets/images/pay/google_an_fundo_branco_.png';
import ImgPayPal from '../../../../../Assets/images/pay/paypal_fundo_branco-1.png';
import ImgBoleto from '../../../../../Assets/images/pay/facebook_pixel_fundo_branco.png';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  checkIcon: {
   color: "green",
  },
  buttonCP:{
    height: "40px",
    width: "80%",
  },
  buttonDisabled:{
    textDecoration: "line-through",
    color: "rgb(255 255 255 / 86%) !important",
    backgroundColor: "#f5005785 !important"
},
cardPlanoAtual: {
  backgroundColor:"#efefef",
}
}));


export default function ModalPlanos(props) {
  const history = useHistory();  
  const [openPlanos, setOpenPlanos] = useState(false);
  const classes = useStyles();
  const[infoPlanos,setInfoPlanos] = useState('');
  const [token, setToken] = useState(localStorage.getItem('tokenUser'));
  const [state, setState] = React.useState({
    plano: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  async function getDadosPlano(){
    const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
      await api.get('/v1/plans', {
          headers: headers
      })
      .then((response) => {

        var dados = response.data;
        setInfoPlanos(dados);
      }) 
  }
  useEffect(() => {
    getDadosPlano();
  },[]) 

  useEffect(() => {
    setOpenPlanos(props.open);
},[props.open])

  function contratarPlano(id){
    history.push(`/contratar-plano?id=${id}`); 
  }

  return (
    <div>
    <div className="modal-planos">
          <h4>Ideal para pequenas empresas de 1 até 10 lojas virtuais</h4>
          <FormGroup row>
            <p className="p-switch-mes">Mensal</p>
            <FormControlLabel
              control={<Switch checked={state.plano} onChange={handleChange} name="plano" />}
              label="Anual"
            />
          </FormGroup>
          <div className="envolve-cards">
          {infoPlanos !== ''?
        infoPlanos.map((infoPlano) => ( 
          <div className={infoPlano.name !=='freelancers' && infoPlano.name !=='agency'?"envolve-card":''}>
            {infoPlano.name !=='freelancers' && infoPlano.name !=='agency'?
            <div className={infoPlano.current?clsx("card-plano",classes.cardPlanoAtual):"card-plano"}>
              {infoPlano.current?
              <div className="back-card-plano-atual">
                <h4 className="card-plano-atual">SEU PLANO ATUAL</h4>
              </div>
                :
                <div className="back-card-plano-natual">
              </div>
                }
              <div className="card-plano-padding">
              <div className="card-plano-img-title">
              <h2 className="h2-item-plano">{infoPlano.name ==='free' ? 'GRATUITO': 
                    infoPlano.name ==='pro' ? 'PRO' : 
                    infoPlano.name ==='plus'? 'PLUS' :
                    infoPlano.name ==='basic'? 'BASIC' :''}</h2>
                    <h3 className="qtd-loja-plan">{infoPlano.name ==='free' ? '1 Loja Virtual': 
                    infoPlano.name ==='pro' ? 'Até 10 Lojas Virtuais' : 
                    infoPlano.name ==='plus'? 'Até 3 Lojas Virtuais' :
                    infoPlano.name ==='basic'? '1 Loja Virtual' :''}</h3>
                    {!state.plano?
                     <h2 className="preco-plano">
                       {'R$'} 
                       <span className="destaque-preco-plano">
                         {infoPlano.price_monthly === 0? '0': infoPlano.price_monthly_mask}</span>
                         {'/mês'} </h2>
                    :
                    <h2 className="preco-plano">
                    {'R$'} 
                    <span className="destaque-preco-plano">
                      {infoPlano.price_monthly === 0? '0': infoPlano.price_yearly_mask}</span>
                      {'/ano'} </h2>
                    }
              <Button onClick={()=>contratarPlano(infoPlano.id)} disabled={infoPlano.current? true : infoPlano.name === 'free'? true:false}  className={
                infoPlano.current? clsx(classes.buttonCP,classes.buttonDisabled)
                : infoPlano.name ==='free' ? clsx(classes.buttonCP,classes.buttonDisabled)
                :classes.buttonCP}
                 variant="contained" color="secondary">Contratar plano</Button>
              </div>
              {infoPlano.name ==='free' ? 
              <div className="envolve-item-plano">
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Woocommerce</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Melhor Envio</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Grátis"</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Fixo"</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Personalizado"</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Método de frete com Correios</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Método de frete com "Retirada na loja física"</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Mercado pago</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Mercado pago QR Code</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Pagseguro (Cartões e Boletos)</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Google Analytics</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Facebook Pixel</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Suporte técnico básico por e-mail</p></div>
                  <div className="mt-5">
                    <div className="div-flex-logos">
                      <div className="div-img-logos">
                        <img src={ImgAme}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgPicPay}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgMercadoPago}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgPayPal}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgPix}/>
                      </div>
                    </div>
                    <div className="div-flex-logos div-sec">
                      <div className="div-img-logos">
                        <img src={ImgVisa}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgMaster}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgElo}/>
                      </div>
                      <div className="div-img-logos"> 
                        <img src={ImgHiper}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgBoleto}/>
                      </div>
                    </div>
                  </div>
                  <div className="item-plano"><p className="p-comissao"> 2,99% de comissão sob as vendas</p></div>
              </div>
               : infoPlano.name ==='basic' ?
               <div className="envolve-item-plano">
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Woocommerce </p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Melhor Envio</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Grátis"</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Fixo"</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Personalizado"</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Método de frete com Correios</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Método de frete com "Retirada na loja física"</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Mercado pago</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Mercado pago QR Code</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Pagar.me (Cartões e Boleto)</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Pagseguro (Cartões e Boletos)</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Cielo</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Picpay</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>AME Digital</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Google Analytics</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Facebook Pixel</p></div>
                  <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p><strong>Suporte técnico completo por e-mail, chat ou telefone</strong></p></div>
                 <div className="mt-5">
                     <div className="div-flex-logos">
                       <div className="div-img-logos">
                         <img src={ImgAme}/>
                       </div>
                       <div className="div-img-logos">
                         <img src={ImgPicPay}/>
                       </div>
                       <div className="div-img-logos">
                         <img src={ImgMercadoPago}/>
                       </div>
                       <div className="div-img-logos">
                         <img src={ImgPayPal}/>
                       </div>
                       <div className="div-img-logos">
                         <img src={ImgPix}/>
                       </div>
                     </div>
                     <div className="div-flex-logos div-sec">
                       <div className="div-img-logos">
                         <img src={ImgVisa}/>
                       </div>
                       <div className="div-img-logos">
                         <img src={ImgMaster}/>
                       </div>
                       <div className="div-img-logos">
                         <img src={ImgElo}/>
                       </div>
                       <div className="div-img-logos"> 
                         <img src={ImgHiper}/>
                       </div>
                       <div className="div-img-logos">
                         <img src={ImgBoleto}/>
                       </div>
                     </div>
                   </div>
                 <div className="item-plano"><p className="p-comissao">  <span className="span-zero">Zero</span> de comissão sob as vendas</p></div>
               </div>
              : infoPlano.name ==='pro' ?
              <div className="envolve-item-plano">
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Woocommerce</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Melhor Envio</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Grátis"</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Fixo"</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Personalizado"</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Método de frete com Correios</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Método de frete com "Retirada na loja física"</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Mercado pago</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Mercado pago QR Code</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Pagar.me (Cartões e Boleto)</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Pagseguro (Cartões e Boletos)</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Cielo</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Picpay</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>AME Digital</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Google Analytics</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Facebook Pixel</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p><strong>Suporte técnico completo por e-mail, chat ou telefone</strong></p></div>
                <div className="mt-5">
                    <div className="div-flex-logos">
                      <div className="div-img-logos">
                        <img src={ImgAme}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgPicPay}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgMercadoPago}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgPayPal}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgPix}/>
                      </div>
                    </div>
                    <div className="div-flex-logos div-sec">
                      <div className="div-img-logos">
                        <img src={ImgVisa}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgMaster}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgElo}/>
                      </div>
                      <div className="div-img-logos"> 
                        <img src={ImgHiper}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgBoleto}/>
                      </div>
                    </div>
                  </div>
                <div className="item-plano"><p className="p-comissao">  <span className="span-zero">Zero</span> de comissão sob as vendas</p></div>
              </div>
              : infoPlano.name ==='plus' ?
              <div className="envolve-item-plano">
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Woocommerce</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Melhor Envio</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Grátis"</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Fixo"</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Métodos de "Frete Personalizado"</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Método de frete com Correios</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Método de frete com "Retirada na loja física"</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Mercado pago</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Mercado pago QR Code</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Pagar.me (Cartões e Boleto)</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Pagseguro (Cartões e Boletos)</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Cielo</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Picpay</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>AME Digital</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Google Analytics</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p>Integração com Facebook Pixel</p></div>
                <div className="item-plano"><CheckIcon className={classes.checkIcon}/><p><strong>Suporte técnico completo por e-mail, chat ou telefone</strong></p></div>
                <div className="mt-5">
                    <div className="div-flex-logos">
                      <div className="div-img-logos">
                        <img src={ImgAme}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgPicPay}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgMercadoPago}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgPayPal}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgPix}/>
                      </div>
                    </div>
                    <div className="div-flex-logos div-sec">
                      <div className="div-img-logos">
                        <img src={ImgVisa}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgMaster}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgElo}/>
                      </div>
                      <div className="div-img-logos"> 
                        <img src={ImgHiper}/>
                      </div>
                      <div className="div-img-logos">
                        <img src={ImgBoleto}/>
                      </div>
                    </div>
                  </div>
                <div className="item-plano"><p className="p-comissao">  <span className="span-zero">Zero</span> de comissão sob as vendas</p></div>
              </div>
              :''}
              </div>
            </div>
            :''}
            </div>
               )):''}
          </div>
    </div>
    </div>
  );
}
 