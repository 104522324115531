import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../../Services/api';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import '../../../../../App/Components/Form/Forms_Apps/PicPay/css/PicPay.css';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    },
    pTempoRetirada:{
        marginBottom: '0',
        marginLeft: '.5rem',
    },
    divTempoPrazo:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: '100%'
    },

  }));

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        prefix="R$"
  
         format={currencyFormatter}
      />
    );
  }

  function retiradaPrazo(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
         format="##"
      />
    );
  }

  function currencyFormatter(value) {
    if (!Number(value)) return "";
  
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value / 100);
  
    return `${amount}`;
  }
  
  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  retiradaPrazo.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
  
 

export default function FormFixo() {
    const history = useHistory();
    const classes = useStyles();
    const [id, setId] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [name, setName] = React.useState('');
    const [statusApp, setStatusApp] = React.useState('');
    const [enableApp, setEnableApp] = React.useState('');
    const [checked, setChecked] = React.useState('');
    const [tempoEntrega, setTempoEntrega] = React.useState('');
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [verificaVazioTempoEntrega, setVerificaTempoEntrega] = React.useState(false);
    const [verificaVazioAmount, setVerificaAmount] = React.useState(false);
    const [environment, setEnvironment] = React.useState('gratis');

    const [valor, setValor] = React.useState({
        numberformat: '',
      });

      const handleChange = (event) => {
        setValor({
          ...valor,
          [event.target.name]: event.target.value,
        });
      };
    
   function mostraStatus(){
    var alert = document.getElementById("AlertStatus");
       alert.style.display = 'block';
   }
   
   function removeStatus(){
    var alert = document.getElementById("AlertStatus");
       alert.style.display = 'none';
   }

   function checkEnable(){
    if(checked === true) {
        setChecked(false);
        setEnableApp(0);
    }
    else{
        setChecked(true);
        setEnableApp(1); 
    }
   }

  function cancelarApp(){
    history.push('/configuracoes-frete');
  }

const capturaTempoEntrega = (prop) => (event) => {
  setTempoEntrega(event.target.value );
};
 

async function buscaApp(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/shipping/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
        for(let i = 0; dados.length > i; i++){
            if(dados[i].slug === 'frete-fixo'){
                setId(dados[i].slug);
                setPhoto(dados[i].photo);
                setName(dados[i].name);
                setStatusApp(dados[i].authenticated);
                setEnableApp(dados[i].enable);
                setChecked(dados[i].enable);
                buscaDadosApp(dados[i].credentials);
                var amount = dados[i].credentials.shipping.amount;
                setValor({numberformat: `${amount}`})
              
        }
    }
    })
    .catch((error) => {

    })
}
 
  useEffect(() => {
    buscaApp();
  },[]) 

    async function enviaFormApp(event){
    event.preventDefault();
    var amount = '';
    if(environment==='gratis'){
        amount = 0;
    }else{
        amount = valor.numberformat;
    }

        var data = {
            "enable": enableApp,
            "amount": amount,
            "delivery_time": tempoEntrega,
            "company_id" : idCompanies,
            "integration_slug" : "frete-fixo",
        }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
          if(amount > 99 && environment==='pago'){
          if(tempoEntrega && amount !==''){
            await api.post('/v1/integrations/shipping', data, {
                headers: headers
              })
              .then((response) => {
                setMessageErro('');
                setMessageSuccess('Informações salvas com sucesso');
                setTimeout(() => {
                    setMessageSuccess('');
              }, 1500);
                setTimeout(() => {
                history.push('/configuracoes-frete');
            }, 2000);
              })
              .catch((error) => {
                setMessageSuccess('');
                  setMessageErro(error.response.data.errors.message);
                  setTimeout(() => {
                    setMessageErro('');
              }, 1500);
              })
            }else{
                if(!tempoEntrega || tempoEntrega ===undefined){
                    setVerificaTempoEntrega(true);
                }
                if(valor === ''){
                    setVerificaAmount(true);
                }
                return true;
            }
        } else if(environment!=='pago'){
                if(tempoEntrega){
                  await api.post('/v1/integrations/shipping', data, {
                      headers: headers
                    })
                    .then((response) => {
                      setMessageErro('');
                      setMessageSuccess('Informações salvas com sucesso');
                      setTimeout(() => {
                          setMessageSuccess('');
                    }, 1500);
                      setTimeout(() => {
                      history.push('/configuracoes-frete');
                  }, 2000);
                    })
                    .catch((error) => {
                      setMessageSuccess('');
                        setMessageErro(error.response.data.errors.message);
                        setTimeout(() => {
                          setMessageErro('');
                    }, 1500);
                    })
                  }else{
                      if(!tempoEntrega || tempoEntrega ===undefined){
                          setVerificaTempoEntrega(true);
                      }
                      if(valor ===''){
                          setVerificaAmount(true);
                      }
                      return true;
                  }
        }else{
            setMessageSuccess('');
                  setMessageErro('O valor do frete precisa ser no mínimo R$ 1,00 real.');
                  setTimeout(() => {
                    setMessageErro('');
              }, 1500);
        }
  }

 function buscaDadosApp(credentials){
   if(credentials.delivery_time){
    setTempoEntrega(credentials.delivery_time);  
   }else{
    setTempoEntrega('');  
   }
  
    if(credentials.amount==0){
        setEnvironment('gratis')
    }else{
        setEnvironment('pago')
        setValor({
            ...valor,
            ['numberformat']: credentials.amount,
        });
    }
}
  

    function clickRadio(radio){
        if(radio ==='dev'){
            setEnvironment('gratis');
        }else{
            setEnvironment('pago');
        }
       }


return (
    <div className="Apps-form-style">
        <div className="App-suporte">
            <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft}/></a>
        </div>
    <div className="App-form-style">
    <form onSubmit={enviaFormApp} className="PicPay-form" noValidate autoComplete="off">
        <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
            <div className={checked ? "App-circle-status App-circle-status-true": "App-circle-status"} onMouseEnter={mostraStatus} onMouseOut={removeStatus}></div>
                <img className="PicPay-logo" src={photo}/>
                    <h1 className="PicPay-title">{name}</h1>
            </div>
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    onClick={checkEnable}
                    value={enableApp}
                    checked={checked}
                    control={<Switch color="secondary" />}
                    label="Ativo:"
                    labelPlacement="start"
                />
            </FormGroup>
        </div>
        <div id="AlertStatus" className="AlertStatus">
            { checked ?
             <Alert variant="filled" severity="success">
                Status: Ativo
            </Alert>
                :
            <Alert variant="filled" severity="error">
                Status: Inativo
            </Alert>
            }
        </div>
        <div className="PicPay-link-tutoriais">
        <p className="PicPay-p-form">Informações da aplicação:</p>
            <a target="_blank" href="https://blog.payhow.com.br/2021/06/07/frete-fixo/">Como obter as informações do Frete Fixo?</a>
        </div>
        {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                     {messageSuccess!== ''?
                     <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                     :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                    <div className="PicPay-envolve-radio-teste-prod">
                    <h4 className="mt-2">1. Preencha os detalhes do frete:</h4>
                    <p>O frete será gratuito ou pago?</p>
                    <RadioGroup  aria-label="position" className="radio-grupo" name="position" defaultValue="top">
                        <FormControlLabel
                        className="mr"
                            onClick={()=>clickRadio('dev')}
                            name="environment"
                            checked={environment === 'gratis' ? true : false}
                            value="gratis" control={<Radio color="secondary" />} 
                            label="Gratuito"
                        />
                        <FormControlLabel 
                            onClick={()=>clickRadio('prod')}
                            name="environment"
                            checked={environment === 'pago' ? true : false} 
                            value="pago" 
                            control={<Radio color="secondary" />} 
                            label="Pago" 
                        />
                    </RadioGroup>
                    </div>
                    {environment==='pago'?
                    <TextField
                        className={clsx(classes.textStyle)} 
                        color="secondary"
                        label="Digite o valor que será cobrado no frete"
                        value={valor.numberformat}
                        onChange={handleChange}
                        name="numberformat"
                        id="formatted-numberformat-input"
                        variant="outlined" 
                        error={verificaVazioAmount ? true:false}
                        helperText={verificaVazioAmount ?"*Campo obrigatório":''}
                        InputProps={{
                        inputComponent: NumberFormatCustom,
                        }}
                    />
                    :''}
                    <div className={classes.divTempoPrazo}>
                        <TextField
                            className={clsx(classes.textStyle)} 
                            color="secondary"
                            id="tempoEntrega"
                            label="Tempo estimado de entrega"
                            type="tel"
                            value={tempoEntrega}  
                            onChange={capturaTempoEntrega()}
                            variant="outlined" 
                            required
                            InputProps={{
                                inputComponent: retiradaPrazo,
                            }}
                            error={verificaVazioTempoEntrega ? true:false}
                            helperText={verificaVazioTempoEntrega ?"*Campo obrigatório":''}
                            /> 
                            <p className={classes.pTempoRetirada}>dia(s)</p>
                    </div>
                   
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button variant="contained" onClick={cancelarApp}>Cancelar</Button>
                <Button type="submit" variant="contained" color="secondary">Salvar informações</Button>
            </div>
        </form>
    </div>
    </div>
);
}