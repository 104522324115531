import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import {Link} from 'react-router-dom';
import MoreIcon from '@material-ui/icons/MoreVert';
import './css/Topo.css';
import Logo from '../../Assets/images/payhow_logo.fw.png';
import EditIcon from '@material-ui/icons/Edit';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {useHistory} from 'react-router-dom';
import api from '../../Services/api';
import ModalLogout from './ModalLogout';
import { useEffect } from 'react';
import { StateGlobal } from '../../ContextAPI/context';



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  mail: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  }
}));

export default function PrimarySearchAppBar() {
  const history = useHistory()  
  const [image, setImage] = React.useState('');
  const [notify, setNotify] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [token, setToken] = React.useState(null);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [listMessage, setListMessage] = React.useState(null);
  const [listNotificacoes, setListNotificacoes] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [openLogout, setOpenLogout] = React.useState(false);
  const [lojas, setLojas] = React.useState('');
  const [lojaAtiva, setLojaAtiva] = React.useState('');

  const {confingfrete, setConfigfrete} = StateGlobal();

  //setConfigfrete('2994d912-9973-4871-93ea-4a18136fab4c')
  

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const  editarPerfil = () => {
    history.push('/perfil');
    setAnchorEl(null);
    handleMobileMenuClose();
  };
 

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function logout(){
    setOpenLogout(true);
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={editarPerfil}><EditIcon/> <p className="PaginaInicialPPerfil">Editar Perfil</p></MenuItem>
      <MenuItem onClick={logout}><ExitToAppIcon/><p className="PaginaInicialPSair">Sair</p></MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className="PaginaInicialTopo"
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={message} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Mensagens</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={notify} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notificaçãoes</p>
      </MenuItem>
      <MenuItem>
        <IconButton color="inherit">
          <Badge color="secondary">
            <SettingsIcon/>
          </Badge>
        </IconButton>
        <p>Configurações</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <img src={image} className="photoPerfil"/>
        </IconButton>
        <p>Perfil</p>
      </MenuItem>
    </Menu>
  );
  const [state, setState] = React.useState({
    name: lojaAtiva,
  });
  const handleChange = (event) => {
    const name = event.target.name;
    localStorage.removeItem('companiesId');
    localStorage.setItem('companiesId',event.target.value);

    setConfigfrete(event.target.value)
    setState({
      ...state,
      [name]: event.target.value,
    });
    history.push('/home');
  };

function pegaToken(){
  setToken(localStorage.getItem('tokenUser'));
  if(token !== null){
    topoInformacoes();
    recarregaNotificacoes();
    dadosLojasAfiliadas();
    // escondeChat();
  }
} 
useEffect(() => {
  setTimeout(() => {
  pegaToken();
  },200);
},[token]) 



// const urlPag =  history.location.pathname;


// function escondeChat(){
//   var chatB = document.getElementsByClassName('globalClass_d2cf');
//   if(urlPag !=='/login-secundario' && urlPag !=='/' && urlPag !=='/cadastro'){
//     setTimeout(() => {
//      chatB.style.display = 'block';
//     }, 10000);
// }else{
//   chatB.style.display = 'none';
// }
// }
  async function topoInformacoes() {
      const headers = { 
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": 'Bearer'+token
        }
        await api.get('/v1/customers/profile', {
          headers: headers
        })
        .then((response) => { 
           setImage(response.data.photo);
           setNotify(response.data.notifications.general_unread);
           setMessage(response.data.notifications.single_unread); 
        }).catch((error) =>{
            history.push('/')
        })
  }
  
async function recarregaNotificacoes() {

  const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization": 'Bearer'+token
    }
    await api.get('/v1/notifications', {
      headers: headers
    })
    .then((response) => {
      var listmessage = response.data.single;
      var listNotificacoes = response.data.general;
      setListNotificacoes(listNotificacoes);
      setListMessage(listmessage);
      topoInformacoes()
    })
}

useEffect(() => {
  const interval = setInterval(() => {
    recarregaNotificacoes();
  }, 120000);
  return () => clearInterval(interval);
}, []); 

function listaMessage(){
  history.push('/mensagens');
}
function listaNotify(){
  history.push('/notificacoes',{params:listNotificacoes});
}
function listaConfig(){
  history.push('/configuracoes');
}

async function dadosLojasAfiliadas(){
  const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
  await api.get(`/v1/customers/company`,  {
      headers: headers
    })
    .then((response) => {
      setLojas(response.data)
      let id = response.data[0].id;
      setConfigfrete(id)
      localStorage.setItem('companiesId',id);   
    })
}

  return (
    <div>
    <div className="PaginaInicialTopo">
    <div className={classes.grow}>
      
      <AppBar position="static">
        <Toolbar>
          <div className="PaginaInicial-img-topo">
            <Link to="/home">
              <img src={Logo}/>
            </Link>
          </div>
          <div className="PaginaInicialSelect">
          <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel className="labelSelect" shrink htmlFor="outlined-age-native-simple">Loja ativa</InputLabel>
        <Select
          native
          value={state.loja}
          onChange={handleChange}
          label="Loja ativa"
          inputProps={{
            name: 'Loja ativa',
            id: 'outlined-age-native-simple',
          }}
        >
        {lojas !=='' ?
        lojas.map((loja) => (
          <option value={loja.id}>{loja.name}</option>
          ))
          :''}
        </Select>
      </FormControl>
      </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton className={history.location.pathname==='/mensagens'?classes.mail:''} aria-label="show 4 new mails" color="inherit" onClick={listaMessage}>
              <Badge badgeContent={message} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton className={history.location.pathname==='/notificacoes'?classes.mail:''} aria-label="show 17 new notifications" color="inherit" onClick={listaNotify}>
              <Badge badgeContent={notify} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <img src={image} className="PaginaInicial-PhotoPerfil"/>
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
    <ModalLogout open={openLogout}/>
    </div>
 
    </div>
  );
}