import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Load from '../../../Components/Load';
import {useHistory} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../css/App.css';
import Modal from './Modal/Modal';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';


export default function BlocoApp(dadosApps) {
  
  const dadosApp = dadosApps.props;
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [openApp, setOpenApp] = React.useState(false);
  const [id, setId] = React.useState('');

  const handleClickOpen = (id) => {
    setOpenApp(true);
    setId(id);
  };

  const alteraEstadoModal = (value) => {
    setOpenApp(value); 
  };

  function abreFormulario(idApp){
    history.push('/form-apps');
    var infoApp = JSON.stringify(idApp);

    localStorage.setItem('idApp',infoApp);
  }
  useEffect(() => {
}, [search]);

return (
    <>
    <Modal open={openApp} id={id} setStateModal={alteraEstadoModal}/>
        {dadosApp !== ''?
     <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={dadosApp.map((option) => option.name)}
        onSelect={(ev) => setSearch(ev.target.value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Buscar App"
            margin="normal"
            variant="outlined"
            color="secondary" 
            InputProps={{
              ...params.InputProps, type: 'search',
              endAdornment: <InputAdornment position="start"><SearchIcon className="iconSearchColor"/></InputAdornment>,
            }}
          />
        )}
      />
      :''}
    {dadosApp !== ''?
     dadosApp.map((infoApp) => (
         <div>
             {search ==='' || search ===infoApp.name?
             <div>
        <div key={infoApp.id} className="App-envolve-app" >
        <div className="App-envolve-title-circle">
        <div className="App-img-title">
        <div className={infoApp.enable ? "App-circle-status App-circle-status-true": "App-circle-status"}></div>
            <img src={infoApp.photo} className="App-img-logo-empresa"/> 
            <h5>{infoApp.name}</h5>
        </div>
        { infoApp.available === 1 ?
            <Button variant="contained" color="secondary" onClick={()=>abreFormulario(infoApp)}>Visualizar</Button>
            :
            <Button variant="contained" color="secondary" onClick={()=>handleClickOpen(infoApp.id)}>Visualizar</Button>
            }
        </div>
        <div className="App-envolve-p">
            <p>{infoApp.description}</p>
        </div>
        </div>
         </div>
         :''}
         </div>
     ))
     :
     <div>
         <Load/>
    </div>
}

    </>
);
}