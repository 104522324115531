import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../../Services/api';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import '../../../../../App/Components/Form/Forms_Apps/PicPay/css/PicPay.css';
import CircularLoad from '../../../../../../Components/Load';
import {useHistory} from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    },
    pTempoRetirada:{
        marginBottom: '0',
        marginLeft: '.5rem',
    },
    divTempoPrazo:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: '100%'
    },
    envolveFormOculto:{
        width: '100%'
    },
    titleSecao:{
        marginTop: '2rem',
        textAlign: 'left'
    }

  }));

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
        prefix="R$"
  
         format={currencyFormatter}
      />
    );
  }

  function retiradaPrazo(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
         format="##"
      />
    );
  }

  function currencyFormatter(value) {
    if (!Number(value)) return "";
  
    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL"
    }).format(value / 100);
  
    return `${amount}`;
  }
  
  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  retiradaPrazo.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
  
 

export default function FormRetirarLocal() {
    const history = useHistory();
    const classes = useStyles();
    const [id, setId] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [name, setName] = React.useState('');
    const [statusApp, setStatusApp] = React.useState('');
    const [enableApp, setEnableApp] = React.useState('');
    const [checked, setChecked] = React.useState('');
    const [token, setToken]  = React.useState(localStorage.getItem('tokenUser'));
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [tokenAcesso, setTokenAcesso] = React.useState('');
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [verificaVazioToken, setverificaVazioToken] = React.useState(false);
    const [verificaToken, setverificaToken] = React.useState(false);
    const [load, setLoad] = React.useState(false);
    const [listEndereco, setListEndereco] = React.useState([]);
    const [listCorreios, setListCorreios] = React.useState([]);
    const [listJadlog, setListJadlog] = React.useState([]);
    const [listLatam, setListLatam] = React.useState([]);
    const [listAzul, setListAzul] = React.useState([]);
    const [listLoja, setListLoja] = React.useState([]);
    const [opcoesCotacao, setOpcoesCotacao] = React.useState([]);

    const [state, setState] = React.useState({
        endereco: '',
        jadlog: '',
        correios:'',
        loja:'',
        latam:'',
        azul:'',
      });
    
      const [check, setCheck] = React.useState({
        mao_propria: false,
        assegurar_sempre: false,
        aviso_recebimento: false,
      });

      const handleChange = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
      };

      
      const checkedCotacao = (event) => {
        const name = event.target.name;
        if(check[name]){
            opcoesCotacao.splice(opcoesCotacao.indexOf(name), 1);
            setCheck({
                ...check,
                [name]: false,
              });
        }else{
            opcoesCotacao.push(name)
            var unico = opcoesCotacao.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            });
            setOpcoesCotacao(unico)
            setCheck({
                ...check,
                [name]: true,
              });
        }
   
      };

   function mostraStatus(){
    var alert = document.getElementById("AlertStatus");
       alert.style.display = 'block';
   }
   
   function removeStatus(){
    var alert = document.getElementById("AlertStatus");
       alert.style.display = 'none';
   }

   function checkEnable(){
    if(checked === true) {
        setChecked(false);
        setEnableApp(0);
    }
    else{
        setChecked(true);
        setEnableApp(1); 
    }
   }

  function cancelarApp(){
    history.push('/configuracoes-frete');
  }


const capturaToken = () => (event) => {
    setTokenAcesso(event.target.value);
};


async function buscaApp(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/shipping/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
        for(let i = 0; dados.length > i; i++){
            if(dados[i].slug === 'melhor-envio'){
                setId(dados[i].slug);
                setPhoto(dados[i].photo);
                setName(dados[i].name);
                setStatusApp(dados[i].authenticated);
                setEnableApp(dados[i].enable);
                setChecked(dados[i].enable);
                buscaDadosApp(dados[i].credentials);
        }
    }
    })
    .catch((error) => {

    })
}
 
  useEffect(() => {
    buscaApp();
  },[]) 

  async function autenticaToken(){
      setLoad(true)
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
      }
    await api.post('/v1/integrations/shipping/melhor_envio/token',{
        "token":tokenAcesso
        },{
        headers: headers
      })
      .then((response) => {
        setLoad(false)
        setverificaToken(response.data.authenticated)
        if(!response.data.authenticated){
            setMessageSuccess('');
            setMessageErro('Token incorreto! Por favor verifique o campo preenchido e tente novamente.');
            setTimeout(() => {
              setMessageErro('');
        }, 2500);
        }else{
            setListEndereco(response.data.addresses)
            setListCorreios(response.data.correios_agencies)
            setListJadlog(response.data.jadlog_agencies)
            setListLatam(response.data.latam_cargo_agencies)
            setListAzul(response.data.azul_cargo_agencies)
            setListLoja(response.data.companies)
        }
      })
      .catch((error) => {
          setLoad(false)
          setMessageSuccess('');
          setMessageErro('Token incorreto! Por favor verifique o campo preenchido e tente novamente.');
          setTimeout(() => {
            setMessageErro('');
      }, 2500);
      })
  }



    async function enviaFormApp(event){
        setLoad(true)
    event.preventDefault();
         var data = {
            "company_id" : idCompanies,
            "integration_slug" : "melhor-envio",
            "me_address_id" : state.endereco,
            "me_company_id" : state.loja,
            "me_correios_id": state.correios,
            "me_jadlog_id": state.jadlog,
            "me_azul_cargo_id": state.azul,
            "me_latam_cargo_id": state.latam,    
            "enable" : enableApp,
            "me_checkbox_price" : opcoesCotacao,
            "me_token" : tokenAcesso
        }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
     
            await api.post('/v1/integrations/shipping', data, {
                headers: headers
              })
              .then((response) => {
                setLoad(false)
                setMessageErro('');
                setMessageSuccess('Informações salvas com sucesso');
                setTimeout(() => {
                    setMessageSuccess('');
              }, 1500);
                setTimeout(() => {
                history.push('/configuracoes-frete');
            }, 2000);
              })
              .catch((error) => {
                setLoad(false)
                setMessageSuccess('');
                  setMessageErro(error.response.data.errors.message);
                  setTimeout(() => {
                    setMessageErro('');
              }, 1500);
              })
         
            }
  

 function buscaDadosApp(credentials){
      setTokenAcesso(credentials.me_token)
      setState({
         endereco: credentials.me_address_id,
         azul: credentials.me_azul_cargo_id,
         jadlog: credentials.me_jadlog_id,
         loja: credentials.me_company_id,
         latam: credentials.me_latam_cargo_id,
         correios: credentials.me_correios_id
        })
        credentials.me_checkbox_price.map(function(item){
            check[item]=true
            var array = check;
            setCheck(array)
            opcoesCotacao.push(item)
            var unico = opcoesCotacao.filter(function(elem, index, self) {
                return index === self.indexOf(elem);
            });
            setOpcoesCotacao(unico)
        })
    }

return (
    <div className="Apps-form-style">
        <div className="App-suporte">
            <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft}/></a>
        </div>
    <div className="App-form-style">
    <form onSubmit={enviaFormApp} className="PicPay-form"  autoComplete="off">
        <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
            <div className={checked ? "App-circle-status App-circle-status-true": "App-circle-status"} onMouseEnter={mostraStatus} onMouseOut={removeStatus}></div>
                <img className="PicPay-logo" src={photo}/>
                    <h1 className="PicPay-title">{name}</h1>
            </div>
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    onClick={checkEnable}
                    value={enableApp}
                    checked={checked}
                    control={<Switch color="secondary" />}
                    label="Ativo:"
                    labelPlacement="start"
                />
            </FormGroup>
        </div>
        <div id="AlertStatus" className="AlertStatus">
            { checked ?
             <Alert variant="filled" severity="success">
                Status: Ativo
            </Alert>
                :
            <Alert variant="filled" severity="error">
                Status: Inativo
            </Alert>
            }
        </div>
        <div className="PicPay-link-tutoriais">
        <p className="PicPay-p-form">Informações da aplicação:</p>
            <a target="_blank" href="https://blog.payhow.com.br/2021/06/07/configuracao-do-melhor-envio/">Como obter as informações do Melhor Envio?</a>
        </div>
        {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                     {messageSuccess!== ''?
                     <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                     :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                    <h4 className="mt-2">1. Preencha o token para continuar:</h4>
                        <TextField
                            className={clsx(classes.textStyle)} 
                            color="secondary"
                            id="token"
                            label="Token de acesso"
                            type="text"
                            value={tokenAcesso} 
                            onChange={capturaToken()}
                            variant="outlined" 
                            required
                            error={verificaVazioToken ? true:false}
                            helperText={verificaVazioToken && tokenAcesso ==='' ?"*Campo obrigatório":''}
                         />
                        {verificaToken?    
                            <div className={classes.envolveFormOculto}>
                            <h4 className={classes.titleSecao}>2. Preencha as informações necessárias para o envio:</h4>
                                <TextField
                                    id="endereco"
                                    className={clsx(classes.textStyle)} 
                                    select
                                    color="secondary"
                                    label="Escolha o endereço para cálculo de frete"
                                    value={state.endereco}
                                    name="endereco"
                                    required
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    >
                                          <option value="">
                                          Selecione uma opção
                                      </option>
                                    {listEndereco?
                                    listEndereco.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.address}
                                        </option>
                                           )):
                                           <option value={null}>
                                               Não possui endereço
                                           </option>
                                        }
                                </TextField>
                                <TextField
                                    id="jadlog"
                                    className={clsx(classes.textStyle)} 
                                    select
                                    color="secondary"
                                    label="Escolha uma agência Jadlog de sua preferência"
                                    value={state.jadlog}
                                    name="jadlog"
                                    required
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    >
                                          <option value="">
                                          Selecione uma opção
                                      </option>
                                    {listJadlog?
                                    listJadlog.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                        )):
                                        <option value={null}>
                                            Não possui agências
                                        </option>
                                     }
                                </TextField>
                                <TextField
                                    id="correios"
                                    className={clsx(classes.textStyle)} 
                                    select
                                    color="secondary"
                                    label="Escolha uma agência Correios de sua preferência"
                                    value={state.correios}
                                    name="correios"
                                    required
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    >
                                          <option value="">
                                          Selecione uma opção
                                      </option>
                                    {listCorreios?
                                    listCorreios.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                       )):
                                       <option value={null}>
                                           Não possui agências
                                       </option>
                                    }
                                </TextField>
                                <TextField
                                    id="azul"
                                    className={clsx(classes.textStyle)} 
                                    select
                                    color="secondary"
                                    label="Escolha uma agência Azul Cargo Express de sua preferência"
                                    value={state.azul}
                                    name="azul"
                                    required
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    >
                                        <option value="">
                                          Selecione uma opção
                                      </option>
                                    {listAzul?
                                    listAzul.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                      )):
                                      <option value={null}>
                                          Não possui agências
                                      </option>
                                    }
                                </TextField>
                                <TextField
                                    id="latam"
                                    className={clsx(classes.textStyle)} 
                                    select
                                    color="secondary"
                                    label="Escolha uma agência Latam Cargo de sua preferência"
                                    value={state.latam}
                                    name="latam"
                                    required
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    >
                                          <option value="">
                                          Selecione uma opção
                                      </option>
                                    {listLatam?
                                    listLatam.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                    )):
                                        <option value={null}>
                                            Não possui agências
                                        </option>
                                    }
                                </TextField>
                                <TextField
                                    id="loja"
                                    className={clsx(classes.textStyle)} 
                                    select
                                    color="secondary"
                                    label="Escolha uma loja padrão dentre as lojas cadastradas no Melhor Envio"
                                    value={state.loja}
                                    name="loja"
                                    required
                                    onChange={handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    variant="outlined"
                                    >
                                          <option value="">
                                          Selecione uma opção
                                      </option>
                                    {listLoja?
                                    listLoja.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.name}
                                        </option>
                                         )):
                                         <option value={null}>
                                             Não possui loja
                                         </option>
                                      }
                                </TextField>
                                <FormGroup>
                                <h4 className={classes.titleSecao}>3. Opções para cotação:</h4>
                                    <FormControlLabel
                                        value="aviso_recebimento"
                                        name="aviso_recebimento"
                                        control={
                                            <Checkbox checked={check.aviso_recebimento}
                                            onChange={checkedCotacao} 
                                            color="secondary" />
                                        }
                                        label="Aviso de recebimento"
                                        labelPlacement="Aviso de recebimento"
                                    />
                                         <FormControlLabel
                                        value="mao_propria"
                                        name="mao_propria"
                                        control={
                                            <Checkbox checked={check.mao_propria}
                                            onChange={checkedCotacao} 
                                            color="secondary" />
                                        }
                                        label="Mão própria"
                                        labelPlacement="Mão própria"
                                    />
                                         <FormControlLabel
                                        value="assegurar_sempre"
                                        name="assegurar_sempre"
                                        control={
                                            <Checkbox checked={check.assegurar_sempre}
                                            onChange={checkedCotacao} 
                                            color="secondary" />
                                        }
                                        label="Assegurar sempre"
                                        labelPlacement="Assegurar sempre"
                                    />
                                </FormGroup>
                            </div>
                        :''}
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button variant="contained" onClick={cancelarApp}>Cancelar</Button>
                {!verificaToken?
                <Button type="button" onClick={autenticaToken} variant="contained" color="secondary">{!load?'Conectar':<CircularLoad/>}</Button>
                :<Button type="submit" variant="contained" color="secondary">{!load?'Salvar informações':<CircularLoad/>}</Button>}
            </div>
        </form>
    </div>
    </div>
);
}