import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './css/gridMensagem.css';
import api from '../../Services/api';
import Load from '../Load';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },

}));

export default function AutoGrid() {
  const classes = useStyles();
  const [messages, setMessages] = useState('');
  const [modalMensagem, setModalMensagem] = useState(false);
  const [titleMensagem, setTitleMensagem] = useState('');
  const [descriptionMensagem, setDescriptionMensagem] = useState('');
  const [hourMensagem, setHourMensagem] = useState('');
  const [dateMensagem, setDateMensagem] = useState('');
  const [idMensagem, setIdMensagem] = useState();
  const token = localStorage.getItem('tokenUser');

  const abreMensagem = (mensagem) => {
    setModalMensagem(true);
    setTitleMensagem(mensagem.title);
    setDescriptionMensagem(mensagem.description);
    setHourMensagem(mensagem.hour);
    setDateMensagem(mensagem.date);
    setIdMensagem(mensagem.id);
  };

  const fechaMensagem = (idMensagem) => {
    setModalMensagem(false);
    visualizouMensagem(idMensagem);
  };

  useEffect(() => {
    recarregaNotificacoes()
  },[]) 

  async function recarregaNotificacoes() {

    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": 'Bearer'+token
      }
      await api.get('/v1/notifications', {
        headers: headers
      })
      .then((response) => {
        var listmessage = response.data.single;
        setMessages(listmessage);
      })
  }


  async function visualizouMensagem(idMensagem) {
    var data = {
      'read': 1
      }
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": 'Bearer'+token
      }
      await api.put('/v1/notifications/' + idMensagem, data, {
        headers: headers
      })
      .then((response) => {
        recarregaNotificacoes();
      
      })
  }
  return (
    <div className={classes.root}>
      <div className="GridMensagem-divEnvolve">
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
          {messages!==''?
            <div className="GridMensagem-envolve-conteudo">
            {messages===null?<Load/>:''} 
              <div className="GridMensagem-envolve-title">
              {messages.length === 0?<h2>Você não possui mensagens</h2>:<h2>Mensagens</h2> }
             </div>
             <div className="GridMensagem-envolve-lista">
               { messages.map((mensagem) => (
               <div key={mensagem.id} id={mensagem.id} className="GridMensagem-lista" onClick={()=>abreMensagem(mensagem)}>
                 {mensagem.read==1 ?
                 <div read = {mensagem.read} className="GridMensagem-circle"></div>
                 :
                 <div read = {mensagem.read} className="GridMensagem-circle active"></div>
                  }
                 <div className="GridMensagem-envolv-text">
                   <h3 dangerouslySetInnerHTML={{ __html:mensagem.title.length > 150 ? mensagem.title.substring(0,55) + '...' : mensagem.title}}/>
                 <p dangerouslySetInnerHTML={{ __html:mensagem.description.length > 150 ? mensagem.description.substring(0,150) + '...' : mensagem.description}}/>
                 <p className="GridMensagem-date">{mensagem.date} às {mensagem.hour}</p>
                 </div>
               </div>
               ))}
                 <Dialog
                    open={modalMensagem}
                    keepMounted
                    id={idMensagem}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <p className="GridMensagem-date modal"></p>
                    <DialogTitle>{titleMensagem}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description"  dangerouslySetInnerHTML={{ __html:descriptionMensagem}}>
                      </DialogContentText>
                      <DialogContentText className="GridMensagem-date" id="alert-dialog-description-date">
                        {dateMensagem} às {hourMensagem}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={()=>fechaMensagem(idMensagem)} color="secondary">
                        Fechar
                      </Button>
                    </DialogActions>
                  </Dialog>
             </div>
            </div>
            :''} 
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}