import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {useParams} from 'react-router-dom';
import PlanoCorporativo from './PlanoCorporativo';
import PlanoProfissional from './PlanoProfissional';
import '../../css/perfil.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento:{
      paddingTop: 20,
  },
  barra:{
      backgroundColor: 'transparent',
      color: '#5A5A5A',
      boxShadow: 'none',
      borderBottom: '1px solid #70707085',
      
  },
  btn:{
      borderRadius: 5,
      marginBottom: 10,
      marginRight: 10,
      borderBottom: 'none',
      '&:hover': {
        color: '#F50272',
        opacity: 1,
      }
  },
  btnSelect:{
    borderRadius: 5,
    marginBottom: 10, 
    marginRight: 10,
    borderBottom: 'none',
    backgroundColor: '#f5027221',
    color: '#F50272', 
  },
  indicator:{
    display: 'hidden',
  }
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
            {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Perfil(props) {
  const classes = useStyles();
  const params = useParams();
  const token = localStorage.getItem('tokenUser');
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={1}>
          
        </Grid>
        <Grid item xs={9}>
        <div>
          <AppBar position="static" className={classes.barra}>
            <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Profissional" {...a11yProps(0)} className={value === 0 ? classes.btnSelect : classes.btn}/>
              <Tab label="Corporativo" {...a11yProps(1)} className={value === 1 ? classes.btnSelect : classes.btn}/>
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <PlanoProfissional/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PlanoCorporativo/>
          </TabPanel>
        </div>
        </Grid>
      </Grid>
   
    </div>
  );
}