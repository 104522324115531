import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import FormMeusDados from './components/FormMeusDados';
import FormAltSenha from './components/FormAltSenha';
import FormDadosBanc from './components/FormDadosBanc';
import Planos from './components/FormMeuPlano';
import Upload from './components/upload.js';
import api from '../../Services/api';
import { useParams } from 'react-router-dom';
import './css/perfil.css'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento: {
    paddingTop: 20 ,
  },
  barra: {
    backgroundColor: 'transparent',
    color: '#5A5A5A',
    boxShadow: 'none',
    borderBottom: '1px solid #70707085',

  },
  btn: {
    borderRadius: 5,
    marginBottom: 10,
    marginRight: 10,
    borderBottom: 'none',
    '&:hover': {
      color: '#F50272',
      opacity: 1,
    }
  },
  btnSelect: {
    borderRadius: 5,
    marginBottom: 10,
    marginRight: 10,
    borderBottom: 'none',
    backgroundColor: '#f5027221',
    color: '#F50272',
  },
  indicator: {
    display: 'hidden',
  }
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Perfil(props) {
  const classes = useStyles();
  const params = useParams();
  const [foto, setFoto] = React.useState('');
  const [photo, setPhoto] = React.useState('');
  const [value, setValue] = React.useState('');
  const [name, setName] = React.useState('');
  const [nameAntigo, setNameAntigo] = React.useState('');
  const [modalPlano, setModalPlano] = React.useState('');
  const token = localStorage.getItem('tokenUser');




  










  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeFoto = (foto) => {

    setFoto(foto)
  }
  async function recebeMeusDados() {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/customers/profile', {
      headers: headers
    })
      .then((response) => {

       if(response.data.name !==''){
        setName(response.data.name);
        setNameAntigo(response.data.name)
        setPhoto(response.data.photo)
       }
       return true;
      })
  }
  useEffect(() => {

    if (props.location.state !== undefined) {
      setModalPlano(props.location.state.params);
      if (props.location.state.params === 'modal') {
        setValue(3);
      } else {
        setValue(0);
      }
    } else {
      setValue(0);
    }
    recebeMeusDados();

  }, [])
  const issoAlteraOMeuEstado = (value) => {
    setName(value);
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={1}>

        </Grid>
        <Grid item xs={9}>
        <div className="perfil-image-name">
          <div className={classes.foto}>
            <Upload photo={photo} handleChangeFoto={handleChangeFoto}/>
          </div>
               <h1>{name ===''?nameAntigo:name}</h1>
        </div>
        <div>
          <AppBar position="static" className={classes.barra}>
            <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Meus dados" {...a11yProps(0)} className={value === 0 ? classes.btnSelect : classes.btn}/>
              <Tab label="Alterar minha senha" {...a11yProps(1)} className={value === 1 ? classes.btnSelect : classes.btn}/>
              {/* <Tab label="Dados bancários" {...a11yProps(2)} className={value === 2 ? classes.btnSelect : classes.btn}/> */}
              <Tab label="Assinatura" {...a11yProps(2)} className={value === 2 ? classes.btnSelect : classes.btn}/>
            </Tabs>
          </AppBar>
          
          <TabPanel value={value} index={0}>
            <FormMeusDados photoUser={foto} nameUser={name} setStateDoPapaizineo={issoAlteraOMeuEstado}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FormAltSenha/>
          </TabPanel>
          <TabPanel value={value} index={2}>
           <Planos modalPlano={modalPlano} />
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            <FormDadosBanc />
          </TabPanel> */}
          
        </div>
        </Grid>

      </Grid>

    </div>
  );
}