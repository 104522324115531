import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import LaunchIcon from "@material-ui/icons/Launch";
import FilterListIcon from "@material-ui/icons/FilterList";
import GetAppIcon from "@material-ui/icons/GetApp";
import api from "../../../Services/api";
import ImgPedidos from "../../../Assets/images/historicoPedido.png";
import { useHistory } from "react-router-dom";
import { InputLabel, Select } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "codigo",
    numeric: true,
    disablePadding: false,
    label: "ID do Checkout",
  },
  { id: "loja", numeric: false, disablePadding: false, label: "Loja" },
  {
    id: "data ",
    numeric: false,
    disablePadding: false,
    label: "Data de Geração",
  },
  {
    id: "data_aprovacao",
    numeric: false,
    disablePadding: false,
    label: "Ultima atualização",
  },
  { id: "valor", numeric: true, disablePadding: false, label: "Valor" },
  {
    id: "comprador",
    numeric: false,
    disablePadding: false,
    label: "Comprador",
  },
  {
    id: "forma_pagamento",
    numeric: false,
    disablePadding: false,
    label: "Forma de pagamento",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "visualizar",
    numeric: false,
    disablePadding: false,
    label: "Visualizar",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const useToolbarStyles = makeStyles((theme) => ({
//   root: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(1),
//   },
//   highlight:
//     theme.palette.type === 'light'
//       ? {
//           color: theme.palette.secondary.main,
//           backgroundColor: lighten(theme.palette.secondary.light, 0.85),
//         }
//       : {
//           color: theme.palette.text.primary,
//           backgroundColor: theme.palette.secondary.dark,
//         },
//   title: {
//     flex: '1 1 100%',
//   },
// }));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    backgroundColor: "#F3F3F3",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const getStatusColor = (status) => {
  switch (status) {
    case "Não finalizado":
      return { backgroundColor: "#ffa9a9", color: "#7a1f1f" };
    case "Processando":
      return { backgroundColor: "#fff7b4", color: "#68631e" };
    case "Pendente":
      return { backgroundColor: "#cbcbcb", color: "#565656" };
    case "Pago":
      return { backgroundColor: "#a2fba6", color: "#486a32" };
    case "Cancelado":
      return { backgroundColor: "#e57373", color: "#000000" };
    case "Reembolsado":
      return { backgroundColor: "#699aff", color: "#404142" };
    case "Atrasado":
      return { backgroundColor: "#ffbc7e", color: "#52482b" };
    default:
      return { backgroundColor: "#ffffff", color: "#000000" };
  }
};

export default function TableHistorico() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [dataInicial, setDataInicial] = React.useState("");
  const [dataFinal, setDataFinal] = React.useState("");
  const [messageErro, setMessageErro] = React.useState("");
  const [messageSuccess, setMessageSuccess] = React.useState("");
  const [semHistorico, setSemHistorico] = React.useState(false);
  const [semResultado, setSemResultado] = React.useState(false);
  const [typeFilter, setTypeFilter] = React.useState("id_checkout");
  const history = useHistory();
  const theme = createMuiTheme({}, ptBR);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const capturaDataFinal = (prop) => (event) => {
    setDataFinal(event.target.value);
  };

  const capturaDataInicial = (prop) => (event) => {
    setDataInicial(event.target.value);
  };

  async function buscarData() {
    setRows([]);
    setPage(0);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("tokenUser")}`,
    };

    if (dataFinal && dataInicial !== "") {
      var dataInicio = dataInicial?.split("-");
      var data_inicial =
        dataInicio[2] + "/" + dataInicio[1] + "/" + dataInicio[0];

      var dataFim = dataFinal?.split("-");
      var data_final = dataFim[2] + "/" + dataFim[1] + "/" + dataFim[0];
    }

    const data = {
      data_inicial: data_inicial,
      data_final: data_final,
      filtro: typeFilter,
      content: search,
    };
    await api
      .post(
        `/v1/customers/historic/filter/${localStorage.getItem("companiesId")}`,
        data,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.message) {
          setSemResultado(true);
          setMessageSuccess("");
          setMessageErro(response.data.message);
          setTimeout(() => {
            setMessageErro("");
          }, 1500);
        } else {
          setSemResultado(false);
          setMessageErro("");
          setMessageSuccess(
            dataFinal && dataInicial !== ""
              ? `Resultados da busca pela data inicial: ${data_inicial} e data final: ${data_final}`
              : "Resultados da busca"
          );
          setTimeout(() => {
            setMessageSuccess("");
          }, 4000);
          const itens = [];
          response.data.map(function (item) {
            itens.push({
              loja: item.loja,
              codigo: item.codigo_pedido,
              status: item.status,
              data: item.data,
              hora: item?.hora,
              data_aprovacao: item.data_aprovacao,
              hora_aprovacao: item?.hora_aprovacao,
              valor_total: item?.valor_total,
              forma_pagamento: item.forma_pagamento,
              type: item.type,
              telefone: item.telefone,
              integration_compra: item.integration_compra,
              integration_id: item.integration_id,
              email: item.email,
              plataforma: item.plataforma,
              comprador: item.comprador,
              codigo_parceiro: item.codigo_parceiro,
              valor: item.valor_total_mask,
            });
          });
          setRows(itens);
        }
      });
  }

  useEffect(() => {
    buscarData();
  }, []);

  async function exportData() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("tokenUser")}`,
    };

    if (dataFinal && dataInicial !== "") {
      var dataInicio = dataInicial?.split("-");
      var data_inicial =
        dataInicio[2] + "/" + dataInicio[1] + "/" + dataInicio[0];

      var dataFim = dataFinal?.split("-");
      var data_final = dataFim[2] + "/" + dataFim[1] + "/" + dataFim[0];
    }

    const data = {
      data_inicial: data_inicial,
      data_final: data_final,
      filtro: typeFilter,
      content: search,
    };
    await api
      .post(
        `/v1/customers/historic/export/${localStorage.getItem("companiesId")}`,
        data,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.message) {
          setSemResultado(true);
          setMessageSuccess("");
          setMessageErro(response.data.message);
          setTimeout(() => {
            setMessageErro("");
          }, 1500);
        } else {
          const link = document.createElement("a");
          link.setAttribute("href", encodeURI(response.data.csv));
          link.setAttribute("download", `historico-${response.data.registros}`);
          link.click();
        }
      });
  }

  function visualizarInformacoes(row) {
    console.log('row: ', row)
    history.push({
      pathname: `/pedidos/${row?.codigo}`,
      state: { data: row },
    });
  }

  const handleChangeType = (event) => {
    setTypeFilter(event.target.value);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        {!semHistorico ? (
          <div className="tablePedido">
            <div className="boxFilter">
              <FormControl
                variant="outlined"
                color="secondary"
                className="selectType"
              >
                <InputLabel>Tipo do link</InputLabel>
                <Select
                  value={typeFilter}
                  onChange={handleChangeType}
                  label="Tipo do link"
                >
                  <option value={"id_checkout"}>ID do checkout</option>
                  <option value={"comprador"}>Comprador </option>
                  <option value={"status"}>Status</option>
                </Select>
              </FormControl>
              <TextField
                style={{ width: `25%` }}
                id="outlined-basic"
                value={search}
                onChange={(ev) => setSearch(ev.target.value)}
                label="Buscar por..."
                color="secondary"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className="iconSearchColor" />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                style={{ width: `15%` }}
                id="outlined-basic"
                value={dataInicial}
                onChange={capturaDataInicial()}
                label="Data inicial"
                color="secondary"
                variant="outlined"
                type="date"
                maxLength="10"
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                style={{ width: `15%` }}
                id="outlined-basic"
                value={dataFinal}
                label="Data final"
                onChange={capturaDataFinal()}
                color="secondary"
                variant="outlined"
                type="date"
                maxLength="10"
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                className="sendFilter"
                variant="contained"
                color="secondary"
                onClick={buscarData}
              >
                <FilterListIcon /> Filtrar
              </Button>
              <Button
                className="sendFilter"
                variant="contained"
                color="secondary"
                onClick={exportData}
              >
                <GetAppIcon /> EXPORTAR
              </Button>
            </div>
            <div className="historico-pedido-div-msg">
              {messageErro !== "" ? (
                <Alert
                  className="messagensFixed"
                  variant="filled"
                  severity="error"
                >
                  {messageErro}
                </Alert>
              ) : (
                ""
              )}
              {messageSuccess !== "" ? (
                <Alert
                  className="messagensFixed"
                  variant="filled"
                  severity="success"
                >
                  {messageSuccess}
                </Alert>
              ) : (
                ""
              )}
            </div>
            {!semResultado ? (
              <Paper className={classes.paper}>
                <TableContainer>
                  <Table
                    className={clsx(classes.table, "historico-pedido-table")}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.codigo);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleClick(event, row.codigo)
                              }
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.codigo}
                              selected={isItemSelected}
                            >
                              <TableCell component="th" id={labelId}>
                                {row.codigo}
                              </TableCell>
                              <TableCell align="right">{row.loja}</TableCell>
                              <TableCell align="right">
                                {row.data} - {row?.hora}
                              </TableCell>
                              <TableCell align="right">
                                {row.data_aprovacao} - {row?.hora_aprovacao}
                              </TableCell>
                              <TableCell align="right">{row.valor}</TableCell>
                              <TableCell align="right">
                                {row.comprador}
                              </TableCell>
                              <TableCell align="right">
                                {row.forma_pagamento}
                              </TableCell>
                              <TableCell align="right">
                                <div
                                  className="tagStatus"
                                  style={{
                                    backgroundColor: getStatusColor(row.status)
                                      .backgroundColor,
                                    color: getStatusColor(row.status).color,
                                  }}
                                >
                                  {row.status}
                                </div>
                              </TableCell>
                              <TableCell align="right">
                                <LaunchIcon
                                  className="hisotrico-pedido-icon"
                                  onClick={() => visualizarInformacoes(row)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 30, 35]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage="Resultados por página"
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="historico-pedido-semHistorico">
            <img src={ImgPedidos} />
            <h1>Nenhum pedido foi resgistrado até o momento.</h1>
          </div>
        )}
      </ThemeProvider>
    </div>
  );
}
