import React from 'react';
import './css/index.css';
import GoogleLogin from 'react-google-login';
import api from '../../Services/api';
import {useHistory} from 'react-router-dom';


export default function Google() {
  const History = useHistory();
const responseGoogle = (response) => {
  let IDGoogle = response.Ca;
  let access_token = response.accessToken;
  makeRequestGoogle(IDGoogle,access_token,response); 
}
async function makeRequestGoogle(IDGoogle,access_token, response) {
  
  const config = {
    headers: { 
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'get',
    url: '/v1/customers/register/google/'+IDGoogle,
  }
  
  let res = await api(config)
  let status = res.data.status;

    if(status===true){
      makePostRequestGoogle(access_token);
    }else{
      History.push('/login-secundario',{params:response, login: 'google'}); 
    }
    
}
 function makePostRequestGoogle(access_token) {
  var data = {
    'access_token': access_token,
  }
  const headers = { 
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  api.post('/v1/customers/login/google', data, {
    headers: headers
  })
  .then((response) => {
    var dados = response.data;
    History.push('/home?load');  
    const token = dados.token
    localStorage.setItem('tokenUser',token);
  })
  .catch((error) => {
  })
}
  return (
    <div className="google-login">
      <GoogleLogin
        clientId="919126853343-ul996ku86f36qaeog7b5tepefdehviet.apps.googleusercontent.com"
        buttonText="Entrar com Google"
        onSuccess={responseGoogle}
        prompt="select_account"
        className="google-btn"
        scope = 'profile email'
      />
  </div>
  );
}