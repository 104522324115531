import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import FormInfoLoja from './components/FormInfoLoja';
import Script from './components/Script';
import Personalizar from './components/personalizar';
import api from '../../../../Services/api';
import {useHistory} from 'react-router-dom';
import '../../css/Loja.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento:{
      paddingTop: 20,
  },
  barra:{
      backgroundColor: 'transparent',
      color: '#5A5A5A',
      boxShadow: 'none',
      borderBottom: '1px solid #70707085',
      
  },
  btn:{
      borderRadius: 5,
      marginBottom: 10,
      marginRight: 10,
      borderBottom: 'none',
      '&:hover': {
        color: '#F50272',
        opacity: 1,
      }
  },
  btnSelect:{
    borderRadius: 5,
    marginBottom: 10, 
    marginRight: 10,
    borderBottom: 'none',
    backgroundColor: '#f5027221',
    color: '#F50272', 
  },
  indicator:{
    display: 'hidden',
  }
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
            {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Perfil() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [name, setName] = React.useState('Nome Loja');
  const [nameAntigo, setNameAntigo] = React.useState('Nome Loja');
  const [dataLoja, setDadosLoja] = React.useState('');
  const [disable, setDisable] = React.useState(false);
  const [idLoja, setIdLoja] = React.useState('');
  const token = localStorage.getItem('tokenUser');
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const alteraEstadoNome = (value) => {
    setName(value); 
  };

  const dadosLoja = (value) => {
    setDadosLoja(value);
  };
  const idLojas = (value) => {
    setIdLoja(value); 
  };

  const string = history.location.pathname;
  var id = string.split('/')[3];


  async function dadosLojasAfiliadas(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
      }
    await api.get(`/v1/customers/company`,  {
        headers: headers
      })
      .then((response) => {
        for(let i = 0; response.data.length > i; i++){
          if(response.data[i].cnpj){
            setDisable(false);
          }else{
            setDisable(true);
          }
            if(id===response.data[i].id){
              setName(response.data[i].name);
              setNameAntigo(response.data[i].name);
            }
        }
      })
  }
  useEffect(() => {
    const urlPag =  history.location.pathname;
    var pag = urlPag.split('/')[2];
    if(pag === 'editar'){
      setDisable(false);
    }else{
      setDisable(true);
    }
   
    dadosLojasAfiliadas()
  }, []);


  return (
    <div className={classes.root}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={9}>
        <div className="loja-image-name">
               <h1>{name ===''?nameAntigo:name}</h1>
        </div>
        <div>
          <AppBar position="static" className={classes.barra}>
            <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Informações da loja" {...a11yProps(0)} className={value === 0 ? classes.btnSelect : classes.btn}/>
              <Tab label="Personalizar" {...a11yProps(1)} disabled={disable} className={value === 1 ? classes.btnSelect : classes.btn}/>
              {/* <Tab label="Pixel de Rastreamento" {...a11yProps(2)} className={value == 2 ? classes.btnSelect : classes.btn}/> */}
              {/* <Tab label="Scripts" {...a11yProps(2)} className={value === 2 ? classes.btnSelect : classes.btn}/> */}
            </Tabs>
          </AppBar>
          
          <TabPanel value={value} index={0}>
            <FormInfoLoja nameUser={name} atualizaNomeLoja={alteraEstadoNome} arrayDados={dadosLoja} idLoja={idLojas}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Personalizar arrayDados={dataLoja} idLoja={idLoja}/>
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            <PixelRastreamento />
          </TabPanel> */}
          <TabPanel value={value} index={2}>
            <Script/>
          </TabPanel>
        </div>
        </Grid>
       
      </Grid>
   
    </div>
  );
}