import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Login from './Pages/Login';
import Cadastro from './Pages/Cadastro';
import LoginRedeSocial from './Pages/LoginRedeSocial';
import Topo from './Components/Menus'
import ContratarPlano from './Pages/ContratarPlano';


export default function Routes(){
    return(
        <Switch>
            <Route path="/" exact component={Login}/>
            <Route path="/cadastro" component={Cadastro}/>   
            <Route path="/login-secundario" component={LoginRedeSocial}/>
            <Route path="/contratar-plano" component={ContratarPlano}/>
            <Route path="/home" component={Topo}/>
            <Route path="/dashboard" component={Topo}/>   
            <Route path="/links-personalizados" component={Topo}/>   
            <Route path="/mensagens" component={Topo}/>
            <Route path="/notificacoes" component={Topo}/>  
            <Route path="/configuracoes" component={Topo}/>     
            <Route path="/apps" component={Topo}/>
            <Route path="/form-apps" component={Topo}/>
            <Route path="/form-apps-card" component={Topo}/>
            <Route path="/form-apps-boleto" component={Topo}/>      
            <Route path="/form-apps-pix" component={Topo}/>      
            <Route path="/apps" component={Topo}/>
            <Route path="/perfil" component={Topo}/>      
            <Route path="/pedidos" component={Topo}/>   
            <Route path="/planos" component={Topo}/> 
            <Route path="/historico-vendas" component={Topo}/>   
            <Route path="/lojas" component={Topo}/>
            <Route path="/links" component={Topo}/>
            <Route path="/configuracoes-frete" component={Topo}/>
            <Route path="/app-frete" component={Topo}/>    
            <Route component={Topo} /> 
        </Switch>
    )
}
