import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import api from '../../../../../Services/api';
import clsx from 'clsx';
import {useHistory} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    },
    formControl: {
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      inputCep: {
        width:"70% !important",
    },
    inputEstado: {
        width:"28% !important",
        marginLeft: ".5rem"
    },
    envolveUFCep:{
        width: '100%'
    }
  }));

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
         format='(##) # ####-####'
      />
    );
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

export default function FormInfoLoja(InfoLoja) {
    const classes = useStyles();
    const [nome, setNome] = React.useState('');
    const [nomeSocio, setNomeSocio] = React.useState('');
    const [razaoSocial, setRazaoSocial] = React.useState('');
    const [cpf, setCpf] = React.useState('');
    const [cnpj, setCnpj] = React.useState('');
    const [cep, setCep] = React.useState('');
    const [uf, setUf] = React.useState('');
    const [bairro, setBairro] = React.useState('');
    const [numero, setNumero] = React.useState('');
    const [cidade, setCidade] = React.useState('');
    const [rua, setRua] = React.useState('');
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [verificaVazioNome, setVerificaVazioNome] = React.useState(false);
    const [verificaVazioNomeSocio, setVerificaVazioNomeSocio] = React.useState(false);
    const [verificaVazioRazaoSocial, setVerificaVazioRazaoSocial] = React.useState(false);
    const [verificaVazioCpf, setVerificaVazioCpf] = React.useState(false);
    const [verificaVazioCnpj, setVerificaVazioCnpj] = React.useState(false);
    const [verificaVazioCep, setVerificaVazioCep] = React.useState(false);
    const [verificaVazioUF, setVerificaVazioUF] = React.useState(false);
    const [verificaVazioBairro, setVerificaVazioBairro] = React.useState(false);
    const [verificaVazioNumero, setVerificaVazioNumero] = React.useState(false);
    const [verificaVazioRua, setVerificaVazioRua] = React.useState(false);
    const [verificaVazioCidade, setVerificaVazioCidade] = React.useState(false);
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [cnpjsm, setCnpjSm] = React.useState('');
    const [cpfsm, setCpfSm] = React.useState('');
    const [cepfsm, setCepSm] = React.useState('');
    const [telUser, setTelUser] = React.useState('');
    const [phoneUser, setPhoneUser] = React.useState('');
    const [corEnvia, setColorEnvia] = React.useState('');
    const [corSecundariaEnvia, setColorSecundariaEnvia] = React.useState('');
    const [corBtnAcaoEnvia, setColorBtnAcaoEnvia] = React.useState('');
    const [corTextDestaqueEnvia, setColorTextDestaqueEnvia] = React.useState('');
    const [verificaVazioTel, setVerificaVazioTel] = React.useState(false);
    const history = useHistory();
  
    const capturaNome = (prop) => (event) => {
        setNome(event.target.value );
        InfoLoja.atualizaNomeLoja(event.target.value)
    };
    const capturaBairro = (prop) => (event) => {
        setBairro(event.target.value );
    };
    const capturaNomeSocio = (prop) => (event) => {
        setNomeSocio(event.target.value );
    };

    const capturaRazaoSocial = (prop) => (event) => {
        setRazaoSocial(event.target.value );
    };

    const capturaCpf = (prop) => (event) => {
        setCpf(event.target.value );
        let cpfsm = event.target.value.replace(/[^\d]+/g, '');
        setCpfSm(cpfsm);
    };
    
    const capturaCep= (prop) => (event) => {
        setCep(event.target.value );
        let cepSm = event.target.value.replace(/\D/g, '');
        setCepSm(cepSm);
    };
    const capturaCnpj = (prop) => (event) => {
        setCnpj(event.target.value );
        let cnpjsm = event.target.value.replace(/[^\d]+/g, '');
        setCnpjSm(cnpjsm);
    };
    const capturaUF = (prop) => (event) => {
        setUf(event.target.value );
    };
    const capturaRua = (prop) => (event) => {
        setRua(event.target.value );
    };
    const capturaNumero = (prop) => (event) => {
        setNumero(event.target.value );
    };
    const capturaCidade = (prop) => (event) => {
        setCidade(event.target.value );
    };
    const capturaTelUser = (prop) => (event) => {
        setTelUser(event.target.value );
        let phone = event.target.value.replace(/[^\d]+/g, '');
        setPhoneUser(phone);
    };
    const mascara = (prop) =>  (object) => {
  
        var telefone = object.target;
        if(telefone.value.length == 0)
                    telefone.value = '(' + telefone.value; 
                if(telefone.value.length == 3)
                    telefone.value = telefone.value + ') '; 
     
                if(telefone.value.length == 9)
                    telefone.value = telefone.value + '-';
    }

    useEffect(() => {
        capturaNome()
      },[nome]) 
      
      const string = history.location.pathname;
      var id = string.split('/')[3];
      var editar = string.split('/')[2];
      InfoLoja.idLoja(id);
      async function dadosLojasAfiliadas(){
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
        await api.get(`/v1/customers/company`,  {
            headers: headers
          })
          .then((response) => {
            for(let i = 0; response.data.length > i; i++){
                var endereco = response.data[i].address.length;
                if(id===response.data[i].id){

                    // cores
                var corPrimaria = response.data[i].checkout_data.main_color;
                  var corSecundaria = response.data[i].checkout_data.secondary_color;
                  var btnAcao = response.data[i].checkout_data.action_button_color;
                  var textDestaque = response.data[i].checkout_data.featured_text_color;
        
                  if(corPrimaria === null || corPrimaria === ''){
                      setColorEnvia('#F50057');
                  }else{
                      setColorEnvia(corPrimaria); 
                  }
                  if(corSecundaria === null || corSecundaria ===''){
                      setColorSecundariaEnvia('#F50057');
                  }else{
                      setColorSecundariaEnvia(corSecundaria);
                  }
                  if(btnAcao === null || btnAcao ===''){
                      setColorBtnAcaoEnvia('#F50057');
                  }else{
                      setColorBtnAcaoEnvia(btnAcao);
                  }
                  if(textDestaque === null || textDestaque ===''){
                      setColorTextDestaqueEnvia('#F50057');
                  }else{
                      setColorTextDestaqueEnvia(textDestaque);
                  }
                    // fim cores

                    setNome(response.data[i].name);
                    InfoLoja.atualizaNomeLoja(response.data[i].name)
                    setNomeSocio(response.data[i].partner_name);
                    setRazaoSocial(response.data[i].company_name);

                    if(response.data[i].partner_document === '' || response.data[i].partner_document === null){
                        setCpf(response.data[i].partner_document);
                        setCpfSm(response.data[i].partner_document)
                    }else{
                        setCpfSm(response.data[i].partner_document);
                        setCpf(response.data[i].partner_document_mask);
                    }

                    if(response.data[i].cnpj === '' || response.data[i].cnpj === null){
                        setCnpj(response.data[i].cnpj);
                        setCnpjSm(response.data[i].cnpj)
                    }else{
                        setCnpj(response.data[i].cnpj_mask);
                        setCnpjSm(response.data[i].cnpj)
                    }
                if(endereco !== 0){
                    if(response.data[i].address['0'].zip_code === '' || response.data[i].address['0'].zip_code === null){
                        setCep(response.data[i].address['0'].zip_code);
                    }else{
                        setCep(response.data[i].address['0'].zip_code_mask);
                    }
               
                    setUf(response.data[i].address['0'].state);
                    setBairro(response.data[i].address['0'].neighborhood);
                    setNumero(response.data[i].address['0'].number);
                    setCidade(response.data[i].address['0'].city);
                    setRua(response.data[i].address['0'].street);
                }
                    if(response.data[i].phone === '' || response.data[i].phone === null){
                        setTelUser(response.data[i].phone);
                    }else{
                        setTelUser(response.data[i].phone);
                    }
                }
            }
          })
      }
      useEffect(() => {
        dadosLojasAfiliadas()
      }, []);


async function enviaDadosLoja(event){
    event.preventDefault();
    if(!verificaCPF() || !ValidarCNPJ()){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
      }
      var ceP = cep.replace(/[^\d]+/g, '');
      var cpF = cpf.replace(/[^\d]+/g, '');
      var cnpJ = cnpj.replace(/[^\d]+/g, '');
      var phone = telUser.replace(/[^\d]+/g, '');

      const data = { 
        "name" : nome,
        "cnpj" : cnpJ,
        "phone" : phone,
        "street" : rua,
        "number" : numero,
        "city" : cidade,
        "postal_code" : ceP,
        "neighborhood" : bairro,
        "state" : uf,
        "complement" : "",
        "partner_name" : nomeSocio,
        "partner_document" : cpF,
        "company_name" : razaoSocial,
        "checkout_data": {
            "main_color" : corEnvia, 
            "secondary_color" : corSecundariaEnvia,
            "action_button_color" : corBtnAcaoEnvia,
            "featured_text_color" : corTextDestaqueEnvia
        }
      }
      InfoLoja.arrayDados(data);

    if(verificaVazioCnpj === false && verificaVazioCpf === false){
      if(razaoSocial !=='' &&  nome !=='' &&  uf !=='' && bairro !=='' && numero !=='' && cidade  !=='' && rua !=='' && nomeSocio !=='' && cpf !=='' && cep !==''  && cnpj !=='' && telUser !=='' ){
        if(editar !== 'editar'){
        await api.post('/v1/customers/company', data, {
            headers: headers
        })
        .then((response) => {
            InfoLoja.idLoja(response.data.id);
            setMessageSuccess('Loja cadastrada com sucesso!');
            setMessageErro('');
            setTimeout(() => {
                setMessageSuccess('');
                history.push(`/lojas/editar/${response.data.id}`)
          }, 1300);;
        })
        .catch((error) => {
            setMessageErro('Ocorreu um erro ao cadastrar a loja, revise os dados e tente novamente.');
            setMessageSuccess('');
            setTimeout(() => {
                setMessageErro('');
          }, 10000);
        })
    }else{
        await api.put(`/v1/customers/company/${id}`, data, {
            headers: headers
        })
        .then((response) => {
            InfoLoja.idLoja(response.data.id);
            setMessageSuccess('Atualizações salvas com sucesso!');
            setMessageErro('');
            setTimeout(() => {
                setMessageSuccess('');
                window.location.reload();
          }, 1300);
        })
        .catch((error) => {
            setMessageErro('Ocorreu um erro ao atualizar a loja, revise os dados e tente novamente.');
            setMessageSuccess('');
            setTimeout(() => {
                setMessageErro('');
          }, 10000);
        })
    }
    }else{
        if(nome  ==='' ){
            setVerificaVazioNome(true);
        } 
        if(nomeSocio  ==='' ){
            setVerificaVazioNomeSocio(true);
        } 
        if(razaoSocial ===''){
            setVerificaVazioRazaoSocial(true);
        }
        if(uf ===''){
            setVerificaVazioUF(true);
        }
        if(cidade ===''){
            setVerificaVazioCidade(true);
        }
        if(bairro ===''){
            setVerificaVazioBairro(true);
        }
        if(rua ===''){
            setVerificaVazioRua(true);
        }
        if(numero ===''){
            setVerificaVazioNumero(true);
        }
        if(cep ===''){
            setVerificaVazioCep(true);
        }
        if(telUser ===''){
            setVerificaVazioTel(true);
        }
        return true;
    }
    }
}else{
    setMessageErro('Ocorreu um erro ao atualizar a loja, revise os dados e tente novamente.');
    setMessageSuccess('');
    setTimeout(() => {
        setMessageErro('');
  }, 10000);
}
}

const mascaraCPF = (prop) =>  (object) => {
  
    var cpf = object.target;
    if(cpf.value.length == 3)
    cpf.value = cpf.value + '.';
            if(cpf.value.length == 7)
                cpf.value = cpf.value + '.'; 
            if(cpf.value.length == 11)
                cpf.value = cpf.value + '-';
}

const mascaraCNPJ = (prop) =>  (object) => {
  
    var cnpj = object.target;
    if(cnpj.value.length == 2)
    cnpj.value = cnpj.value + '.';
            if(cnpj.value.length == 6)
                cnpj.value = cnpj.value + '.'; 
            if(cnpj.value.length == 10)
                cnpj.value = cnpj.value + '/';
                if(cnpj.value.length == 15)
                cnpj.value = cnpj.value + '-';
}

const mascaraCEP = (prop) =>  (object) => {
  
    var cnpj = object.target;
            if(cnpj.value.length == 5)
                cnpj.value = cnpj.value + '-'; 
}

       function TestaCPF(strCPF) {
        var Soma;
        var Resto;
        var i;
        Soma = 0;
        if (strCPF == "00000000000") return false;

        for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11)) Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }
    function verificaCPF() {
        var retorno = TestaCPF(cpfsm);
        if (retorno === false) {
            setVerificaVazioCpf(true);
            return true;
        } else {
            setVerificaVazioCpf(false);
            return false;
        }
    }
    function ValidarCNPJ() {
        var i;
        var valida = new Array(6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
        var dig1 = new Number;
        var dig2 = new Number;
        var digito = new Number(eval(cnpjsm.charAt(12) + cnpjsm.charAt(13)));
        for (i = 0; i < valida.length; i++) {
            dig1 += (i > 0 ? (cnpjsm.charAt(i - 1) * valida[i]) : 0);
            dig2 += cnpjsm.charAt(i) * valida[i];
        }
        dig1 = (((dig1 % 11) < 2) ? 0 : (11 - (dig1 % 11)));
        dig2 = (((dig2 % 11) < 2) ? 0 : (11 - (dig2 % 11)));
        if (((dig1 * 10) + dig2) != digito) {
            setVerificaVazioCnpj(true);
            return true;
        } else {
            setVerificaVazioCnpj(false);
            return false;
        }
    }

// via Cep
function limpa_formulário_cep() {
    //Limpa valores do formulário de cep.
    setRua('');
    setBairro('');
    setUf('');
    setCidade('');
}

function meu_callback(conteudo) {
    //Atualiza os campos com os valores.
    setRua(conteudo.data.logradouro);
    setBairro(conteudo.data.bairro);
    setUf(conteudo.data.uf);
    setCidade(conteudo.data.localidade);
} 
 
const pesquisacep= (prop) => (event) => {

//Nova variável "cep" somente com dígitos.
var cep = event.target.value.replace(/\D/g, '');

//Verifica se campo cep possui valor informado.
if (cep != "") {

    //Expressão regular para validar o CEP.
    var validacep = /^[0-9]{8}$/;

    //Valida o formato do CEP.
    if(validacep.test(cep)) {

        //Preenche os campos com "..." enquanto consulta webservice.
        setRua('...');
        setBairro('...');
        setUf('...');
        setCidade('...');

        //chama a api do via cep
        dadosViaCep();

    } //end if.
    else {
        //cep é inválido.
        limpa_formulário_cep();
       setMessageErro('Formato de CEP inválido.');
       setMessageSuccess('');
       setTimeout(() => {
        setMessageErro('');
  }, 10000);
    }
} //end if.
else {
    //cep sem valor, limpa formulário.
    limpa_formulário_cep();
}
};

async function dadosViaCep(){
   await axios.get('https://viacep.com.br/ws/'+ cepfsm + '/json')
      .then((response) => {
      meu_callback(response)
      })
      .catch((error) => {
            //CEP não Encontrado.
    limpa_formulário_cep();
    setMessageErro('CEP não encontrado.');
    setMessageSuccess('');
    setTimeout(() => {
        setMessageErro('');
  }, 10000);
      })
}

return (
    <div className="Apps-form-style">
    <div className="App-form-style">
        <div className="Dados-banc-links-title">
        <h2 className="title-abas">Informações da Loja</h2>
        </div>
    <form onSubmit={enviaDadosLoja} className="FormMeusDados" noValidate autoComplete="off">
    {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                       {messageSuccess !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                    :''}
            <FormControl className={classes.root} component="fieldset">
            <h3 className="mb-0">1. Preencha os campos com as informações da loja:</h3>
                <FormGroup aria-label="position" row>
                    <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="nome"
                        label="Nome"
                        type="text"
                        value={nome}  
                        onChange={capturaNome()}
                        variant="outlined" 
                        required
                        error={verificaVazioNome && nome ==='' ? true:false}
                        helperText={verificaVazioNome && nome ==='' ?"*Campo obrigatório":''}
                         />
                 <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="razaoSocial"
                        label="Razao Social"
                        type="text"
                        value={razaoSocial}  
                        onChange={capturaRazaoSocial()}
                        variant="outlined" 
                        required
                        error={verificaVazioRazaoSocial && razaoSocial ==='' ? true:false}
                        helperText={verificaVazioRazaoSocial && razaoSocial ==='' ?"*Campo obrigatório":''}
                         />
                              <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="cnpj" 
                        label="CNPJ" 
                        type="tel" 
                        maxLength = "18"
                        inputProps ={{maxLength:18}}
                        onKeyPress={mascaraCNPJ()}
                        value={cnpj} 
                        onChange={capturaCnpj()} 
                        variant="outlined" 
                        required
                        error={verificaVazioCnpj ? true:false}
                        helperText={verificaVazioCnpj && cnpj ==='' ?"*Campo obrigatório":''}
                        helperText={verificaVazioCnpj && cnpj !=='' ?"*CNPJ inválido":''}
                        />
                        <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="tel" 
                        label="Celular/Telefone" 
                        type="tel" 
                        value={telUser} 
                        onChange={capturaTelUser()} 
                        variant="outlined" 
                        required
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                            }}
                        error={verificaVazioTel && telUser ==='' ? true:false}
                        helperText={verificaVazioTel && telUser ==='' ?"*Campo obrigatório":''}
                        />
                        <h3 className="h3-dados">2. Endereço da loja:</h3>
                        <div className={classes.envolveUFCep}>
                             <TextField
                        className={clsx(classes.textStyle,classes.inputCep)} 
                        color="secondary" 
                        id="cep" 
                        label="CEP" 
                        type="tel" 
                        maxLength = "9"
                        inputProps ={{maxLength:9}}
                        onKeyPress={mascaraCEP()}
                        value={cep} 
                        onChange={capturaCep()} 
                        onBlur={pesquisacep()}
                        variant="outlined" 
                        required
                        error={verificaVazioCep ? true:false}
                        helperText={verificaVazioCep && cep ==='' ?"*Campo obrigatório":''}
                        helperText={verificaVazioCep && cep !=='' ?"*CEP inválido":''}
                        />
                            <TextField
                        className={clsx(classes.textStyle,classes.inputEstado)} 
                        color="secondary"
                        id="estado"
                        label="UF"
                        type="text"
                        value={uf} 
                        onChange={capturaUF}
                        variant="outlined" 
                        required
                        disabled
                        error={verificaVazioUF ? true:false}
                        helperText={verificaVazioUF && uf ==='' ?"*Campo obrigatório":''}
                         />
                         </div>
                            <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="bairro"
                        label="Bairro"
                        type="text"
                        value={bairro}  
                        onChange={capturaBairro()}
                        variant="outlined" 
                        required
                        error={verificaVazioBairro && bairro ==='' ? true:false}
                        helperText={verificaVazioBairro && bairro ==='' ?"*Campo obrigatório":''}
                         /> 
                                 <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="cidade"
                        label="Cidade"
                        type="text"
                        value={cidade}  
                        onChange={capturaCidade()}
                        variant="outlined" 
                        required
                        disabled = {cidade !=='' ? true:false} 
                        error={verificaVazioCidade && cidade ==='' ? true:false}
                        helperText={verificaVazioCidade && cidade ==='' ?"*Campo obrigatório":''}
                         />
                                  <TextField
                        className={clsx(classes.textStyle,classes.inputCep)} 
                        color="secondary" 
                        id="rua" 
                        label="Rua" 
                        type="text"
                        value={rua} 
                        onChange={capturaRua()} 
                        variant="outlined" 
                        required
                        error={verificaVazioRua ? true:false}
                        helperText={verificaVazioRua && rua ==='' ?"*Campo obrigatório":''}
                        />
                            <TextField
                        className={clsx(classes.textStyle,classes.inputEstado)} 
                        color="secondary"
                        id="numero"
                        label="Número"
                        type="tel"
                        value={numero} 
                        onChange={capturaNumero()}
                        variant="outlined" 
                        required
                        error={verificaVazioNumero ? true:false}
                        helperText={verificaVazioNumero && numero ==='' ?"*Campo obrigatório":''}
                         />  
                        <h3 className="h3-dados">3. Preencha os campos com os dados do sócio:</h3>      
                             <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="nomeSocio"
                        label="Nome completo do sócio"
                        type="text"
                        value={nomeSocio}  
                        onChange={capturaNomeSocio()}
                        variant="outlined" 
                        required
                        error={verificaVazioNomeSocio && nomeSocio ==='' ? true:false}
                        helperText={verificaVazioNomeSocio && nomeSocio ==='' ?"*Campo obrigatório":''}
                         />
                            <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="cpf" 
                        label="CPF do sócio" 
                        type="tel" 
                        maxLength = "14"
                        inputProps ={{maxLength:14}}
                        onKeyPress={mascaraCPF()}
                        value={cpf} 
                        onChange={capturaCpf()} 
                        variant="outlined" 
                        required
                        error={verificaVazioCpf ? true:false}
                        helperText={verificaVazioCpf && cpf ==='' ?"*Campo obrigatório":''}
                        helperText={verificaVazioCpf && cpf !=='' ?"*CPF inválido":''}
                        />
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button type="submit" variant="contained" color="secondary">Salvar</Button>
            </div>
        </form>
    </div>
    </div>
);
} 