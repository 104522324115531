import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './css/LoginRedeSocial.css';
import Logo from '../../Assets/images/payhow_logo.fw.png';
import Termos from '../../Assets/pdf/Termos_De_Uso.pdf';
import Politica from '../../Assets/pdf/Politica_De_Privacidade.pdf';
import FormLoginRedeSocial from '../LoginRedeSocial/components/Form/formulario';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,   
  }
}));

export default function LoginRedeSocial(history) {
  var login = history.location.state.login;
  var nome = history.location.state;
  var image = '';
 
  
  if(login === 'facebook'){
  // Pegando imagem de perfil do usuário presente no retorno
    image = history.location.state.params.picture.data.url;
  }
  else{ 
  // Pegando imagem de perfil do usuário presente no retorno
    image = history.location.state.params.profileObj.imageUrl;
  }
  const classes = useStyles();
  const History = useHistory();

  
  return (
    <div className="login-envolve-div__login">
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} sm={6} className="login-esconde-mob">
                    <Paper className="login-leteral_image">
                      <div className="loginRede-background_image">
                      <div className="login-image_logo">
                        <img src={Logo} alt="Logo PAYHOW" text="Logo PAYHOW"/>
                      </div>
                      </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper className="login-lateral_form">
                        <div className="login-envolve_link_form">
                        <div className="login-image_logo_mobile">
                        <img src={Logo} alt="Logo PAYHOW" text="Logo PAYHOW"/>
                      </div>
                      <div>
                        <div className="login-rede-social-imagePerfil">
                        <img src={image} alt="Imagem de perfil" text="Imagem de perfil"/>
                        </div>
                      </div>
                          <div className="login-envolve_form login-envolve_formRedeSocial">
                           <FormLoginRedeSocial props={nome}/>
                          </div>
                        </div>
                        <div className="login-footer">
                          <a className="footer-link" target="_blank" href={Termos}>Termos</a>
                          <a className="footer-link" target="_blank" href={Politica}>Política de Privacidade</a>
                          <p>PAYHOW©2020</p>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    </div>
  );
}

