import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from './ModalDelete';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import api from '../../../Services/api';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    width: "23%",
    minHeight: "21rem !important",
    maxHeight: "21rem",
    margin: "1%",
    backgroundColor: "#fff !important"
  },
  media: {
    height: 170,
  },
  btnFooter:{
      justifyContent: "flex-end",
      borderTop: "1px solid #F3F3F3"
  }
});

export default function MediaCard() {
  const classes = useStyles();
  const [modal, setModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();
  const [dadosLoja, setDadosLoja] = React.useState('');
  const token = localStorage.getItem('tokenUser');
  const [search, setSearch] = React.useState('');
  const [dadosLojaBusca, setDadosLojaBusca] = React.useState('');
  const [lojaDeletada, setLojaDeletada] = React.useState(false);
  const [messageErro, setMessageErro] = React.useState('');
  const [messageSuccess, setMessageSuccess] = React.useState('');
  const history = useHistory();

  const openModal = (id) => {
    setModal(true)
    setDeleteId(id)
  }
  const deletar = (deleteStatus) => {
   if(deleteStatus){
    deletaLojasAfiliada();
   }
  }
  const changeModal = (modal) => {
    setModal(modal)
}
async function deletaLojasAfiliada(){
  var div = document.getElementById('divAlert');
  div.style.display = 'block';
  const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
  await api.delete(`/v1/customers/company/${deleteId}`,  {
      headers: headers
    })
    .then((response) => {
      if(response.data.status){
      setLojaDeletada(true)
      setMessageSuccess('Loja deletada com sucesso!')
      setMessageErro('')
      setTimeout(() => {
        window.location.reload()
        setMessageSuccess('');
  }, 1500);
  }else{
        setLojaDeletada(false)  
        setMessageSuccess('') 
        setMessageErro('Erro: uma loja padrão não pode ser excluida!') 
        setTimeout(() => {
          setMessageErro('');
      }, 1500);
      }
    }).catch((error) => {
      setLojaDeletada(false)  
      setMessageSuccess('') 
      setMessageErro('Não foi possível deletar essa loja, tente novamente mais tarde!') 
      setTimeout(() => {
        setMessageErro('');
  }, 1500);
    })
    setTimeout(() => {
      div.style.display = 'none';
    }, 3000);
}

async function buscaLojasAfiliadas(name){
  const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
  await api.get(`/v1/customers/company?name=${name}`,  {
      headers: headers
    })
    .then((response) => {
      setDadosLojaBusca(response.data);
    })
}
useEffect(() => {
  buscaLojasAfiliadas(search)
}, [search,lojaDeletada]);
  return (
    <div className="w-100">
      <div id="divAlert" className="mb-5">
      {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                       {messageSuccess !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                    :''}
      </div>
      <TextField className="buscaLoja"
        id="outlined-basic" 
        value={search}
        onChange={(ev) => setSearch(ev.target.value)} 
        label="Buscar Loja"  
        color="secondary"  
        variant="outlined" 
        InputProps={{
          endAdornment: <InputAdornment position="start"><SearchIcon className="iconSearchColor"/></InputAdornment>,
        }}/>
          <div className="envolveCardsLojas">
          {dadosLojaBusca.length !== 0?
        dadosLojaBusca.map((infoLoja) => ( 
    <Card className={classes.root}>
      <div>
        <CardMedia
          className={classes.media}
          image={infoLoja.photo}
          title={infoLoja.name}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {infoLoja.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          {infoLoja.phone === null ? infoLoja.phone : infoLoja.phone_mask }
          </Typography>
        </CardContent>
      </div>
      <CardActions className={classes.btnFooter}>
      <IconButton aria-label="settings" onClick={() => {history.push(`/lojas/editar/${infoLoja.id}`)}}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="settings">
            <DeleteIcon onClick={() => openModal(infoLoja.id)}/>
          </IconButton>
      </CardActions>
      <Modal open={modal} onChange={changeModal.bind(modal)} change={deletar.bind()}/>
    </Card>
      )):
      <div className="mt-2 cabecalho-personalizado">
      <h3>Nenhuma loja encontrada.</h3>
      </div>
      }
    </div>
    </div>
  );
}