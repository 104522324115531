import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Upload from "./upload";
import api from "../../../Services/api";
import Snackbar from "./Snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  paper: {
    padding: theme.spacing(4),
    color: theme.palette.text.secondary,
    backgroundColor: "#F3F3F3",
  },
  form: {
    width: "90%",
  },
  item: {
    marginTop: 15,
    marginLeft: 15,
  },
  button: {
    marginTop: 30,
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 15,
  },
  btn: {
    marginRight: 15,
  },
  select: {
    width: "50%",
    marginTop: 15,
    marginLeft: 15,
  },
  foto: {
    marginLeft: 15,
    marginTop: 15,
  },
}));

export default function InformacoesForm() {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();

  const [tipo, setTipo] = React.useState("");
  const [foto, setFoto] = React.useState("");
  const [titulo, setTitulo] = React.useState("");
  const [valor, setValor] = React.useState("");
  const [id, setId] = React.useState();
  const [alert, setAlert] = React.useState(false);
  const [alerterror, setAlerterror] = React.useState();
  const [mensagem, setMensagem] = React.useState("");
  const [photo, setPhoto] = React.useState("");

  useEffect(() => {
    async function buscar() {
      const token = localStorage.getItem("tokenUser");

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer" + token,
      };
      await api
        .get("/v1/customers/profile", {
          headers: headers,
        })
        .then((response) => {
          setId(localStorage.getItem("companiesId"));
        })
        .catch((error) => {});
    }
    buscar();
  }, []);

  useEffect(() => {
    async function listar() {
      const token = localStorage.getItem("tokenUser");

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer" + token,
      };
      await api
        .get(`/v1/company/${id}/payment_link/${params.id}`, {
          headers: headers,
        })
        .then((response) => {
          setTitulo(response.data.name);
          setTipo(response.data.product_type);
          setPhoto(response.data.photo);

          let inteiro = response.data.amount;
          inteiro = inteiro.replace(/[^\d]+/g, "");

          var tmp = inteiro + "";
          tmp = tmp.replace(/([0-9]{2})$/g, ",$1");

          setValor(tmp);
        })
        .catch((error) => {});
    }
    listar();
  }, [id]);

  const handleChange = (event) => {
    setTipo(event.target.value);
  };

  const handleChangeFoto = (foto) => {
    setFoto(foto);
  };

  const handleClick = () => {
    setAlert(false);
    let inteiro = valor;
    inteiro = inteiro.replace(/[^\d]+/g, "");

    async function salvar() {
      const data = {
        name: titulo,
        amount: inteiro,
        method_payments: [],
        photo: foto,
      };

      const token = localStorage.getItem("tokenUser");

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer" + token,
      };
      await api
        .post(`/v1/company/${id}/payment_link`, data, {
          headers: headers,
        })
        .then((response) => {
          setAlert(true);
          setAlerterror(false);
          setMensagem("Cadastro feito com sucesso");
          setTimeout(function () {
            history.push("/links-personalizados");
          }, 1000);
        })
        .catch((error) => {
          setAlert(true);
          setAlerterror(true);

          if (error.response.data.errors.message.amount !== undefined) {
            setMensagem("Verifique o campo valor do link");
          } else if (error.response.data.errors.message.name !== undefined) {
            setMensagem("Verifique o campo nome do link");
          } else {
            setMensagem("Houve um erro");
          }
        });
    }
    salvar();
  };

  const handleClickEditar = () => {
    setAlert(false);
    let inteiro = valor;
    inteiro = inteiro.replace(/[^\d]+/g, "");
    async function Editar() {
      const data = {
        name: titulo,
        amount: inteiro,
        method_payments: [],
        photo: foto,
        enable: 1,
      };

      const token = localStorage.getItem("tokenUser");

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer" + token,
      };
      await api
        .put(`/v1/company/${id}/payment_link/${params.id}`, data, {
          headers: headers,
        })
        .then((response) => {
          setAlert(true);
          setAlerterror(false);
          setMensagem("Cadastro alterado com sucesso");
          setTimeout(function () {
            history.push("/links-personalizados");
          }, 1000);
        })
        .catch((error) => {
          setAlert(true);
          setAlerterror(true);

          if (error.response.data.errors.message.amount !== undefined) {
            setMensagem("Verifique o campo valor do link");
          } else if (error.response.data.errors.message.name !== undefined) {
            setMensagem("Verifique o campo nome do link");
          } else {
            setMensagem("Houve um erro");
          }
        });
    }
    Editar();
  };
  const mudaValor = (event) => {
    let str = event.target.value.replace(/\D/g, "");
    str = str.replace(/([0-9]{2})$/g, ",$1");
    setValor(str);
  };

  return (
    <div className="link-pers">
      <div className={classes.root}>
        <Grid container className={classes.espacamento}>
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={3}>
              <h1>Informações do Ultra Link</h1>
              <hr />

              <form
                className={classes.root}
                noValidate
                autoComplete="off"
                className={classes.form}
              >
                <TextField
                  id="outlined-basic"
                  value={titulo}
                  label="Titulo do link"
                  variant="outlined"
                  className={classes.item}
                  onChange={(event) => setTitulo(event.target.value)}
                />

                <TextField
                  id="outlined-basic"
                  value={valor}
                  label="Valor do link"
                  variant="outlined"
                  className={classes.item}
                  onChange={mudaValor}
                />

                <FormControl
                  variant="outlined"
                  type="number"
                  className={classes.formControl}
                  className={(classes.item, classes.select)}
                >
                  <InputLabel>Tipo do link</InputLabel>
                  <Select
                    value={tipo}
                    onChange={handleChange}
                    label="Tipo do link"
                  >
                    <option aria-label="None" value="" />
                    <option value={"fisico"}>Produto Físico</option>
                    <option value={"servico"}>Serviço </option>
                    <option value={"digital"}>Digital</option>
                  </Select>
                </FormControl>

                <div className={classes.foto}>
                  <Upload
                    photo={photo}
                    params={params}
                    onChange={handleChangeFoto.bind(foto)}
                  />
                </div>
              </form>

              <div className={classes.button}>
                <Button
                  variant="contained"
                  className={classes.btn}
                  onClick={() => {
                    history.push("/links-personalizados");
                  }}
                >
                  Cancelar
                </Button>

                {params.editar ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClickEditar}
                  >
                    Salvar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClick}
                  >
                    Salvar
                  </Button>
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Snackbar alert={alert} error={alerterror} mensagem={mensagem} />
      </div>
    </div>
  );
}
