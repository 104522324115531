import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import api from "../../../../Services/api";
import { Checkbox, InputLabel, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(3),
      width: "100%",
      marginLeft: 0,
      marginRight: 0,
    },
  },
  textStyle: {
    marginTop: "1rem",
    width: "100%",
  },
  iconLeft: {
    width: "11px",
    height: "11px",
  },
  formControl: {
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inputCep: {
    width: "70% !important",
  },
  inputEstado: {
    width: "28% !important",
    marginLeft: ".5rem",
  },
  envolveUFCep: {
    width: "100%",
  },
  buttonUpload: {
    border: "none",
    borderRadius: "10px",
    height: "40px",
    backgroundColor: "#333",
    color: "#FFF",
    fontSize: "16px",
    fontWeight: "600",
    cursor: "pointer",
    width: "100%",
    maxWidth: "300px",
    marginTop: "15px",
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

export default function FormInfoLoja(InfoLoja) {
  const classes = useStyles();
  const history = useHistory();
  const [sucessRequest, setSuccessRequest] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [disabledButton, setDisabledButton] = React.useState(true);
  const token = localStorage.getItem("tokenUser") || "";
  const [fileSelected, setFileSelected] = React.useState("");
  const idCompany = localStorage.getItem("companiesId");
  const [value, setValue] = React.useState("");

  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = formatCurrency(inputValue);

    setValue(inputValue);
  };

  const formatCurrency = (value) => {
    if (!value) return "";

    const floatValue = parseFloat(value) / 100;
    const formattedValue = floatValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formattedValue;
  };

  const [bodyCreate, setBodyCreate] = React.useState({
    name: "",
    amount: "",
    product_type: "", //'digital','physical','service'
    enable: 0, // 0,1
    address_required: 0, //0,1
    photo: "",
  });

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  async function createLink(event) {
    event.preventDefault();
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer" + token,
    };

    await api
      .post(
        `/v1/company/${idCompany}/payment_link`,
        {
          name: bodyCreate?.name,
          amount: String(value)?.replace(/[^0-9s]/g, ""),
          product_type: bodyCreate?.product_type,
          enable: bodyCreate?.enable,
          address_required: bodyCreate?.address_required,
          photo: bodyCreate?.photo,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setSuccessRequest(true);
        setShowAlert(true);
        setTimeout(() => {
          history.push("/links");
          setShowAlert(false);
        }, 2000);
      })
      .catch((error) => {
        setSuccessRequest(false);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 2000);
      });
  }
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileSelected(file?.name);
    const reader = new FileReader();

    reader.onloadend = () => {
      setBodyCreate({ ...bodyCreate, photo: reader.result });
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (
      bodyCreate?.name === "" ||
      value === "" ||
      bodyCreate?.product_type === "" ||
      bodyCreate?.name === ""
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [bodyCreate]);

  return (
    <div className="Apps-form-style">
      <div className="App-form-style">
        <div className="Dados-banc-links-title">
          <h2>Informações do link</h2>
        </div>
        <form
          className="FormMeusDados"
          noValidate
          autoComplete="off"
          onSubmit={createLink}
        >
          <FormControl
            className={classes.root}
            component="fieldset"
            style={{ width: "100%" }}
          >
            <h3 className="mb-0">
              Preencha os campos com as informações do link:
            </h3>
            <FormGroup variant="outlined" color="secondary">
              <FormControl
                variant="outlined"
                color="secondary"
                label="Status do link *"
                className="selectTypeCreate"
              >
                <InputLabel>Status do link *</InputLabel>
                <Select
                  id="filter"
                  className="select"
                  label="Status do link *"
                  value={bodyCreate?.enable}
                  onChange={(event) =>
                    setBodyCreate({ ...bodyCreate, enable: event.target.value })
                  }
                >
                  <MenuItem value={1}>Ativo</MenuItem>
                  <MenuItem value={0}>Inativo</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                color="secondary"
                label="Tipo de produto *"
                className="selectTypeCreate"
                style={{ marginTop: "15px" }}
              >
                <InputLabel>Tipo de produto *</InputLabel>
                <Select
                  id="filter"
                  className="select"
                  label="Tipo de produto *"
                  value={bodyCreate?.product_type}
                  onChange={(event) =>
                    setBodyCreate({
                      ...bodyCreate,
                      product_type: event.target.value,
                    })
                  }
                >
                  <MenuItem value={"digital"}>Produto Digital</MenuItem>
                  <MenuItem value={"physical"}>Produto Físico</MenuItem>
                  <MenuItem value={"service"}>Serviço</MenuItem>
                </Select>
              </FormControl>
              <TextField
                className={classes.textStyle}
                color="secondary"
                id="razaoSocial"
                label="Descrição"
                type="text"
                value={bodyCreate?.name}
                onChange={(event) =>
                  setBodyCreate({
                    ...bodyCreate,
                    name: event.target.value,
                  })
                }
                variant="outlined"
                required
              />
              <TextField
                label="Valor"
                variant="outlined"
                color="secondary"
                required
                value={value}
                onChange={handleInputChange}
                style={{ marginTop: "15px" }}
              />
          

              <FormControl>
                <div
                  style={{
                    margin: "0px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "-10px",
                    marginTop: "10px",
                  }}
                >
                  <Checkbox
                    checked={bodyCreate?.address_required === 1}
                    value={bodyCreate?.address_required}
                    onChange={() =>
                      setBodyCreate({
                        ...bodyCreate,
                        address_required:
                          bodyCreate?.address_required === 0 ? 1 : 0,
                      })
                    }
                  />
                  <span style={{ fontSize: "16px" }}>
                    Solicitar dados do endereço
                  </span>
                </div>
              </FormControl>
              <FormControl>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  <button
                    onClick={handleButtonClick}
                    type="button"
                    className={classes.buttonUpload}
                  >
                    Enviar imagem{" "}
                  </button>
                  {fileSelected && <b>{fileSelected}</b>}
                </div>
              </FormControl>
            </FormGroup>
          </FormControl>
          <div className="PiPay-button">
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={disabledButton}
              style={{ width: "100%", maxWidth: "250px" }}
            >
              Salvar
            </Button>
          </div>
        </form>
      </div>
      <div id="AlertStatus" className="AlertStatus">
        {showAlert && (
          <Alert
            variant="filled"
            severity={sucessRequest ? "success" : "error"}
          >
            {sucessRequest ? "Link criado com sucesso" : "Erro ao criar link"}
          </Alert>
        )}
      </div>
    </div>
  );
}
