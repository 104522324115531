import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/FiberManualRecordOutlined";
import { Link } from "react-router-dom";
import Navegacao from "../../navigation";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useEffect } from "react";
// icons menu lateral
import InsertLinkOutlinedIcon from "@material-ui/icons/InsertLink";
import ExtensionOutlinedIcon from "@material-ui/icons/ExtensionOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import WbIridescentOutlinedIcon from "@material-ui/icons/WbIridescentOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "absolute",
    width: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    zIndex: 0,
  },
  menuButton: {
    margin: 0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    backgroundColor: "#333333",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#333333",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  barra: {
    width: 0,
    left: 0,
    position: "relative",
    height: "100%",
  },
  iconMenu: {
    backgroundColor: "#333333",
    color: "#CCCCCC",
    "&:hover": {
      color: "#FCCD44",
    },
  },
  text: {
    color: "#ffffff",
    paddingLeft: 24,
    paddingRight: 24,
    textDecoration: "none",
    "&:hover": {
      color: "#FCCD44",
    },
  },
  textPrinc: {
    color: "#ffffff",
    textDecoration: "none",
    "&:hover": {
      color: "#FCCD44",
    },
  },
  icon: {
    color: "#CCCCCC",
    "&:hover": {
      color: "#FCCD44",
    },
  },
  submenu: {
    paddingLeft: 40,
    backgroundColor: "#424242",
    whiteSpace: "break-spaces",
  },
  submenuIcon: {
    minWidth: "1.6rem",
  },
  submenuColapse: {
    paddingLeft: 0,
    backgroundColor: "#424242",
    whiteSpace: "row",
    paddingLeft: 24,
    paddingRight: 24,
  },
  barraIconMenu: {
    width: 73,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#333333",
    padding: 0,
    marginTop: 80,
    position: "fixed",
  },
  positionDrawer: {
    marginTop: 80,
    height: "90vh",
    zIndex: 1,
  },
  lista: {
    marginBottom: 100,
  },
  iconBola: {
    width: "1rem",
    heigth: "1rem",
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openColapse, setOpenColapse] = React.useState(false);
  const [openPedidos, setOpenPedidos] = React.useState(false);
  // const [openLinks, setOpenLinks] = React.useState(false);
  const [openMarketing, setOpenMarketing] = React.useState(false);
  const [openRelatorios, setOpenRelatorios] = React.useState(false);
  const [openApps, setOpenApps] = React.useState(false);
  const [openConfiguracoes, setOpenConfiguracoes] = React.useState(false);
  const history = useHistory();

  const handleClick = () => {
    setOpenColapse(!openColapse);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const mudaPedidos = () => {
    setOpenPedidos(!openPedidos);
    if (!open) {
      handleDrawerOpen();
    }
  };

  useEffect(() => {
    if (history.location.pathname === "/configuracoes") {
      setOpenConfiguracoes(true);
    }
  }, []);

  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.appBar, classes.barra, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.barraIconMenu}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon className={[classes.icon, classes.iconMenu]} />
          </IconButton>
        </Toolbar>
      </div>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.positionDrawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose} className={classes.icon}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List className={[classes.lista, "menuScroll"]}>
          <Link to="/home">
            <ListItem button className={classes.text}>
              <ListItemIcon>
                {open ? (
                  <HomeOutlinedIcon className={classes.icon} />
                ) : (
                  <Tooltip title="Home" placement="right">
                    <HomeOutlinedIcon className={classes.icon} />
                  </Tooltip>
                )}
              </ListItemIcon>
              <Link to="/home" className={classes.textPrinc}>
                <ListItemText primary="Home" />
              </Link>
            </ListItem>
          </Link>
          <ListItem button onClick={mudaPedidos} className={classes.text}>
            <ListItemIcon onClick={handleDrawerOpen}>
              {open ? (
                <ShoppingCartOutlinedIcon className={classes.icon} />
              ) : (
                <Tooltip title="Pedidos" placement="right">
                  <ShoppingCartOutlinedIcon className={classes.icon} />
                </Tooltip>
              )}
            </ListItemIcon>
            <ListItemText primary="Pedidos" />
            {openPedidos ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openPedidos} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="/pedidos">
                <ListItem
                  button
                  className={[
                    classes.text,
                    open ? classes.submenu : classes.submenuColapse,
                  ]}
                >
                  <ListItemIcon className={classes.submenuIcon}>
                    {open ? (
                      <StarBorder
                        className={clsx(classes.icon, classes.iconBola)}
                      />
                    ) : (
                      <Tooltip title="Ver todos" placement="right">
                        <StarBorder
                          className={clsx(classes.icon, classes.iconBola)}
                        />
                      </Tooltip>
                    )}
                  </ListItemIcon>
                  <Link to="/pedidos" className={classes.text}>
                    <ListItemText primary="Ver todos" />
                  </Link>
                </ListItem>
              </Link>
            </List>
          </Collapse>
          <Link to="/lojas">
            <ListItem button className={classes.text}>
              <ListItemIcon>
                {open ? (
                  <StorefrontOutlinedIcon className={classes.icon} />
                ) : (
                  <Tooltip title="Minhas lojas" placement="right">
                    <StorefrontOutlinedIcon className={classes.icon} />
                  </Tooltip>
                )}
              </ListItemIcon>
              <Link to="/lojas" className={classes.textPrinc}>
                <ListItemText primary="Minhas lojas" />
              </Link>
            </ListItem>
          </Link>
          <Link to="/apps">
            <ListItem button className={classes.text}>
              <ListItemIcon>
                {open ? (
                  <ExtensionOutlinedIcon className={classes.icon} />
                ) : (
                  <Tooltip title="Aplicativos e Integrações" placement="right">
                    <ExtensionOutlinedIcon className={classes.icon} />
                  </Tooltip>
                )}
              </ListItemIcon>
              <Link to="/apps" className={classes.textPrinc}>
                <ListItemText primary="Aplicativos e Integrações" />
              </Link>
            </ListItem>
          </Link>
          <Link to="/links">
            <ListItem button className={classes.text}>
              <ListItemIcon>
                {open ? (
                  <InsertLinkOutlinedIcon className={classes.icon} />
                ) : (
                  <Tooltip title="Links de pagamento" placement="right">
                    <InsertLinkOutlinedIcon className={classes.icon} />
                  </Tooltip>
                )}
              </ListItemIcon>
              <Link to="/links" className={classes.textPrinc}>
                <ListItemText primary="Links de pagamento" />
              </Link>
            </ListItem>
          </Link>
          <Link to="/configuracoes-frete">
            <ListItem button className={classes.text}>
              <ListItemIcon>
                {open ? (
                  <SettingsApplicationsOutlinedIcon className={classes.icon} />
                ) : (
                  <Tooltip title="Configurações de Frete" placement="right">
                    <SettingsApplicationsOutlinedIcon
                      className={classes.icon}
                    />
                  </Tooltip>
                )}
              </ListItemIcon>
              <Link to="/configuracoes-frete" className={classes.textPrinc}>
                <ListItemText primary="Configurações de Frete" />
              </Link>
            </ListItem>
          </Link>
          <a target="_blank" href="https://blog.payhow.com.br/tutoriais/">
            <ListItem button className={classes.text}>
              <ListItemIcon>
                {open ? (
                  <HelpOutlineIcon className={classes.icon} />
                ) : (
                  <Tooltip title="Ajuda" placement="right">
                    <HelpOutlineIcon className={classes.icon} />
                  </Tooltip>
                )}
              </ListItemIcon>
              <ListItemText primary="Ajuda" />
            </ListItem>
          </a>
        </List>
      </Drawer>

      <Navegacao />
    </div>
  );
}
