import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
 
    title: {
        color:'#f50057',
       
        
    },
    btnCadastrar:{
        paddingTop: 10,
        paddingBottom:10,
        borderRadius:25,
        width: '70%',
        fontWeight: '400',
        fontSize: 16
    },
    containerBtn:{
        justifyContent: 'center',
        marginBottom: 10,
    },
   
    
  }));

export default function ResponsiveDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();


  useEffect(() => {
      
        setOpen(props.open);
        
  },[props.open])

  const handleClose = () => {
    setOpen(false);
    var dados = props.user;
    history.push('/home?load');  
    const token = dados.token
    const companiesId = localStorage.getItem('companiesId')
    localStorage.setItem('tokenUser',token);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.title} color="secondary" id="responsive-dialog-title">{"Parabéns"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seu cadastro foi realizado com sucesso
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.containerBtn}>
          <Button variant="contained" color="secondary" onClick={handleClose}  autoFocus className={classes.btnCadastrar}>
            ok
          </Button>
         
        </DialogActions>
      </Dialog>
    </div>
  );
}