import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AppCorreios from './FormApps/FormFreteCorreios';
import AppRetirarLocal from './FormApps/FormRetirarLocal';
import FreteGratis from './FormApps/FreteGratis';
import MelhorEnvio from './FormApps/MelhorEnvio';
import FretePersonalizado from './FormApps/FormPersonalizado';
import FreteFixo from './FormApps/FormFixo';
import {useHistory} from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
}));

export default function FormApp() {
  const history = useHistory();
  const classes = useStyles();
  const [idApp, setIdApp] = React.useState('');
  const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
  const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
  

  useEffect(() => {
 var infoApp = localStorage.getItem('idApp');
 var idAplicativos = JSON.parse(infoApp);

 setIdApp(idAplicativos.slug);
},[]) 
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
            <Paper className={classes.paper}>
              { idApp == 'correios' ? <AppCorreios /> 
              : idApp == 'retirar-local' ? <AppRetirarLocal />
              : idApp == 'frete-gratis' ? <FreteGratis />
              : idApp == 'melhor-envio' ? <MelhorEnvio />
              : idApp == 'frete-personalizado' ? <FretePersonalizado />
              : idApp == 'frete-fixo' ? <FreteFixo />
              :'Ainda não possui formulário!'}
            </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
   
    </div>
  );
}