import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import '../css/formulario.css';
import Button from '@material-ui/core/Button';
import Btnfacebook from '../../../Components/facebook'
import Btngoogle from '../../../Components/google';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Checkbox from '@material-ui/core/Checkbox';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Termos from '../../../Assets/pdf/Termos_De_Uso.pdf';
import Politica from '../../../Assets/pdf/Politica_De_Privacidade.pdf';
import {useHistory} from 'react-router-dom';
import api from '../../../Services/api';
import Modal from './modal'
import CircularLoad from '../../../Components/Load';



const useStyles = makeStyles((theme) => ({
 
  borderRadius: {
    borderRadius: 50,
    textTransform: "capitalize",
    paddingLeft: 30,
    paddingRight:30,
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 500,
    fontFamily: 'Lato, Arial, Helvetica, sans-serif',
    '&:hover': {
        backgroundColor: '#F50272',
        color:'#fff'
      },
  },
  margin: {
    marginBottom: 20 ,
  },
  btnCadastrar:{
    paddingTop: 15,
    paddingBottom:15,
    borderRadius:25,
    width: '100%',
    fontWeight: '700',
    fontSize: 16
  },
  
}));


export default function Cadastro() {

  const history = useHistory();

  const classes = useStyles();

  const [values, setValues] = React.useState({
    password: '',
    confirm:'',
    showPassword: false,
    showConfirm: false,
  });
  const [check, setCheck] = React.useState({
    checked: false,
  });

  const [nome, setNome] = React.useState();

  const [email, setEmail] = React.useState();

  const [nomeErro, setNomeErro] = React.useState(false);
  const [senhaErro, setSenhaErro] = React.useState(false);
  const [emailErro, setEmailErro] = React.useState(false);
  const [resultado, setResultado] = React.useState(true);
  const [errors, setErrors] = React.useState({
      check: true,
  });

  const [erroDescriptionNome, setErroDescriptionNome] = React.useState('');
  const [erroDescriptionEmail, setErroDescriptionEmail] = React.useState('');
  const [erroConfirmaSenha, setErroConfirma] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const [dados,setDados] = React.useState(false);

  const handleChangeChecked = (event) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };

  const handleChangeConfirm = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangePassword = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowConfirm = () => {
    setValues({ ...values, showConfirm: !values.showConfirm });
  };

  const handleMouseDownConfirm = (event) => {
    event.preventDefault();
  };
  

  ///////////////////// função de chamada de api/////////////////////////////

  const handleSubmit = (event) => {
    event.preventDefault();
    setResultado(false);

    if(values.password !== values.confirm){
      setResultado(true);
          setSenhaErro(true)
          setErroConfirma('As senhas tem que ser iguais')
          setValues({...values, password:'', confirm: '' })
    }
    if(check.checked){

    let campaign = history.location.search
    
    setErrors({ ...errors, check: true });
    

    if (campaign){
      let campaignPrev = campaign.indexOf("[")
      let campaignNext = campaign.indexOf("]")
      campaign = campaign.slice(campaignPrev +1, campaignNext )
    }

    const login = {
        name: nome,
        email: email,
        password: values.password,
        campaign_origin: campaign,
    }
  
      async function loadLogin(){
        
          try{
            const response = await api.post('/v1/customers/register', 
            login,
            {
              headers:{
                'Accept': 'application/json', 
                'Content-Type': 'application/json',
              }
            });
            setResultado(true);
            
            setModalOpen(true)
            setDados(response.data);

          }catch(error){
            setResultado(true);
            const err = error.response.data.errors.message

            if(err.name){
                setNomeErro(true)
                setErroDescriptionNome(err.name[0])
            }     
            if(err.email){
              setEmailErro(true)
              setErroDescriptionEmail(err.email[0])
          }                  

            
          }  
      }

      loadLogin()
    }else{
      setResultado(true);
      setErrors({ ...errors, check: false });
    
    }

  }

  function ir(){
         history.push('/?load')
        }
  const termos = <span>Declaro que li e aceito os <span>Termos</span> e <span>Política de Privacidade</span></span>
  

  return (
        <div>
            <div className="cadastro-possui-conta">
              <p>Já possui uma conta?</p>
              <Button onClick={ir} variant="outlined" color="secondary" className={classes.borderRadius}>
                entrar
              </Button>
            </div>
            <div className="cadastro-container-formulario">
                <h1 className="cadastro-titulo">Cadastro</h1>
                <div className="cadastro-redes">      
                    <Btnfacebook/>
                    <Btngoogle/>
                </div>  
                <div className="cadastro-ou">
                    <span>ou</span>
                </div>
              <form  onSubmit={handleSubmit} >
                <div className="cadastro-body-formulario">
                    <TextField required onChange={e => setNome(e.target.value)} error={nomeErro} id="nomeCadastro" label="Nome" variant="outlined" className={classes.margin} helperText={erroDescriptionNome} />
           
                    <TextField required type="email" onChange = {e => setEmail(e.target.value)} id="emailCadastro" label="E-mail" variant="outlined" error={emailErro} className={classes.margin} helperText={erroDescriptionEmail} autoComplete="email"/>

                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" error={senhaErro}   >

                    <InputLabel required htmlFor="outlined-adornment-password"  className={classes.margin} >Senha</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        required
                        onChange={handleChangePassword('password')}
                        autoComplete="new-password" 
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            
                            >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                        labelWidth={70}
                    />

                    {senhaErro ? <FormHelperText id="component-error-text">{erroConfirmaSenha}</FormHelperText> : ''}
                    </FormControl>

                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" error={senhaErro} >

                    <InputLabel required htmlFor="outlined-password">Confirmar senha</InputLabel>
                    <OutlinedInput
                        id="outlined-password"
                        type={values.showConfirm ? 'text' : 'password'}
                        value={values.confirm}
                        required
                        onChange={handleChangeConfirm('confirm')}
                        autoComplete="confirm-password"                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirm}
                            onMouseDown={handleMouseDownConfirm}
                            edge="end"
                            >
                            {values.showConfirm ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                        labelWidth={70}
                    />
                    {senhaErro ? <FormHelperText id="component-error-confrim">{erroConfirmaSenha}</FormHelperText> : ''}
                    </FormControl>
                   
                </div>
               
                <div className="cadastro-termos">
                  <FormControlLabel
                    control={<Checkbox checked={check.checked} onChange={handleChangeChecked} name="checked" />}
                    label={termos}
                  />                    
                  <div className="cadastro-erro-check">
                  {errors.check ? '' : <Alert variant="outlined" severity="error">
                                          Aceite os termos para continuar!
                                      </Alert>
                  }
                  
                </div>             
                </div>
                
                <div className="cadastrar-botao"> 
                <Button type="submit" variant="contained" color="secondary" className={classes.btnCadastrar}>
                {resultado ? 'cadastre-se' : <CircularLoad/>}
                </Button>
                <Modal open={modalOpen} user={dados}/>  
                </div>
                </form>  
                <div className="login-footer">
                  <a className="footer-link" target="_blank" href={Termos}>Termos</a>
                  <a className="footer-link" target="_blank" href={Politica}>Política de Privacidade</a>
                  <p>PAYHOW©2020</p>
                </div>
            </div>
            </div>
        
  );
}