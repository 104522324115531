import React from "react";
import { Switch, Route } from "react-router-dom";
import GridDashboard from "./Components/GridDashboard";
import Links from "./Pages/Personalizados";
import Novolink from "./Pages/NovoLink";
import GridHome from "./Components/gridHome";
import GridMensagem from "./Components/gridMensagem";
import GridNotificacoes from "./Components/gridNotificacoes";
import GridConfiguracoes from "./Components/gridConfiguracoes";
import GridApp from "./Pages/App";
import AppForm from "./Pages/App/Components/Form";
import AppFrete from "./Pages/Configuracoes/Frete/Components/index";
import AppFormCard from "./Pages/App/Components/Form/Forms_Apps/Cartao/Verifica";
import AppFormBoleto from "./Pages/App/Components/Form/Forms_Apps/Boleto/Verifica";
import AppFormPix from "./Pages/App/Components/Form/Forms_Apps/Pix/Verifica";
import Perfil from "./Pages/Perfil";
import Pedidos from "./Pages/Pedidos";
import VerPedido from "./Pages/Pedidos/components/InformacoesPedido";
import HistoricoVendas from "./Pages/HistoricoVendasLink";
import VerHistoricoVendas from "./Pages/HistoricoVendasLink/components/InformacoesVendas";
import LojasAfiliadas from "./Pages/LojasAfiliadas";
import NovaLoja from "./Pages/LojasAfiliadas/components/NovaLoja";
import ConfiguracoesFrete from "./Pages/Configuracoes/Frete/index";
import ContratarPlano from "./Pages/ContratarPlano";
import Planos from "./Pages/Perfil/components/Planos";
import TableLinks from "./Pages/TableLinks";
import CreateLink from "./Pages/TableLinks/CreateLink";
import EditLink from "./Pages/TableLinks/EditLink";

export default function Routes() {
  return (
    <Switch>
      <Route path="/dashboard" component={GridDashboard} />
      <Route exact path="/links-personalizados" component={Links}></Route>
      <Route path="/links-personalizados" component={Links}>
        <Route path="/links-personalizados/novo" component={Novolink} />
        <Route path="/links-personalizados/:editar/:id" component={Novolink} />
      </Route>
      <Route path="/home" component={GridHome} />
      <Route path="/mensagens" component={GridMensagem} />
      <Route path="/dashboard" component={GridDashboard} />
      <Route path="/links-personalizados" component={Links} />
      <Route path="/notificacoes" component={GridNotificacoes} />
      <Route path="/configuracoes" component={GridConfiguracoes} />
      <Route path="/apps" component={GridApp} />
      <Route path="/form-apps" component={AppForm} />
      <Route path="/form-apps-card" component={AppFormCard} />
      <Route path="/form-apps-boleto" component={AppFormBoleto} />
      <Route path="/form-apps-pix" component={AppFormPix} />
      <Route path="/perfil" component={Perfil} />
      <Route path="/planos" component={Planos} />
      <Route exact path="/pedidos" component={Pedidos}></Route>
      <Route path="/links" component={TableLinks}>
      </Route>
      <Route path="/cadastrar-link" component={CreateLink} />
      <Route path="/editar-link" component={EditLink} />

      <Route path="/pedidos" component={Pedidos}>
        <Route path="/pedidos/:id" component={VerPedido} />
      </Route>
      <Route exact path="/historico-vendas" component={HistoricoVendas}></Route>
      <Route path="/historico-vendas" component={HistoricoVendas}>
        <Route path="/historico-vendas/:id" component={VerHistoricoVendas} />
      </Route>
      <Route exact path="/lojas" component={LojasAfiliadas} />
      <Route path="/lojas" component={LojasAfiliadas}>
        <Route path="/lojas/nova" component={NovaLoja} />
        <Route path="/lojas/:editar/:id" component={NovaLoja} />
      </Route>
      <Route path="/configuracoes-frete" component={ConfiguracoesFrete} />
      <Route path="/app-frete" component={AppFrete} />
      <Route component={GridHome} />
      <Route path="/contratar-plano" component={ContratarPlano} />
      <Route path="/*/reload" component={null} />
    </Switch>
  );
}
