import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../../Services/api';
import '../PicPay/css/PicPay.css';
import Loading from '../../../../../../Components/Load';
import ModalIntNaoDisp from '../ModalIntNaoDisp';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    }
  }));

export default function FormMercadoPago(params) {
    const history = useHistory();
    const classes = useStyles();
    const [id, setId] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [name, setName] = React.useState('');
    const [statusApp, setStatusApp] = React.useState('');
    const [enableApp, setEnableApp] = React.useState('');
    const [checked, setChecked] = React.useState('');
    const [consumerKey, setConsumerKey] = React.useState('');
    const [environment, setEnvironment] = React.useState('');
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [verificaVazioToken, setVerificaVazioToken] = React.useState(false);
    const [verificaVazioSaller, setVerificaVazioSaller] = React.useState(false);
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [modal, setModal] = React.useState(false);
    const [messageModal, setMessageModal] = React.useState('');
    const [typeAmbient, setTypeAmbient] = React.useState('');
    const [Load, setLoad] = React.useState(false);

    const capturaConsumerKey = (prop) => (event) => {
        setConsumerKey(event.target.value );
    };


   function checkEnable(){
    if(checked === true) {
        setChecked(false);
        setEnableApp(0);
    }
    else{
        setChecked(true);
        setEnableApp(1); 
    }
   }

   const openModal = () => {
    setModal(true)
}
const changeModal = (modal) => {
    setModal(modal)
}


  function cancelarApp(){
    history.push('/apps');
  }

async function buscaApp(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/company/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
     
        for(let i = 0; dados.length > i; i++){
            if(dados[i].name === 'Facebook Pixel'){
                setId(dados[i].id);
                setPhoto(dados[i].photo);
                setName(dados[i].name);
                setStatusApp(dados[i].authenticated);
                setEnableApp(dados[i].enable);
                setChecked(dados[i].enable);
                if(!dados[i].environment){
                    setEnvironment('production');
                }else{
                    setEnvironment(dados[i].environment);
                }
                buscaDadosApp(dados[i].id);
            }
              
        }
    })
    .catch((error) => {

    })
}
 
  useEffect(() => {
    buscaApp();

  },[]) 

    async function enviaFormApp(event){
        setLoad(true);
    event.preventDefault();
        var data = {
            "company_id": idCompanies,
            "enable": checked,
            "environment" : "production",
            "pixel_id": consumerKey,
	        "integration_slug" : "facebook-pixel",		
            "authenticated": statusApp,
        }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
          if(consumerKey !==''){
        await api.post('/v1/integrations/marketing', data, {
            headers: headers
          })
          .then((response) => {
            setLoad(false);
            setMessageErro('');
            setMessageSuccess('Informações salvas com sucesso');
            setTimeout(() => {
                setMessageSuccess('');
          }, 1500);
            setTimeout(() => {
            history.push('/apps');
        }, 2000);
          })
          .catch((error) => {
            openModal();
            setMessageModal(error.response.data.errors.message);
            setLoad(false);
          })
        }else{
            setLoad(false);
            if(consumerKey ===''){
                setVerificaVazioSaller(true);
            }
            return true;
        }
  }

  async function buscaDadosApp(){
 
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
        await api.get('/v1/integrations/marketing/show/' + idCompanies,  {
            headers: headers
          })
          .then((response) => {
            if(response !== ''){
                var dadosInputs = response.data;
                for (var i = 0; i < dadosInputs.length; i++) {
                    setConsumerKey(dadosInputs[i].pixel_id);
                }
            }else{
                    setConsumerKey('');
            }
          })
          .catch((error) => {
              setMessageErro(error.response.data.errors.message);
              setTimeout(() => {
                setMessageErro('');
          }, 1500);
          })
  }


function appPayhow(){
    params.atualizaId(38)
}

return (
    <div className="Apps-form-style">
        <div className="App-suporte">
            <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft}/></a>
        </div>
    <div className="App-form-style">
    <form onSubmit={enviaFormApp} className="PicPay-form" noValidate autoComplete="off">
        <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
            <div className={checked ? "App-circle-status App-circle-status-true": "App-circle-status"}></div>
                <img className="PicPay-logo" src={photo}/>
                    <h1 className="PicPay-title">{name}</h1>
            </div>
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    onClick={checkEnable}
                    value={enableApp}
                    checked={checked}
                    control={<Switch color="secondary" />}
                    label="Ativo:"
                    labelPlacement="start"
                />
            </FormGroup>
        </div>
        <div className="esonde-pagamento">
        <div id="AlertStatus" className="AlertStatus">
            { statusApp ?
             <Alert variant="filled" severity="success">
                Status: Online, conectado com a empresa de integração
            </Alert>
                :
            <Alert variant="filled" severity="error">
                Status: Offline, desconectado.
                Talvez alguma informação esteja incorreta, verifique os campos preenchidos
            </Alert>
            }
        </div>
        <div className="PicPay-link-tutoriais">
        <p className="PicPay-p-form">Informações da aplicação:</p>
            <a target="_blank" href="https://blog.payhow.com.br/2021/04/05/como-criar-e-instalar-um-pixel-do-facebook/">Como obter as informações do Facebook Pixel?</a>
        </div>
        {messageErro !== ''?
                    <Alert  className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                     {messageSuccess!== ''?
                     <Alert  className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                     :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                    {/* <div className="PicPay-envolve-radio-teste-prod">
                        <h4 className="mt-1">1. Escolha o ambiente que sua aplicação irá utilizar:</h4>
                    <RadioGroup  aria-label="position" name="position" defaultValue="top">
                    {environment_sandbox === 1?
                        <FormControlLabel
                            onClick={()=>clickRadio('dev')}
                            name="environment"
                            checked={environment === 'development' ? true : false}
                            value="teste" control={<Radio color="secondary" />} 
                            label="Teste"
                        />
                        :''}
                        <FormControlLabel 
                            onClick={()=>clickRadio('prod')}
                            name="environment"
                            checked={environment === 'production' ? true : false} 
                            value="producao" 
                            control={<Radio color="secondary" />} 
                            label="Produção" 
                        />
                    </RadioGroup>
                    </div> */}
                    <h4 className="mt-2">1. Preencha os campos com os dados correspondentes:</h4>
                    <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="consumerKey" 
                        label={`ID do Pixel`} 
                        type="text" 
                        value={consumerKey} 
                        onChange={capturaConsumerKey()} 
                        variant="outlined" 
                        required
                        error={verificaVazioSaller && consumerKey ==='' ? true:false}
                        helperText={verificaVazioSaller && consumerKey ==='' ?"*Campo obrigatório":''}
                        />
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button variant="contained" onClick={cancelarApp}>Cancelar</Button>
                {!Load ?
                <Button type="submit" variant="contained" color="secondary">Salvar informações</Button>
                :
                <Button type="submit" variant="contained" color="secondary"><Loading/></Button>
                }
          </div>
            </div>
        </form>
    </div>
    <ModalIntNaoDisp open={modal} onChange={changeModal.bind(modal)} message={messageModal}/>
    </div>
);
}