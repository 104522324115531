import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../../Services/api';
import ModalIntNaoDisp from '../ModalIntNaoDisp';
import Loading from '../../../../../../Components/Load';
import '../PicPay/css/PicPay.css';
import {useHistory} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    }
  }));

export default function FormAme(params) {
    const history = useHistory();
    const classes = useStyles();
    const [id, setId] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [name, setName] = React.useState('');
    const [statusApp, setStatusApp] = React.useState('');
    const [enableApp, setEnableApp] = React.useState('');
    const [checked, setChecked] = React.useState('');
    const [porcCashback, setPorcCashback] = React.useState('');
    const [applicationKey, setApplicationKey] = React.useState('');
    const [applicationToken, setApplicationToken] = React.useState('');
    const [environment, setEnvironment] = React.useState('');
    const [environment_sandbox, setEnvironmentSandbox] = React.useState('');
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [verificaVazioToken, setVerificaVazioToken] = React.useState(false);
    const [verificaVazioSaller, setVerificaVazioSaller] = React.useState(false);
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [typeAmbient, setTypeAmbient] = React.useState('');
    const [Load, setLoad] = React.useState(false);
    const [payhowPagamentos, setPayhowPagamentos] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [messageModal, setMessageModal] = React.useState('');

    const capturaPorcCashback = (prop) => (event) => {
        setPorcCashback(event.target.value );
    };
   const maxLengthCheck = (prop) =>  (object) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
        }
    const capturaApplicationToken = (prop) => (event) => {
        setApplicationToken(event.target.value );
    };
    const capturaApplicationKey = (prop) => (event) => {
        setApplicationKey(event.target.value );
    };

    const openModal = () => {
        setModal(true)
    }
    const changeModal = (modal) => {
        setModal(modal)
    }

   function checkEnable(){
    if(checked === true) {
        setChecked(false);
        setEnableApp(0);
    }
    else{
        setChecked(true);
        setEnableApp(1); 
    }
   }

   function clickRadio(radio){
    if(radio ==='dev'){
        setEnvironment('development');
        setTypeAmbient('(Teste)');
    }else{
        setEnvironment('production');
        setTypeAmbient('(Produção)');
    }
   }
  function cancelarApp(){
    history.push('/apps');
  }
  async function buscaApp(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/company/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
        for(let i = 0; dados.length > i; i++){
            if(dados[i].name === 'Ame'){
                setId(dados[i].id);
                setPhoto(dados[i].photo);
                setName(dados[i].name);
                setStatusApp(dados[i].authenticated);
                setEnableApp(dados[i].enable);
                setChecked(dados[i].enable);
                if(!dados[i].environment){
                    setEnvironment('production');
                }else{
                    setEnvironment(dados[i].environment);
                }
                setEnvironmentSandbox(dados[i].environment_sandbox);
                buscaDadosApp(dados[i].id);
            }
              
        }
    })
    .catch((error) => {
    })
}
 
  useEffect(() => {
    buscaApp();
    buscaPayhowPagamentos();
  },[]) 

    async function enviaFormApp(event){
        setLoad(true);
    event.preventDefault();
        var data = {
            "ativo": id,
            "company_id": idCompanies,
            "enable": checked,
            "environment": environment,
            "integration_id": id,
            "sandbox": id,
            "credentials" : {
                "cashback-percentage": porcCashback,
                "client_id": applicationKey,
                "access_token": applicationToken,
            }
        }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
          if(applicationKey !=='' && applicationToken !==''){
        await api.post('/v1/integrations/v2', data, {
            headers: headers
          })
          .then((response) => {
            setLoad(false);
            setMessageErro('');
            setMessageSuccess('Informações salvas com sucesso');
            setTimeout(() => {
                setMessageSuccess('');
          }, 1500);
            setTimeout(() => {
            history.push('/apps');
        }, 2000);
          })
          .catch((error) => {
            openModal();
            setMessageModal(error.response.data.errors.message);
            setLoad(false);
          })
        }else{
            setLoad(false);
            if(applicationToken ==='' ){
                setVerificaVazioToken(true);
            } 
            if(applicationKey ===''){
                setVerificaVazioSaller(true);
            }
            return true;
        }
  }

  async function buscaDadosApp(idApp){
      if(environment==='development'){
        setTypeAmbient('(Teste)');
      }else{
        setTypeAmbient('(Produção)');
      }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
        await api.get('/v1/integrations/' + idApp + "/fields/" + idCompanies,  {
            headers: headers
          })
          .then((response) => {
            if(response.data.status !== null){
                setApplicationToken(response.data.access_token);
                setApplicationKey(response.data.client_id);
                setPorcCashback(response.data['cashback-percentage']);     
           }
          })
  }


  async function buscaPayhowPagamentos(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/mode/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
        if(dados.integration_mode==='auto'){
            setPayhowPagamentos(true);
        }else{
            setPayhowPagamentos(false);
        }
    })
    .catch((error) => {

    })
}
function appPayhow(){
    params.atualizaId(38)
}

return (
    <div className="Apps-form-style">
        <div className="App-suporte">
            <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft}/></a>
        </div>
    <div className="App-form-style">
    <form onSubmit={enviaFormApp} className="PicPay-form" noValidate autoComplete="off">
        <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
            <div className={checked ? "App-circle-status App-circle-status-true": "App-circle-status"}></div>
                <img className="PicPay-logo" src={photo}/>
                    <h1 className="PicPay-title">{name}</h1>
            </div>
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    onClick={checkEnable}
                    value={enableApp}
                    checked={checked}
                    control={<Switch color="secondary" />}
                    label="Ativo:"
                    labelPlacement="start"
                />
            </FormGroup>
        </div>
        {!payhowPagamentos ? 
        <div className="esonde-pagamento">
        <div id="AlertStatus" className="AlertStatus">
            { statusApp ?
             <Alert variant="filled" severity="success">
                Status: Online, conectado com a empresa de integração
            </Alert>
                :
            <Alert variant="filled" severity="error">
                Status: Offline, desconectado.
                Talvez alguma informação esteja incorreta, verifique os campos preenchidos
            </Alert>
            }
        </div>
        <div className="PicPay-link-tutoriais">
        <p className="PicPay-p-form">Informações da aplicação:</p>
            {/* <a target="_blank" href="https://app.payhow.lionsoft.com.br/tutoriais#ame">Como obter as informações do AME Digital?</a> */}
        </div>
        {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                     {messageSuccess!== ''?
                     <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                     :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                    <div className="PicPay-envolve-radio-teste-prod">
                        <h4 className="mt-1">1. Escolha o ambiente que sua aplicação irá utilizar:</h4>
                    <RadioGroup  aria-label="position" name="position" defaultValue="top">
                    {environment_sandbox === 1?
                        <FormControlLabel
                            onClick={()=>clickRadio('dev')}
                            name="environment"
                            checked={environment === 'development' ? true : false}
                            value="teste" control={<Radio color="secondary" />} 
                            label="Teste"
                        />
                        :''}
                        <FormControlLabel 
                            onClick={()=>clickRadio('prod')}
                            name="environment"
                            checked={environment === 'production' ? true : false} 
                            value="producao" 
                            control={<Radio color="secondary" />} 
                            label="Produção" 
                        />
                    </RadioGroup>
                    </div>
                    <h4 className="mt-2">2. Preencha os campos com os dados correspondentes:</h4>
                    <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="porcCachback"
                        label={`% do cashback ${typeAmbient}`}
                        type="number"
                        maxLength = "2"
                        inputProps = {{maxLength:2}}
                        value={porcCashback}  
                        onChange={capturaPorcCashback()}
                        onInput={maxLengthCheck()}
                        variant="outlined" 
                         />
                    <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="applicationKey" 
                        label={`Client ID ${typeAmbient}`} 
                        type="text" 
                        value={applicationKey} 
                        onChange={capturaApplicationKey()} 
                        variant="outlined" 
                        required
                        error={verificaVazioSaller && applicationKey ==='' ? true:false}
                        helperText={verificaVazioSaller && applicationKey ==='' ?"*Campo obrigatório":''}
                        />
                    <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="applicationToken" 
                        label={`Access Token ${typeAmbient}`} 
                        type="text" 
                        value={applicationToken} 
                        onChange={capturaApplicationToken()} 
                        variant="outlined" 
                        required
                        error={verificaVazioToken && applicationToken ==='' ? true:false}
                        helperText={verificaVazioToken && applicationToken ==='' ?"*Campo obrigatório":''}
                    />
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button variant="contained" onClick={cancelarApp}>Cancelar</Button>
                {!Load ?
                <Button type="submit" variant="contained" color="secondary">Salvar informações</Button>
                :
                <Button type="submit" variant="contained" color="secondary"><Loading/></Button>
                }
            </div>
            </div>
               :
               <div className="app_payhow-ativo">
                   <p>Para visualizar as informações de integração deste App será necessário desativar o App PAYHOW Pagamentos</p>
                   <Button type="button" onClick={appPayhow} variant="contained" color="secondary">Desativar o App PAYHOW Pagamentos</Button>
               </div>
               }
        </form>
    </div>
    <ModalIntNaoDisp open={modal} onChange={changeModal.bind(modal)} message={messageModal}/>
    </div>
);
}