import React from 'react';
import './css/index.css'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from './components/CardLoja';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    paddingTop: 100,
    backgroundColor:'#F3F3F3',
    minHeight: '100vh',
    height: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento:{
      padding: 20,
  },
  container:{
    margin: 15,
  },
  icon:{
    marginRight: ".5rem"
  },
  btnIcon:{
    height: "50px",
    marginLeft: "1rem"
  },
  cards:{
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "flex-start"

  }
}));

export default function AutoGrid(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={12}>
        
        <div className="cabecalho-personalizado">
            <div>
              <h1>Minhas lojas</h1>
              <h3>Cadastre aqui as lojas virtuais que utilizaram nosso checkout</h3>
            </div>
            <div>
            <Button  className={classes.btnIcon} variant="contained" color="secondary" onClick={() => {props.history.push('/lojas/nova')}}>
                <AddIcon className={classes.icon}/>  Nova loja
            </Button>
            </div>
        </div>  
        <div className={classes.cards}>
          <Card/>
        </div>
        </Grid>
      </Grid>
      </div>
   
    </div>
  );
}