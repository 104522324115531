import React, {useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useHistory} from 'react-router-dom';

export default function ModalLogout(props) {
  const history = useHistory();  
  const [openLogout, setOpenLogout] = useState(false);

  useEffect(() => {
    setOpenLogout(props.open);
},[props.open])

  const handleCloseML = () => {
    setOpenLogout(false);
    window.location.reload();
  };
function finalLogout(){
    localStorage.clear();
    history.push('/?load');
}
  return (
    <div>
    <div className="modal-logout">
      <Dialog open={openLogout}  aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Sair do PAYHOW?</DialogTitle>
        <DialogActions>
          <Button type="button" onClick={handleCloseML}>
            Cancelar
          </Button>
          <Button type="button" color="secondary" onClick={finalLogout} className="btnModalEsqueciSenha">
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </div>
  );
}
 