
    import React, { useState } from 'react'
    import { Button } from '@material-ui/core'
    import { makeStyles } from '@material-ui/core/styles';
    import {FaPen} from 'react-icons/fa'
    
    
    
    export default function Upload({ handleChangeFoto, photo}) {
      const useStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          flexDirection: 'column',
          marginRight: '20px' 
        },
        button: {
          height: '40px',
          width: '40px',
          borderRadius: '50%',
          backgroundColor: '#f50057',
          marginLeft:'90px',
          marginTop:'-45px',
          display: 'flex',
          justifyContent:'center',
          alignItems:'center', 
    
         },
        text:{
          color:'#FFFF',
          fontWeight:'bold',
          fontSize: '15px'    
        },
        bord:{
            border:'1px solid #e0e0e0',
            borderRadius:'50%',
            padding: '7px'  
        },
        imagem:{
            height: '120px', 
            width: '120px', 
            borderRadius: '50%'
        }
    
    
      }));
    
    
      const classes = useStyles()
    
    
      const [imagem, setImagem] = useState(false)
    
      function getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {

          handleChangeFoto(reader.result)
    
        };
        reader.onerror = function (error) {
     
        };
      }
    
    
    
      return (
       
        <div className={classes.root}>
        <div className={classes.bord}>      
        {imagem === false ?
          <img className={classes.imagem} src={photo} />
          :
          <img className={classes.imagem} src={imagem} />
        }
        </div>
          <input id='upload' hidden={true} type='file' onChange={(e) => {
            getBase64(e.target.files[0])
        
    
            setImagem(URL.createObjectURL(e.target.files[0]))
          }} />
    
          <label htmlFor="upload" >
          <div className={classes.button} >
                <div className={classes.text}>
                    <FaPen />
                </div>
          </div>
    
          </label>
    
          {/* {/<img src={imagembanco ? imagemimagembanco : imagem} />/}  */}
    
        
        </div>
      )
    }
