import React,{useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Logo from '../../../../../../Assets/images/logo.fw.svg';
import {useHistory} from 'react-router-dom';
import '../../../../css/App.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalApp(infoModal) {
    const History = useHistory();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState(infoModal.message);

  const handleClose = () => {
    setOpen(false);
  };

  function mudarPlano(){
    History.push('/planos'); 
    setOpen(false);
  }

  useEffect(() => {
    setOpen(infoModal.open);
    setMessage(infoModal.message)
   }, [infoModal]);

  return (
    <div>
      <Dialog
      className="modal-app"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"><img src={Logo}/></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose} >
            Fechar
          </Button>
          <Button onClick={mudarPlano}  variant="contained" color="secondary">
            Alterar plano
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}