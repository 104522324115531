import React, {useEffect} from 'react';
import { makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Logo from '../../Assets/images/payhow_logo.fw.png';
import './css/index.css';
import Fromulario from './components/Formulario';
import {useHistory} from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    height: '100vh'
  },
  container:{
    overflow: 'hidden'
  }
  
}));


export default function Cadastro() {
  const classes = useStyles();
  const history = useHistory();
 

  return (
    <div className="tela-cadastro">
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <div className="background-cadastro">
          <div className="login-image_logo">
            <img src={Logo} alt="Logo PAYHOW" text="Logo PAYHOW"/>
          </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className="cadastro-container-form">
        <div className="login-image_logo_mobile">
                        <img src={Logo} alt="Logo PAYHOW" text="Logo PAYHOW"/>
                      </div>
          <div >
          <Fromulario/>
          </div>
        </Grid>
       
      </Grid>
    </div>
    </div>
  );
}