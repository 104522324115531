import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../Services/api';
import Cor from './ClassCores/cor';
import CorSecundaria from './ClassCores/corSecundaria';
import CorBtnAcao from './ClassCores/corBtnAcao';
import CorTextDestaque from './ClassCores/corTextDestaque';
import {useParams, useHistory} from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Upload from './upload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Logo from '../../../../../Assets/images/logo.fw.svg';
import '../../../css/Loja.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    },
    formControl: {
      width: '100%',
      marginLeft:0,
      marginRight:0,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));

export default function FormPersonalizar(props) {
    const classes = useStyles();
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [foto, setFoto] = React.useState('');
    const [photo, setPhoto] = React.useState('')
    const params = useParams();
    var color='';
    const [cor, setColor] = React.useState('');
    const [corEnvia, setColorEnvia] = React.useState('');
    const [corSecundaria, setColorSecundaria] = React.useState('');
    const [corSecundariaEnvia, setColorSecundariaEnvia] = React.useState('');
    const [corBtnAcao, setColorBtnAcao] = React.useState('');
    const [corBtnAcaoEnvia, setColorBtnAcaoEnvia] = React.useState('');
    const [corTextDestaque, setColorTextDestaque] = React.useState('');
    const [corTextDestaqueEnvia, setColorTextDestaqueEnvia] = React.useState('');
    const [arrayPersonalizar, setArrayPersonalizar] = React.useState(props.arrayDados);
    const [idLoja, setIdLoja] = React.useState(props.idLoja);
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const History = useHistory();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [state, setState] = React.useState({
      checkedA: true,
    });

 
      const handleChangeFoto = (foto) => {
       
        setFoto(foto)
    
      }

      const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        showLogo(event.target.checked)
      };

     const convertHexToRGBA = (hexCode) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }    
    
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return color={
            r: `${r}`,
            g: `${g}`,
            b: `${b}`,
            a: '100',
          };

    };

    const corPrimaria = (value) => {
        setColor(value); 
        setColorEnvia(value);
      };

      const corSecundariaF = (value) => {
        setColorSecundaria(value); 
        setColorSecundariaEnvia(value);
      };

      const corBtnAcaoF = (value) => {
        setColorBtnAcao(value); 
        setColorBtnAcaoEnvia(value);
      };

      const corTextDestaqueF = (value) => {
        setColorTextDestaque(value); 
        setColorTextDestaqueEnvia(value);
      };

      function showLogo(value){
        const headers = { 
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer' + token,
        }
        var dados = {
          'show_logo' : value
        }
      api.put(`/v1/customers/company/logo/${idLoja}`,dados, {
          headers: headers
        })
        .then((response) => {
          if(!response.data.status){
            setOpen(true)
            setMessage(response.data.message)
          }
        })
      }

      function mudarPlano(){
        History.push('/planos'); 
        setOpen(false);
      }
      const handleClose = () => {
        setOpen(false);
      };
    

      async function dadosLojasAfiliadas(){
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
        await api.get(`/v1/customers/company`,  {
            headers: headers
          })
          .then((response) => {
            for(let i = 0; response.data.length > i; i++){
                if(idLoja === response.data[i].id){
                  setState({
                    checkedA: response.data[i].checkout_data.show_logo,
                  });
                  var corPrimaria = response.data[i].checkout_data.main_color;
                  var corSecundaria = response.data[i].checkout_data.secondary_color;
                  var btnAcao = response.data[i].checkout_data.action_button_color;
                  var textDestaque = response.data[i].checkout_data.featured_text_color;
        
                  if(corPrimaria === null || corPrimaria === ''){
                      setColor(convertHexToRGBA('#F50057'));
                      setColorEnvia('#F50057');
                  }else{
                      setColor(convertHexToRGBA(corPrimaria));
                      setColorEnvia(corPrimaria); 
                  }
                  if(corSecundaria === null || corSecundaria ===''){
                      setColorSecundaria(convertHexToRGBA('#F50057'));
                      setColorSecundariaEnvia('#F50057');
                  }else{
                      setColorSecundaria(convertHexToRGBA(corSecundaria));
                      setColorSecundariaEnvia(corSecundaria);
                  }
                  if(btnAcao === null || btnAcao ===''){
                      setColorBtnAcao(convertHexToRGBA('#F50057'));
                      setColorBtnAcaoEnvia('#F50057');
                  }else{
                      setColorBtnAcao(convertHexToRGBA(btnAcao));
                      setColorBtnAcaoEnvia(btnAcao);
                  }
                  if(textDestaque === null || textDestaque ===''){
                      setColorTextDestaque(convertHexToRGBA('#F50057'));
                      setColorTextDestaqueEnvia('#F50057');
                  }else{
                      setColorTextDestaque(convertHexToRGBA(textDestaque));
                      setColorTextDestaqueEnvia(textDestaque);
                  }
                  var endereco = response.data[i].address.length;
                  if(props.arrayDados ==='' && endereco !== 0){
                    var data ={
                      "name" :  response.data[i].name,
                      "cnpj" : response.data[i].cnpj,
                      "phone" : response.data[i].phone,
                      "street" : response.data[i].address['0'].street,
                      "number" : response.data[i].address['0'].number,
                      "city" : response.data[i].address['0'].city,
                      "postal_code" : response.data[i].address['0'].zip_code,
                      "neighborhood" : response.data[i].address['0'].neighborhood,
                      "state" : response.data[i].address['0'].state,
                      "complement" : "",
                      "partner_name" :  response.data[i].partner_name,
                      "partner_document" : response.data[i].partner_document,
                      "company_name" : response.data[i].company_name
                    }
                    setArrayPersonalizar(data)
                  }
                }
            }
          })
      }
      useEffect(() => {
        if(idLoja===''){
          setIdLoja(localStorage.getItem('companiesId'));
        }
          dadosLojasAfiliadas();
      }, [idLoja]);


      async function enviaDadosLoja(event){
        event.preventDefault();
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }

          const data = { 
                "photo": foto,
                "checkout_data": {
                "main_color" : corEnvia, 
                "secondary_color" : corSecundariaEnvia,
                "action_button_color" : corBtnAcaoEnvia,
                "featured_text_color" : corTextDestaqueEnvia
            }
          }
          var personalizar = Object.assign({}, data,arrayPersonalizar);
          await api.put(`/v1/customers/company/${idLoja}`, personalizar, {
            headers: headers
        })
        .then((response) => {
            setMessageSuccess('Personalização salva com sucesso!');
            setMessageErro('');
            setTimeout(() => {
              setMessageSuccess('');
              window.location.reload()
        }, 1500);
        })
        .catch((error) => {
            setMessageSuccess('');
            setMessageErro('Ocorreu um erro ao cadastrar a loja, revise os dados e tente novamente.');
            setTimeout(() => {
                setMessageErro('');
          }, 1500);
        })
        }

return (
    <div className="Apps-form-style">
      {cor !== '' && corSecundaria !== '' && corBtnAcao !== '' && corTextDestaque !== ''?
    <div className="App-form-style">
        <div className="Dados-banc-links-title">
        <h2 className="title-abas">Personalizar</h2>
        </div>
    <form onSubmit={enviaDadosLoja} className="FormMeusDados" noValidate autoComplete="off">
    {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                       {messageSuccess !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                    :''}
            <FormControl className={classes.root} component="fieldset">
            <h3 className="mb-0">1. Insira um logo para sua loja:</h3>
            <div className={classes.foto}>
              <Upload photo={photo} handleChangeFoto={handleChangeFoto}/>
            </div>
                <FormGroup aria-label="position" row>
            {/* <h3>2. Preencha os campos abaixo com a URL das páginas desejadas:</h3> */}
                    {/* <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="site"
                        label="URL do site"
                        type="text"
                        value={site}  
                        onChange={capturaSite()}
                        variant="outlined" 
                        InputProps={{
                            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                          }}
                         />
                            <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="site"
                        label="URL de redirecionamento após a compra"
                        type="text"
                        value={siteObrigado}  
                        onChange={capturaSiteObrigado()}
                        variant="outlined" 
                        InputProps={{
                            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                          }}
                         /> */}
                         <div className="envolveCores">
                           <h3>2. Escolha as cores da página de pagamento da sua loja:</h3>
                            <div>
                              <p className="p-color">Cor principal:</p>
                              <div className="gif-cor">
                                <Cor params={cor} atualizaCorPrimaria={corPrimaria}/>
                                 {/* <img src={gif}/> */}
                              </div>
                            </div>
                            <div>
                              <p className="p-color">Cor secundária:</p>
                              <div className="gif-cor">
                                <CorSecundaria params={corSecundaria} atualizaCorSecundaria={corSecundariaF}/>
                                 {/* <img src={gif}/> */}
                              </div>
                            </div>
                            <div>
                              <p className="p-color">Cor dos botões de ação:</p>
                              <div className="gif-cor">
                                <CorBtnAcao params={corBtnAcao} atualizaCorBtnAcao={corBtnAcaoF}/>
                                 {/* <img src={gif}/> */}
                              </div>
                            </div>
                            <div>
                              <p className="p-color">Cor dos textos em destaque:</p>
                              <div className="gif-cor">
                                <CorTextDestaque params={corTextDestaque} atualizaCorTextDestaque={corTextDestaqueF}/>
                                {/* <img src={gif}/> */}
                              </div>
                            </div>
                        </div>
                  
                </FormGroup>
                <div className="envolveCores">
                           <h3>3. Exibir o logo da PAYHOW na tela de Checkout:</h3>
                            <div>
                            <FormControlLabel
                              control={<Switch checked={state.checkedA} onChange={handleChange} name="checkedA" />}
                              label="Sim, exibir."
                            />
                            </div>
                        </div>
            </FormControl>
            <div className="PiPay-button">
                <Button type="submit" variant="contained" color="secondary">Salvar</Button>
            </div>
        </form>
    </div>
    :''}
     <div>
      <Dialog
      className="modal-app"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"><img src={Logo}/></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose} >
            Fechar
          </Button>
          <Button onClick={mudarPlano}  variant="contained" color="secondary">
            Alterar plano
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </div>
);
} 