import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Logo from '../../Assets/images/logo.fw.svg';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import 'react-credit-cards/es/styles-compiled.css';
import Cards from 'react-credit-cards';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import Termos from '../../Assets/pdf/Termos_De_Uso.pdf';
import payImage from '../../Assets/images/pay/cartoes-de-credito.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useHistory} from 'react-router-dom';
import NumberFormat from 'react-number-format';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import api from '../../Services/api';
import './css/contratarPlano.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { mask,unMask } from 'remask'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    backgroundColor: '#ffff'
  },
  paper: {
    padding: theme.spacing(2),
    minHeight: '100vh',
    margin: '.5rem',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
  formInput: {
      margin: theme.spacing(1),
      width: '50ch',
  },
  formInputD: {
    width: '50ch',
    padding: 0
},
  form:{
    width: '75ch'
  },
  formRow:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '8px',
    width: '100%'
  },
  formInputM:{
    width: '25ch',
  },
  formInputMm:{
    width: '25ch',
    marginLeft: '1rem'
  },
  imgPay:{
    width: '35%',
    height: 'auto'
  },
  iconArrow:{
    width: '2rem',
    height: '2rem',
    color: '#f50272',
    marginRight: '.8rem',
    cursor: 'pointer'
  },
  iconCheck:{
    color: '#f50272',
    marginRight: '.8rem',
  },
  divHeader:{
    display: 'flex',
    alignItems: 'center'
  },
  imageLogo:{
    width: '20%',
    height: 'auto'
  },
  divEnvolveForm:{
    marginLeft: '4rem',
    marginRight: '4rem',
    marginTop: '2.5rem'
  },
  h1Title:{
    fontWeight: '600',
    fontFamily: 'Roboto',
    fontSize: '2em',
  },
  radioDiv:{
    flexDirection: 'row',
  },
  pValor:{
    margin: '0'
  },
  h2SubTitle:{
    borderTop: '1px solid #e0e0e0',
    paddingTop: '2rem',
    marginTop: '2rem',
  },
  divEndereco:{
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  formInputN: {
    width: '28% !important',
},
formInputR: {
  width: '69% !important',
},
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#0092d4',
      main: '#0092d4',
      dark: '#0092d4',
      contrastText: '#fff',
    },
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      isNumericString
       format='#####-###'
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


export default function ContratarPlano(params) {
  const History = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState('yearly');
  const [cvc, setCvc] = React.useState('');
  const [expiry, setExpiry] = React.useState('');
  const [focus, setFocus] = React.useState('');
  const [name, setName] = React.useState('NOME DO TÍTULAR');
  const [number, setNumber] = React.useState('');
  const [document, setDocument] = React.useState('');
  const [CEP, setCEP] = React.useState('');
  const [endereco, setEndereco] = React.useState('');
  const [numeroCasa, setNumeroCasa] = React.useState('');
  const [bairro, setBairro] = React.useState('');
  const [cidade, setCidade] = React.useState('');
  const [estado, setEstado] = React.useState('');
  const [telefone, setTelefone] = React.useState('');
  const [tipoCartao, setTipoCartao] = React.useState('');
  const token = localStorage.getItem('tokenUser')
  const [id, setId] = React.useState('');
  const [client, setClient] = React.useState('');
  const [nome, setNome] = React.useState('');
  const [cupom, setCupom] = React.useState('');
  const [valorMes, setvalorMes] = React.useState('');
  const [valorAno, setValorAno] = React.useState('');
  const [valorAnoTotal, setValorAnoTotal] = React.useState('');
  const [novoValorMes, setNovoValorMes] = React.useState('');
  const [novoValorAno, setNovoValorAno] = React.useState('');
  const [novoValorAnoTotal, setNovoValorAnoTotal] = React.useState('');
  const [state, setState] = React.useState({checkedA: true});
  const [verificaVazioNumber, setVerificaVazioNumber] = React.useState(false);
  const [verificaVazioDocument, setVerificaVazioDocument] = React.useState(false);
  const [verificaVazioCEP, setVerificaVazioCEP] = React.useState(false);
  const [verificaVazioEndereco, setVerificaVazioEndereco] = React.useState(false);
  const [verificaVazioCidade, setVerificaVazioCidade] = React.useState(false);
  const [verificaVazioBairro, setVerificaVazioBairro] = React.useState(false);
  const [verificaVazioNumeroCasa, setVerificaVazioNumeroCasa] = React.useState(false);
  const [verificaVazioEstado, setVerificaVazioEstado] = React.useState(false);
  const [verificaVazioTelefone, setVerificaVazioTelefone] = React.useState(false);
  const [verificaVazioName, setVerificaVazioName] = React.useState(false);
  const [verificaVazioExpiry, setVerificaVazioExpiry] = React.useState(false);
  const [cepfsm, setCepSm] = React.useState('');
  const [telefoneSm, setTelefoneSm] = React.useState('');
  const [documentSm, setDocumentSm] = React.useState('');
  const [verificaVazioCvc, setVerificaVazioCvc] = React.useState(false);
  const [bandeiraCard, setBandeiraCard] = React.useState('');
  const [messageErro, setMessageErro] = React.useState('');
  const [validaCPF, setValidaCPF] = React.useState(true);
  const [messageSuccess, setMessageSuccess] = React.useState('');


  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  const handleInputFocus = (event) => {
    setFocus(event.target.name );
  }

  const handleInputCupom = (event) => {
    setCupom(event.target.value)
  }
  
  const handleInputName = (event) => {
    setName(event.target.value)
  }
  const handleInputNumber = (event) => {
    setNumber(event.target.value)
  }
  const handleInputValidade = (event) => {
    setExpiry(event.target.value)
  }
  const handleInputCvv = (event) => {
    setCvc(event.target.value)
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setMessageErro('');
  };

  const capturaCep= (prop) => (event) => {
    setCEP(event.target.value );
    let cepSm = event.target.value.replace(/\D/g, '');
    setCepSm(cepSm);
  };

  const capturaTelefone= (prop) => (event) => {
    setTelefone(event.target.value );
    let telefoneSm = event.target.value.replace(/\D/g, '');
    setTelefoneSm(telefoneSm);
  };
  const capturaDocument= (prop) => (event) => {
    setDocument(event.target.value );
    let documentSm = event.target.value.replace(/\D/g, '');
    setDocumentSm(documentSm);
  };
  const capturaCidade= (prop) => (event) => {
    setCidade(event.target.value );
  };
  const capturaEndereco= (prop) => (event) => {
    setEndereco(event.target.value );
  };
  const capturaNumeroCasa= (prop) => (event) => {
    setNumeroCasa(event.target.value );
  };
  const capturaBairro= (prop) => (event) => {
    setBairro(event.target.value );
  };
  const capturaEstado= (prop) => (event) => {
    setEstado(event.target.value );
  };

  function voltar(){
    History.push('/planos'); 
  }

  const mascaraNumber = (prop) =>  (object) => {
    var number = object.target;
    if(number.value.length == 4)
        number.value = number.value + ' '; 
    if(number.value.length == 9)
        number.value = number.value + ' ';
    if(number.value.length == 14)
        number.value = number.value + ' ';
}
  const mascara = (prop) =>  (object) => {
    var validade = object.target; 
    if(validade.value.length == 2)
        validade.value = validade.value + '/'; 
  }

  useEffect(() => {
    var urlId = params.location.search.split('=')[1];
    var urlClient = params.location.search.split('=')[2];

    if(urlClient == undefined || urlClient == '' || urlClient == null){
      setClient('');
    }else{
      setClient(urlClient);
    }

    setId(urlId);
    getDadosPlano();
    interessePlano();
  }, [id]);

  useEffect(() => {
    verificaBandeira();
  },[number]);

async function validaCupom() {
  const data = {
      "plan_id": id,
      "cupom": cupom
  }
  
  const headers = { 
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer' + token,
  }
  await api.post('/v1/plans/coupons/verify', data, {
    headers: headers
  })
  .then((response) => {
    if(response.data.error){
      setMessageErro(response.data.error)
      setCupom(null)
      setTimeout(() => {
        setMessageErro('');
      }, 2000);
    }else{
      setNovoValorMes(response.data[0].price_monthly_discount_mask);
      setNovoValorAno(response.data[1].price_yearly_discount_mask);
      setNovoValorAnoTotal(response.data[1].yearly_total_discount_mask);
    }
  })
}

  async function getDadosPlano(){
    const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
      await api.get(`/v1/plans/${id}`, {
          headers: headers
      })
      .then((response) => {
        if(response.data.id===2){
          setNome('Basic');
        } else if(response.data.id===3){
          setNome('Plus');
        }
        else if(response.data.id===4){
          setNome('Pro');
        }
        else if(response.data.id===5){
          setNome('Freelancers');
        }
        else if(response.data.id===6){
          setNome('Agências');
        }
        setvalorMes(response.data.price_monthly_mask);
        setValorAnoTotal(response.data.total_yearly_mask);
        setValorAno(response.data.price_yearly_mask);


// price_monthly: 3990
// price_yearly: 2990
// total_yearly: 35880
      }) 
  }

  
  function validaCpfCnpj(val) {// validar cpf ou cpnj verdadeiro
    var valor = val.replace(/\D/g, "")
    if (valor.length == 11) {
        var cpf = valor.trim();


        cpf = cpf.replace(/\D/g, "")



        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cpf.length > i; i++) {
            if (cpf[i - 1] != cpf[i]) {
                aux = true;
            }
        }

        if (aux == false) {
            return false;
        }

        for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
            v1 += cpf[i] * p;
        }

        v1 = ((v1 * 10) % 11);

        if (v1 == 10) {
            v1 = 0;
        }

        if (v1 != cpf[9]) {
            return false;
        }

        for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
            v2 += cpf[i] * p;
        }

        v2 = ((v2 * 10) % 11);

        if (v2 == 10) {
            v2 = 0;
        }
  
        if (v2 == cpf[10]) {
            return true;
        } else {
            return false;
        }
    } else if (valor.length == 14) {
        var cnpj = valor.trim();

        cnpj = cnpj.replace(/\./g, '');
        cnpj = cnpj.replace('-', '');
        cnpj = cnpj.replace('/', '');
        cnpj = cnpj.split('');

        var v1 = 0;
        var v2 = 0;
        var aux = false;

        for (var i = 1; cnpj.length > i; i++) {
            if (cnpj[i - 1] != cnpj[i]) {
                aux = true;
            }
        }

        if (aux == false) {
            return false;
        }

        for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
            if (p1 >= 2) {
                v1 += cnpj[i] * p1;
            } else {
                v1 += cnpj[i] * p2;
            }
        }

        v1 = (v1 % 11);

        if (v1 < 2) {
            v1 = 0;
        } else {
            v1 = (11 - v1);
        }

        if (v1 != cnpj[12]) {
            return false;
        }

        for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
            if (p1 >= 2) {
                v2 += cnpj[i] * p1;
            } else {
                v2 += cnpj[i] * p2;
            }
        }

        v2 = (v2 % 11);

        if (v2 < 2) {
            v2 = 0;
        } else {
            v2 = (11 - v2);
        }

        if (v2 != cnpj[13]) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

  async function enviaDadosPag(){
    if(state.checkedA){
      var card_number = number.replace(/[^\d]+/g, '');
      var mes_expira = expiry.split("/")[0];
      var ano_expira = expiry.split("/")[1];
      var data = '';
      var cupon = null;
      if(cupon !==''){
        cupon = cupom
      }
      if(client === ''){

      data = {
        "plan_id": id,
        "period": value,
        "card_brand": bandeiraCard,
        "card_cvv": cvc,
        "card_year_expiration": ano_expira,
        "card_month_expiration": mes_expira,
        "card_number": card_number,
        "card_holder_name": name,
        "cupom": cupon,
        "document" : documentSm,
        "street" : endereco,
        "street_number" : numeroCasa,
        "neighborhood" : bairro,
        "zipcode" : cepfsm,
        "phone" : telefoneSm
      }

      }else{

        data = {
          "token": client,
          "period": value,
          "card_brand": bandeiraCard,
          "card_cvv": cvc,
          "card_year_expiration": ano_expira,
          "card_month_expiration": mes_expira,
          "card_number": card_number,
          "card_holder_name": name,
          "cupom": cupon,
          "document" : documentSm,
          "street" : endereco,
          "street_number" : numeroCasa,
          "neighborhood" : bairro,
          "zipcode" : cepfsm,
          "phone" : telefoneSm
        } 

      }
      const headers = { 
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer' + token,
        }

        if(number !=='' &&  name !=='' && expiry !=='' && cvc !=='' && telefone !== '' 
          && document !== '' && endereco !== '' && CEP !== '' && cidade !== '' && bairro !== '' && estado !== '' && numeroCasa !== '' && validaCPF){
            await api.post(`${client === '' ? '/v1/plans/payment' : '/v1/plans/renewal'}`, data, {
                headers: headers
              })
          .then((response) => {
            setMessageSuccess('Compra realizada com sucesso! Em breve você será redirecionado para a tela de login...');
            setTimeout(() => {
                setMessageSuccess('');
                History.push('/?load')
          }, 2000);
          })
          .catch((error) => {
            setMessageErro('Ocorreu um erro ao concluir o pagamento, por favor verifique os dados do cartão');
            setTimeout(() => {
                setMessageErro('');
          }, 2000);
          })
        }else{
          if(number  ==='' ){
              setVerificaVazioNumber(true);
          } else{
            setVerificaVazioNumber(false);
          }
          if(telefone  ==='' ){
            setVerificaVazioTelefone(true);
          } else{
            setVerificaVazioTelefone(false);
          }
          if(CEP  ==='' ){
            setVerificaVazioCEP(true);
          } else{
            setVerificaVazioCEP(false);
          }
          if(cidade  ==='' ){
            setVerificaVazioCidade(true);
          } else{
            setVerificaVazioCidade(false);
          }
          if(endereco  ==='' ){
            setVerificaVazioEndereco(true);
          } else{
            setVerificaVazioEndereco(false);
          }
          if(bairro  ==='' ){
            setVerificaVazioBairro(true);
          } else{
            setVerificaVazioBairro(false);
          }
          if(numeroCasa  ==='' ){
            setVerificaVazioNumeroCasa(true);
          } else{
            setVerificaVazioNumeroCasa(false);
          }
          if(estado  ==='' ){
            setVerificaVazioEstado(true);
          } else{
            setVerificaVazioEstado(false);
          }
          if(document  ==='' ){
            setVerificaVazioDocument(true);
          } else{
            setVerificaVazioDocument(false);
          }
          if(name ==='' || name ==='NOME DO TÍTULAR'){
              setVerificaVazioName(true);
          }
          if(expiry ===''){
              setVerificaVazioExpiry(true);
          }
          if(cvc ===''){
            setVerificaVazioCvc(true);
          }
          return true;
        }
      }else{
        setMessageErro('Aceite os Termos da PAYHOW para poder continuar.')
      }
}

async function interessePlano() {
  const data = {
      "plan_id": id
  }
  
  const headers = { 
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': 'Bearer' + token,
  }
  await api.post('/v1/plans/tracking', data, {
    headers: headers
  })
  .then((response) => {
  })
}

function verificaBandeira() {
  var cartoes = {
      visa: /^4[0-9]{12}(?:[0-9]{3})/,
      master: /^5[1-5][0-9]{14}/,
      amex: /^3[47][0-9]{13}/,
      dinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
      elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
      aura: /^(5078\d{2})(\d{2})(\d{11})$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}/
  };

  function testarCC(nr, cartoes) {
    for (var cartao in cartoes) if (nr.match(cartoes[cartao])) return cartao;
    return false;
  }


  var valido = number.replace(/[^\d]+/g, '');
  var invalido = '1234567890';

  [valido, invalido].forEach(function(teste) {
    var cartao = (testarCC(teste,cartoes));
    if(cartao !== false){
      setBandeiraCard(cartao);
    }
  });
}


// via Cep
function limpa_formulário_cep() {
  //Limpa valores do formulário de cep.
  setEndereco('');
  setBairro('');
  setEstado('');
  setCidade('');
}

function meu_callback(conteudo) {
  setVerificaVazioCEP(false)
  //Atualiza os campos com os valores.
  setEndereco(conteudo.data.logradouro);
  setBairro(conteudo.data.bairro);
  setEstado(conteudo.data.uf);
  setCidade(conteudo.data.localidade);
} 
const pesquisacep= (prop) => (event) => {

//Nova variável "cep" somente com dígitos.
var cep = event.target.value.replace(/\D/g, '');

//Verifica se campo cep possui valor informado.
if (cep != "") {

  //Expressão regular para validar o CEP.
  var validacep = /^[0-9]{8}$/;

  //Valida o formato do CEP.
  if(validacep.test(cep)) {
      //Preenche os campos com "..." enquanto consulta webservice.
      setEndereco('...');
      setBairro('...');
      setEstado('...');
      setCidade('...');

      //chama a api do via cep
      dadosViaCep();

  } //end if.
  else {
      //cep é inválido.
      limpa_formulário_cep();
     setVerificaVazioCEP(true)
  }
} //end if.
else {
  //cep sem valor, limpa formulário.
  limpa_formulário_cep();
  setVerificaVazioCEP(true)
}
};

async function dadosViaCep(){
 await axios.get('https://viacep.com.br/ws/'+ cepfsm + '/json')
    .then((response) => {
    meu_callback(response)
    })
    .catch((error) => {
      //CEP não Encontrado.
      limpa_formulário_cep();
      setVerificaVazioCEP(true)
    })
}

  return (
    <ThemeProvider theme={theme}>
    <div className={classes.root}>
        {messageErro !== ''?
          <Alert className="messagensFixed contrataPlano" variant="filled" severity="error">
             {messageErro}
          </Alert>
        :''}
        {messageSuccess !== ''?
          <Alert className="messagensFixed contrataPlano" variant="filled" severity="success">
            {messageSuccess}
          </Alert>
        :''}
      <Grid id="article-conratar-plano" container>
        <Grid item lg={7} xl={6}>
          <Paper className={classes.paper}>
            <div className="contratarPlano">
              <div className={classes.divHeader}>
                <ArrowBackIcon onClick={voltar} className={classes.iconArrow}/>
                <img className={classes.imageLogo} src={Logo} />
              </div>
              <div className={classes.divEnvolveForm}>
                <h1 className={classes.h1Title}>Você está quase lá...</h1>
                <div>
                  <form noValidate autoComplete="off" className={classes.form}>
                  <FormControl className={classes.formControl} variant="outlined">
                    <h2>Cupom de desconto</h2>
                    <OutlinedInput
                      className={classes.formInputD}
                      color="primary"
                      id="filled-adornment-password"
                      onChange={handleInputCupom}
                      endAdornment={
                        <InputAdornment className="usar-cupon-btn" position="end">
                           <Button onClick={validaCupom} variant="contained" color="primary">
                              Usar cupom
                          </Button>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <h2>1. Escolha o seu ciclo de faturamento</h2>
                      <RadioGroup className={classes.radioDiv} aria-label="payPlan" name="payPlan" value={value} onChange={handleRadioChange}>
                        <FormControlLabel value="yearly" control={<Radio color="primary" />}    
                          label= {
                              <React.Fragment>
                                <p className={classes.pValor}> Cobrado anualmente</p>
                                <small>R$ {!novoValorAno?valorAno:novoValorAno}/mês</small>
                              </React.Fragment>
                            }  />
                        <FormControlLabel value="monthly" control={<Radio color="primary" />}
                          label= {
                            <React.Fragment>
                              <p className={classes.pValor}>Cobrado mensalmente</p>
                              <small>R$ {!novoValorMes?valorMes:novoValorMes}/mês</small>
                            </React.Fragment>
                           }  />
                      </RadioGroup>
                      <h2 className={classes.h2SubTitle}>2. Preencha seus dados pessoais</h2>
                      <TextField
                          className={classes.formInput}
                          id="telefone" 
                          value={mask(unMask(telefone), ['(99) 9999-9999', '(99) 9 9999-9999'])}
                          onChange={capturaTelefone()} 
                          label="Telefone para contato" 
                          variant="outlined"
                          type="tel"
                          color="primary"
                          required
                          name="number"
                          error={verificaVazioTelefone  ? true:false}
                          helperText={verificaVazioTelefone  ?"*Campo obrigatório":''}
                         />
                         <TextField
                          className={classes.formInput}
                          id="cep" 
                          label="CEP" 
                          required
                          color="primary"
                          variant="outlined"
                          type="tel"
                          name="number"
                          value={CEP}
                          onChange={capturaCep()} 
                          onBlur={pesquisacep()}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            }}
                          error={verificaVazioCEP  ? true:false}
                          helperText={verificaVazioCEP  ?"*CEP inválido":''}
                         />
                        <div className={classes.divEndereco}>
                            <TextField
                              className={classes.formInputR}
                              id="Cidade" 
                              label="Cidade" 
                              required
                              color="primary"
                              value={cidade}
                              onChange={capturaCidade()} 
                              disabled={cidade?true:false}
                              variant="outlined"
                              type="text"
                              error={verificaVazioCidade  ? true:false}
                              helperText={verificaVazioCidade ?"*Campo obrigatório":''}
                            />
                            <TextField
                              className={classes.formInputN}
                              id="estado" 
                              onChange={capturaEstado()} 
                              required
                              color="primary"
                              value={estado}
                              disabled={estado?true:false}
                              label="Estado" 
                              variant="outlined"
                              type="text"
                              error={verificaVazioEstado  ? true:false}
                              helperText={verificaVazioEstado ?"*Campo obrigatório":''}
                            />
                        </div>
                        <TextField
                          className={classes.formInput}
                          id="bairro" 
                          label="Bairro" 
                          value={bairro}
                          color="primary"
                          required
                          onChange={capturaBairro()} 
                          variant="outlined"
                          type="text"
                          error={verificaVazioBairro  ? true:false}
                          helperText={verificaVazioBairro  ?"*Campo obrigatório":''}
                         />
                        <div className={classes.divEndereco}>
                            <TextField
                              className={classes.formInputR}
                              id="rua" 
                              label="Rua" 
                              value={endereco}
                              color="primary"
                              required
                              onChange={capturaEndereco()} 
                              variant="outlined"
                              type="text"
                              error={verificaVazioEndereco  ? true:false}
                              helperText={verificaVazioEndereco ?"*Campo obrigatório":''}
                            />
                            <TextField
                              className={classes.formInputN}
                              id="numero" 
                              label="Número" 
                              required
                              color="primary"
                              value={numeroCasa}
                              onChange={capturaNumeroCasa()} 
                              variant="outlined"
                              type="number"
                              error={verificaVazioNumeroCasa  ? true:false}
                              helperText={verificaVazioNumeroCasa ?"*Campo obrigatório":''}
                            />
                        </div>
                      <h2 className={classes.h2SubTitle}>3. Insira os dados de pagamento</h2>
                      <RadioGroup aria-label="cardInfo" name="cardInfo" value="cardInfo">
                        <FormControlLabel value="cardInfo" control={<Radio color="primary" />} 
                          label= {
                            <React.Fragment>
                              <img src={payImage} className={classes.imgPay}/>
                            </React.Fragment>
                          } 
                        />
                      </RadioGroup>
                      <div id="PaymentForm">
                        <div>
                          <Cards
                            cvc={cvc}
                            expiry={expiry}
                            focused={focus}
                            name={name}
                            number={number}
                          />
                        </div>
                        <TextField
                          className={classes.formInput}
                          id="document" 
                          label="CPF ou CNPJ do títular" 
                          value={mask(unMask(document), ['999.999.999-99', '99.999.999/9999-99'])}
                          onChange={capturaDocument()} 
                          onKeyUp={(event) => { setValidaCPF(validaCpfCnpj(event.target.value)) }}
                          variant="outlined"
                          type="tel"
                          color="primary"
                          required
                          name="cpf"
                          error={verificaVazioDocument || !validaCPF ? true:false}
                          helperText={ verificaVazioDocument || !validaCPF ? "*Número de documento inválido" : '' }
                         />
                         <TextField
                          className={classes.formInput}
                          id="cardNumber" 
                          label="Número do cartão" 
                          variant="outlined"
                          type="tel"
                          name="number"
                          color="primary"
                          required
                          onChange={handleInputNumber}
                          onFocus={handleInputFocus}
                          maxLength = "19"
                          inputProps ={{maxLength:22}}
                          onKeyPress={mascaraNumber()}
                          error={verificaVazioNumber && number ==='' ? true:false}
                          helperText={verificaVazioNumber && number ==='' ?"*Campo obrigatório":''}
                         />
                        <TextField
                          className={classes.formInput}
                          id="cardName" 
                          label="Nome do títular" 
                          required
                          variant="outlined"
                          color="primary"
                          type="text"
                          name="name"
                          onChange={handleInputName}
                          onFocus={handleInputFocus}
                          error={verificaVazioName && name ==='NOME DO TÍTULAR' ? true:false}
                          helperText={verificaVazioName && name ==='NOME DO TÍTULAR' ?"*Campo obrigatório":''}
                         />
                         <div className={classes.formRow}>
                          <TextField
                            className={classes.formInputM}
                            id="dataValidade" 
                            label="Data de validade" 
                            variant="outlined"
                            type="tel"
                            name="dataValidade"
                            onChange={handleInputValidade}
                            required
                            color="primary"
                            onFocus={handleInputFocus}
                            maxLength = "5"
                            inputProps ={{maxLength:5}}
                            onKeyPress={mascara()}
                            error={verificaVazioExpiry && expiry ==='' ? true:false}
                            helperText={verificaVazioExpiry && expiry ==='' ?"*Campo obrigatório":''}
                          />
                          <TextField
                            className={classes.formInputMm}
                            id="cvc" 
                            label="CVV" 
                            variant="outlined"
                            type="tel"
                            color="primary"
                            required
                            name="cvc"
                            onChange={handleInputCvv}
                            onFocus={handleInputFocus}
                            maxLength = "4"
                            inputProps ={{maxLength:4}}
                            error={verificaVazioCvc && cvc ==='' ? true:false}
                            helperText={verificaVazioCvc && cvc ==='' ?"*Campo obrigatório":''}
                          />
                         </div>
                      </div>
                    </FormControl>
                  </form>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <div id="grid_card_plano">
              <div id="fixed-plano">
                <div className="infoPlanos">
                  <div className="card-opcoes-pag">
                  <div class="linha-cor"></div>
                    <div className="divEnvolveText">
                      <h3 className="namePlan">Plano {nome}</h3>
                      <h5 className="valorPlan">Valor do pagamento: 
                      <span className="envolve-price-ds">
                        {novoValorMes?<span className="price-plano"></span>:''}
                        R$ {value==='monthly'? valorMes : valorAnoTotal}</span>
                        {novoValorMes?<span className="preco-desconto">R$ {value==='monthly'? novoValorMes : novoValorAnoTotal}</span>:''}
                      </h5>
                      <p>Será cobrado o valor total de R$ {value==='monthly'? `${!novoValorMes?valorMes:novoValorMes} mensal` : `${!novoValorAnoTotal?valorAnoTotal:novoValorAnoTotal} anual`} até você cancelar sua assinatura. 
                        Pagamentos realizados anteriormente não serão reembolsados quando você cancelar,
                        a menos que haja obrigatoriedade legal. Suas informações de pagamento são criptografadas
                        e seguras.</p>
                        <FormControlLabel
                          control={<Checkbox checked={state.checkedA} onChange={handleChange} color="primary" name="checkedA" />}
                          label= {
                            <React.Fragment>
                              <p className="termosPlan">Eu concordo com os <a href={Termos} target="_blank">Termos da PAYHOW</a> e com os <strong>Termos de renovação automática acima</strong></p>
                            </React.Fragment>
                          } 
                        />
                        <div className="envolveBtnComprar">
                          <Button className="btnComprar" onClick={enviaDadosPag} variant="contained" color="primary">
                            Contratar agora
                          </Button>
                        </div>
                    </div>
                  </div>
                  <div className="envolveInfoPlanoP">
                    <div>
                      <p> O que está incluído no Plano {nome}? </p>
                      <p> <CheckIcon className={classes.iconCheck}/> 
                        {nome=='Basic'?'Somente 1 loja virtual':
                          nome=='Plus'?'Até 3 lojas virtuais':
                          nome=='Pro'?'Até 10 lojas virtuais':
                          nome=='Freelancers'?'Até 50 lojas virtuais':
                          nome=='Agências'?'Até 100 lojas virtuais':''}</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Integração com Woocommerce</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Integração com Melhor Envio</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Métodos de "Frete Grátis"</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Métodos de "Frete Fixo"</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Métodos de "Frete Personalizado"</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Método de frete com Correios</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Método de frete com "Retirada na loja física"</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Mercado pago</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Mercado pago QR Code</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Pagar.me (Cartões e Boleto)</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Pagseguro (Cartões e Boletos)</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Cielo</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Picpay</p>
                      <p><CheckIcon className={classes.iconCheck}/>  AME Digital</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Integração com Google Analytics</p>
                      <p><CheckIcon className={classes.iconCheck}/>  Integração com Facebook Pixel</p>
                      <p> <CheckIcon className={classes.iconCheck}/><strong>
                        {nome=='Basic'?'Suporte técnico completo por e-mail, chat ou telefone':
                          nome=='Plus'?'Suporte técnico completo por e-mail, chat ou telefone':
                          nome=='Pro'?'Suporte técnico completo por e-mail, chat ou telefone':
                          nome=='Freelancers'?'Suporte totalmente VIP':
                          nome=='Agências'?'Suporte totalmente VIP':''}</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <div class="border-top d-flex">
          <a href={Termos} target="_blank">
            <p class="my-3">Termos e Privacidade</p>
          </a>
          <p class="my-3"><small class="text-muted">PAYHOW © <span class="anoAtual">2021</span></small></p>
        </div>
      </Grid>
    </div>
    </ThemeProvider>
  );
}