import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import api from '../../../../../../Services/api';
import './css/Cartao.css';
import {useHistory} from 'react-router-dom';
import Load from '../../../../../../Components/Load';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    }
  }));

export default function FormBoleto(params) {
    const history = useHistory();
    const classes = useStyles();
    const [id, setId] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [name, setName] = React.useState('');
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [appsCard, setAppsCard] = React.useState('');
    const [checked, setChecked] = React.useState('');
    const [payhowPagamentos, setPayhowPagamentos] = React.useState(false);

   async function buscaDadosCard(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/boleto/company/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        setAppsCard(response.data);
    })
    .catch((error) => {

    })
}
function exibeForm(id){
    history.push('/form-apps-boleto',{params:id});
}
function voltar(){
    history.push('/apps');
}

    useEffect(() => {
        buscaDadosCard();
        buscaBotelo();
        buscaPayhowPagamentos();
    },[]) 

    async function buscaBotelo(){
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
        }
        await api.get('/v1/integrations/company/' + idCompanies,  {
            headers: headers
        })
        .then((response) => {
            var dados = response.data;
            for(let i = 0; dados.length > i; i++){
                if(dados[i].id==='boleto'){
                    setId(dados[i].id);
                    setPhoto(dados[i].photo);
                    setName(dados[i].name);
                }
                  
            }
        })
        .catch((error) => {
     
        })
    }

      
  async function buscaPayhowPagamentos(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/mode/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
        if(dados.integration_mode==='auto'){
            setPayhowPagamentos(true);
        }else{
            setPayhowPagamentos(false);
        }
    })
    .catch((error) => {

    })
}
function appPayhow(){
    params.atualizaId(38)
}

return (
    <div className="Apps-form-style">
        <div className="App-suporte">
            <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft}/></a>
        </div>
    <div className="App-form-style">
    <form  className="PicPay-form" noValidate autoComplete="off">
        <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
                <img className="PicPay-logo" src={photo}/>
                    <h1 className="PicPay-title">{name}</h1>
            </div>
        </div>
        {!payhowPagamentos ? 
        <div className="esonde-pagamento">
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                    <div className="PicPay-envolve-radio-teste-prod">
                        <h3 className="mt-1">Escolha a empresa que deseja utilizar:</h3>
                        {appsCard !=='' ?
                          appsCard.map((infoAppCard) => (
                              <div>
                            <RadioGroup  aria-label="position" name="position" defaultValue="top">
                                <FormControlLabel 
                                    onClick={() => exibeForm(infoAppCard)}
                                    name="environment"
                                    checked = {infoAppCard.enable === 1 ? true : false} 
                                    value="producao" 
                                    control={<Radio color="secondary" />} 
                                    label= {
                                    <React.Fragment>
                                    <div className="envolve-imgCheck-text">
                                        <img src={infoAppCard.photo}/>
                                        <p>{infoAppCard.name}</p>
                                    </div>
                                    </React.Fragment>
                                    } 
                                />
                            </RadioGroup>
                            </div>
                           ))
                           :
                           <div>
                               <Load/>
                          </div>
                      }
                    </div>
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button type="button" variant="contained" onClick={voltar} color="secondary">Voltar</Button>
            </div>
            </div>
              :
              <div className="app_payhow-ativo">
                  <p>Para visualizar as informações de integração deste App será necessário desativar o App PAYHOW Pagamentos</p>
                  <Button type="button" onClick={appPayhow} variant="contained" color="secondary">Desativar o App PAYHOW Pagamentos</Button>
              </div>
              }
        </form>
    </div>
    </div>
);
}
