import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import CodeMirror from '@uiw/react-codemirror';
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/elegant.css';


import '../../../css/Loja.css'

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyleH2: {
        marginTop: '2.5rem',
        marginBottom: '1rem',
    },
    textStyleH1: {
        marginBottom: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    },
    formControl: {
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
  }));

export default function FormScript(InfoApp) {
    const classes = useStyles();
    const [codeCss, setCodeCss] = React.useState('');
    const [codeJs, setCodeJs] = React.useState('');
    // const [messageErro, setMessageErro] = React.useState('');
    // const [messageSuccess, setMessageSuccess] = React.useState('');

    const messageErro = '';
    const messageSuccess = '';

    // const capturaCodeCss = (prop) => (event) => {
    //     setCodeCss(event.target.value );
    // };
    // const capturaCodeJs = (prop) => (event) => {
    //     setCodeJs(event.target.value );
    // };


return (
    <div className="Apps-form-style">
    <div className="App-form-style">
        <div className="Dados-banc-links-title">
        <h2 className="title-abas">Scripts</h2>
        </div>
    <form onSubmit="" className="FormMeusDados formScript" noValidate autoComplete="off">
    {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                       {messageSuccess !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                    :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                <h3 className={classes.textStyleH1}>1. Preencha o campo com o código CSS desejado:</h3>
                <CodeMirror 
                        value={codeCss}
                        options={{
                        theme: 'elegant',
                        keyMap: 'sublime',
                        tabSize: 100,
                        mode: 'css',
                        }}
                    />
                     <h3 className={classes.textStyleH2} >2. Preencha o campo com o código JavaScript desejado:</h3>
                    <CodeMirror
                        value={codeJs}
                        options={{
                        theme: 'elegant',
                        keyMap: 'sublime',
                        tabSize: 100,
                        mode: 'javascript',
                        }}
                    />
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button type="submit" variant="contained" color="secondary">Salvar</Button>
            </div>
        </form>
    </div>
    </div>
);
} 