import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../../../Services/api';
import Loading from '../../../../../../../Components/Load';
import ModalIntNaoDisp from '../../ModalIntNaoDisp';
import '../css/Cartao.css';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    }
  }));

export default function FormAme(InfoApp) {
    const history = useHistory();
    const classes = useStyles();
    const [id, setId] = React.useState(InfoApp.infoApp.id);
    const [photo, setPhoto] = React.useState(InfoApp.infoApp.photo);
    const [name, setName] = React.useState(InfoApp.infoApp.name);
    const [statusApp, setStatusApp] = React.useState(InfoApp.infoApp.authenticated);
    const [enableApp, setEnableApp] = React.useState(InfoApp.infoApp.enable);
    const [checked, setChecked] = React.useState(InfoApp.infoApp.enable);
    const [merchantKey, setMerchantKey] = React.useState('');
    const [merchantId, setMerchantId] = React.useState('');
    const [parcSemJuros, setParcSemJuros] = React.useState('');
    const [environment, setEnvironment] = React.useState('');
    const [environment_sandbox, setEnvironmentSandbox] = React.useState(InfoApp.infoApp.environment_sandbox);
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [verificaVazioToken, setVerificaVazioToken] = React.useState(false);
    const [verificaVazioSaller, setVerificaVazioSaller] = React.useState(false);
    const [verificaVazioCashback, setVerificaVazioCashback] = React.useState(false);
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [typeAmbient, setTypeAmbient] = React.useState('');
    const [Load, setLoad] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [messageModal, setMessageModal] = React.useState('');

    const capturaPorcCashback = (prop) => (event) => {
        setMerchantKey(event.target.value );
    };
    const capturaApplicationToken = (prop) => (event) => {
        setParcSemJuros(event.target.value );
    };
    const capturaApplicationKey = (prop) => (event) => {
        setMerchantId(event.target.value );
    };
    const maxLengthCheck = (prop) =>  (object) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }else if (object.target.value > 12){
            object.target.value = 12
          }
        }

        
   const openModal = () => {
    setModal(true)
}
const changeModal = (modal) => {
    setModal(modal)
}



   function checkEnable(){
    if(checked === true) {
        setChecked(false);
        setEnableApp(0);
    }
    else{
        setChecked(true);
        setEnableApp(1); 
    }
   }

   function clickRadio(radio){
    if(radio ==='dev'){
        setEnvironment('development');
        setTypeAmbient('(Teste)');
    }else{
        setEnvironment('production');
        setTypeAmbient('(Produção)');
    }
   }
  function cancelarApp(){
    history.push('/form-apps');
  }
  useEffect(() => {
    setStatusApp(InfoApp.infoApp.authenticated);
    if(!InfoApp.infoApp.environment){
        setEnvironment('production');
    }else{
        setEnvironment(InfoApp.infoApp.environment);
    }
    buscaDadosApp();
  },[statusApp]) 

    async function enviaFormApp(event){
        setLoad(true);
    event.preventDefault();
        var data = {
            "ativo": id,
            "company_id": idCompanies,
            "enable": checked,
            "environment": environment,
            "integration_id": id,
            "sandbox": id,
            "credentials" : {
                "client_id": merchantKey,
                "secret": merchantId,
                "installments": parcSemJuros,
            }
        }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
          if(merchantKey !=='' && merchantId !=='' && parcSemJuros !==''){
        await api.post('/v1/integrations/v2', data, {
            headers: headers
          })
          .then((response) => {
            setLoad(false);
            setMessageErro('');
            setMessageSuccess('Informações salvas com sucesso');
            setTimeout (() => {
                setMessageSuccess('');
          }, 1500);
            setTimeout(() => {
            history.push('/form-apps');
        }, 2000);
          })
          .catch((error) => {
            openModal();
            setMessageModal(error.response.data.errors.message);
            setLoad(false);
          })
        }else{
            setLoad(false);
            if(parcSemJuros ==='' ){
                setVerificaVazioToken(true);
            } 
            if(merchantId ===''){
                setVerificaVazioSaller(true);
            }if(merchantKey === ''){
                setVerificaVazioCashback(true);
            }
            return true;
        }
  }

  async function buscaDadosApp(){
      if(environment==='development'){
        setTypeAmbient('(Teste)');
      }else{
        setTypeAmbient('(Produção)');
      }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
        await api.get('/v1/integrations/' + id + "/fields/" + idCompanies,  {
            headers: headers
          })
          .then((response) => {
              if(response.data.status !== null){
                setMerchantKey(response.data.client_id);
                setMerchantId(response.data.secret);
                setParcSemJuros(response.data.installments);
              }
          })
  }

return (
    <div className="Apps-form-style">
        <div className="App-suporte">
            <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft}/></a>
        </div>
    <div className="App-form-style">
    <form onSubmit={enviaFormApp} className="PicPay-form" noValidate autoComplete="off">
        <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
            <div className={checked ? "App-circle-status App-circle-status-true": "App-circle-status"}></div>
                <img className="PicPay-logo" src={photo}/>
                    <h1 className="PicPay-title">{name}</h1>
            </div>
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    onClick={checkEnable}
                    value={enableApp}
                    checked={checked}
                    control={<Switch color="secondary" />}
                    label="Ativo:"
                    labelPlacement="start"
                />
            </FormGroup>
        </div>
        <div id="AlertStatus" className="AlertStatus">
            { statusApp ?
             <Alert variant="filled" severity="success">
                Status: Online, conectado com a empresa de integração
            </Alert>
                :
            <Alert variant="filled" severity="error">
                Status: Offline, desconectado.
                Talvez alguma informação esteja incorreta, verifique os campos preenchidos
            </Alert>
            }
        </div>
        <div className="PicPay-link-tutoriais">
        <p className="PicPay-p-form">Informações da aplicação:</p>
            <a target="_blank" href="https://app.payhow.lionsoft.com.br/tutoriais#payPalCheckoutTrasparente">Como obter as informações do PayPal?</a>
        </div>
        {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                     {messageSuccess!== ''?
                     <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                     :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                    <div className="PicPay-envolve-radio-teste-prod">
                        <h4 className="mt-1">1. Escolha o ambiente que sua aplicação irá utilizar:</h4>
                    <RadioGroup  aria-label="position" name="position" defaultValue="top">
                    {environment_sandbox === 1?
                        <FormControlLabel
                            onClick={()=>clickRadio('dev')}
                            name="environment"
                            checked={environment === 'development' ? true : false}
                            value="teste" control={<Radio color="secondary" />} 
                            label="Teste"
                        />
                        :''}
                        <FormControlLabel 
                            onClick={()=>clickRadio('prod')}
                            name="environment"
                            checked={environment === 'production' ? true : false} 
                            value="producao" 
                            control={<Radio color="secondary" />} 
                            label="Produção" 
                        />
                    </RadioGroup>
                    </div>
                    <h4 className="mt-2">2. Preencha os campos com os dados correspondentes:</h4>
                    <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="merchantKey"
                        label={`Client ID ${typeAmbient}`}
                        type="text"
                        value={merchantKey}  
                        onChange={capturaPorcCashback()}
                        variant="outlined" 
                        required
                        error={verificaVazioCashback && merchantKey ==='' ? true:false}
                        helperText={verificaVazioCashback && merchantKey ==='' ?"*Campo obrigatório":''}
                         />
                    <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="merchantId" 
                        label={`Secret ${typeAmbient}`} 
                        type="text" 
                        value={merchantId} 
                        onChange={capturaApplicationKey()} 
                        variant="outlined" 
                        required
                        error={verificaVazioSaller && merchantId ==='' ? true:false}
                        helperText={verificaVazioSaller && merchantId ==='' ?"*Campo obrigatório":''}
                        />
                    <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="parcSemJuros" 
                        label={`Total máximo de parcelas sem juros ${typeAmbient}`} 
                        type="number" 
                        value={parcSemJuros} 
                        onChange={capturaApplicationToken()} 
                        variant="outlined" 
                        maxLength = "2"
                        inputProps = {{maxLength:2}}
                        onInput={maxLengthCheck()}
                        required
                        error={verificaVazioToken && parcSemJuros ==='' ? true:false}
                        helperText={verificaVazioToken && parcSemJuros ==='' ?"*Campo obrigatório":''}
                    />
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button variant="contained" onClick={cancelarApp}>Cancelar</Button>
                {!Load ?
                <Button type="submit" variant="contained" color="secondary">Salvar informações</Button>
                :
                <Button type="submit" variant="contained" color="secondary"><Loading/></Button>
                }
            </div>
        </form>
    </div>
    <ModalIntNaoDisp open={modal} onChange={changeModal.bind(modal)} message={messageModal}/>
    </div>
);
}