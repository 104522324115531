import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CardCielo from '../Cielo/Cielo';
import CardMercadoPago from '../MercadoPago/MercadoPago';
import CardPagarme from '../Pagarme/Pagarme';
import CardPagSeguro from '../PagSeguro/PagSeguro';
import CardPayPal from '../PayPal/PayPal'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
}));

export default function FormApp(infoCard) {
  const classes = useStyles();
  const [idApp, setIdApp] = React.useState(infoCard.location.state.params.id);
  const [infoApp, setInfoApp] = React.useState(infoCard.location.state.params);
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
            <Paper className={classes.paper}>
              { idApp === 16 ? <CardCielo infoApp={infoApp}/>
              : idApp=== 13 ? <CardMercadoPago infoApp={infoApp}/>
              : idApp=== 12 ? <CardPagarme infoApp={infoApp}/>
              : idApp=== 14 ? <CardPagSeguro infoApp={infoApp}/>
              : idApp=== 15 ? <CardPayPal infoApp={infoApp}/>
              :
              'Ainda não possui formulário!'}
            </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
   
    </div>
  );
}