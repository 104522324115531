import React, {useEffect} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

export default function PositionedSnackbar(props) {
    const open = props.alert
    const error = props.error
    const [state, setState] = React.useState(open);
     
          
      const handleClose = () => {
        setState( false );
        
      };
      
      useEffect(() => {
            setState( open );
      },[open]);  


  return (
    <div>
      <Snackbar
        open={state}
        onClose={handleClose}
        message="I love snacks"
      >
          <MuiAlert elevation={6} variant="filled" severity={error === true ? 'error' : 'success'}>{props.mensagem}</MuiAlert>
      </Snackbar>
   
      
    </div>
  );
}