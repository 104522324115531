import React from 'react';
import './css/index.css';
import { FaFacebookF } from 'react-icons/fa';
import FacebookLogin from 'react-facebook-login';
import api from '../../Services/api'
import {useHistory} from 'react-router-dom';



const ReactFacebookLogin = () =>{
  const History = useHistory();
const responseFacebook = (response) => {
  let IDFacebook = response.id;
  let access_token = response.accessToken;
  makeRequest(IDFacebook,access_token,response); 
};

async function makeRequest(IDFacebook,access_token,response) {
  const config = {
    headers: { 
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'get',
    url: '/v1/customers/register/facebook/'+IDFacebook,
  }
  
  let res = await api(config)
  let status = res.data.status;
  if(status===true){
    makePostRequest(access_token);
}else{
 
  History.push('/login-secundario',{params:response, login: 'facebook'}); 
 
}
}
async function makePostRequest(access_token) {
  var data = {
    'access_token': access_token,
    }
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
      api.post('/v1/customers/login/facebook', data, {
        headers: headers
      })
      .then((response) => {
         var dados = response.data;
         History.push('/home?load'); 
         const token = dados.token
         const companiesId = localStorage.getItem('companiesId')
         localStorage.setItem('tokenUser',token);
      })
      .catch((error) => {
      })
}

  return (
        <div className="facebook-envonve-btns">
          <FacebookLogin
              appId="646972106049068"
              autoLoad={false}
              fields="name,email,picture.type(large)"
              cssClass="facebook-btn"
              icon={<FaFacebookF className="facebook-icon"/>}
              textButton = "Entrar com Facebook"                                                                
              callback={responseFacebook} 
              />
        </div>
  );
}
export default ReactFacebookLogin;