import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import api from '../../../Services/api';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '47ch',
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    }
  }));

export default function FormPicPay(InfoApp) {
    const history = useHistory();
    const classes = useStyles();
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [erroConfirma, setErroConfirma] = React.useState('');
    const [msgErroSenha, setMsgErroSenha] = React.useState('');
    const [msgErroConfirm, setMsgErroConfirm] = React.useState('');
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
 
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const [valuesC, setValuesC] = React.useState({
        password: '',
        showPassword: false,
    });
    const [valuesL, setValuesL] = React.useState({
        password: '',
        showPassword: false,
    });
  useEffect(() => {
  },[]) 
async function enviaFormAltSenha(event){
    event.preventDefault();
    if(values.password !== valuesC.password){
        setErroConfirma(true) 
        setMsgErroSenha('As senhas não são idênticas')
        setMsgErroConfirm('As senhas não são idênticas')
    }else{
        setErroConfirma(false)
    } 
    var data = {
        "password": valuesL.password,
        "new_password": valuesC.password
    }
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
      }
      if(valuesL.password !=='' && values.password !=='' && valuesC.password !=='' && valuesC.password===values.password ){
    await api.put('/v1/customers/profile/password', data, {
        headers: headers
      })
      .then((response) => {
       setMessageSuccess('Senha atualizada com sucesso');
       setTimeout(() => {
           setMessageSuccess('');
     }, 1500);
      })
      .catch((error) => {
          setMessageErro(error.response.data.errors.message);
          setTimeout(() => {
              setMessageErro('');
        }, 1500);
      })
    }
}

const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
};

const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
};

const handleMouseDownPassword = (event) => {
    event.preventDefault();
};
const handleChangeC = (prop) => (event) => {
    setValuesC({ ...valuesC, [prop]: event.target.value });
};

const handleClickShowPasswordC = () => {
    setValuesC({ ...valuesC, showPassword: !valuesC.showPassword });
};

const handleMouseDownPasswordC = (event) => {
    event.preventDefault();
};

const handleChangeL = (prop) => (event) => {
    setValuesL({ ...valuesL, [prop]: event.target.value });
};

const handleClickShowPasswordL = () => {
    setValuesL({ ...valuesL, showPassword: !valuesL.showPassword });
};

const handleMouseDownPasswordL = (event) => {
    event.preventDefault();
};



return (
    <div className="Apps-form-style">
    <div className="App-form-style">
    <h2 className="title-abas">Alterar Senha</h2>
    <form onSubmit={enviaFormAltSenha} className="FormMeusDados" noValidate autoComplete="off">
    {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                       {messageSuccess !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                    :''}
        <div className="envolve-alt-senha">
        <FormControl className={classes.root} variant="outlined">
            <InputLabel color="secondary" required htmlFor="password">Senha Atual</InputLabel>
            <OutlinedInput
                id="lastpassword"
                autoComplete="current-password"
                type={valuesL.showPassword ? 'text' : 'password'}
                value={valuesL.password}
                required
                color="secondary"
                onChange={handleChangeL('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordL}
                            onMouseDown={handleMouseDownPasswordL}
                            edge="end"
                        >
                    {valuesL.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={95}
            />
            </FormControl>
    <FormControl className={classes.root} variant="outlined" error={erroConfirma}>
            <InputLabel color="secondary" required htmlFor="password">Senha</InputLabel>
            <OutlinedInput
                id="password"
                autoComplete="current-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                required
                color="secondary"
                onChange={handleChange('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={53}
            />
            {erroConfirma ? <FormHelperText id="component-error-text">{msgErroSenha}</FormHelperText> : ''}
            </FormControl>
            <FormControl className={classes.root} variant="outlined" error={erroConfirma}>
            <InputLabel color="secondary" required htmlFor="confirmaPassword">Confirmar senha</InputLabel>
            <OutlinedInput
                id="confirmaPassword"
                autoComplete="current-password"
                type={valuesC.showPassword ? 'text' : 'password'}
                value={valuesC.password}
                required
                color="secondary"
                onChange={handleChangeC('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordC}
                            onMouseDown={handleMouseDownPasswordC}
                            edge="end"
                        >
                    {valuesC.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={130}
                
            />
            {erroConfirma ? <FormHelperText id="component-error-text">{msgErroConfirm}</FormHelperText> : ''}
            </FormControl>
            </div>
            <div className="PiPay-button">
                <Button type="submit" variant="contained" color="secondary">Alterar Senha</Button>
            </div>
        </form>
    </div>
    </div>
);
}