import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../../css/Modal.css';
import api from '../../../../Services/api';
import CircularLoad from '../../../../Components/Load';

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [openR, setOpenR] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [titleMessage, setTitleMessage] = React.useState();
  const [resultado, setResultado] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenR = () => {
    setOpenR(true);
  };
  const handleCloseR = () => {
    setOpenR(false);
  };


  async function enviaReset(event){
    event.preventDefault();
    setResultado(false);
    var email = document.getElementById('emailEsqueciSenha').value;
    var data = {
      'email': email,
      }

      const headers = { 
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
       await api.post('/v1/customers/password/reset', data, {
          headers: headers
        })
        .then((response) => {
          setResultado(true);
           handleClickOpenR();
           handleClose();
           setTitleMessage('A Senha foi alterada com sucesso!');
           setMessage(response.data.message); 
        })
        .catch((error) => {
          setResultado(true);
          handleClickOpenR();
          handleClose();
          setTitleMessage('Ocorreu um erro ao alterar a senha!');
          setMessage(error.response.data.errors.message); 
        })
  };

  return (
    <div>
    <div className="modal-esqueci-senha">
        <div className="login-esqueceu-senha">
            <button type="button" onClick={handleClickOpen}>
                <p>Esqueceu a senha?</p>
            </button>
        </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <form id="esqueciSenha-form" onSubmit={enviaReset} autoComplete="off">
        <DialogTitle id="form-dialog-title">Esqueceu sua senha?</DialogTitle>
        <DialogContent>
          <DialogContentText>
          Indentifique-se para receber um e-mail com as instruções e o link para criar uma nova senha.
          </DialogContentText>
          <div className="modal-input_email">
             <TextField type="email" id="emailEsqueciSenha" label="E-mail" variant="outlined" required />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" color="secondary" className="btnModalEsqueciSenha">
          {resultado ? 'Enviar' : <CircularLoad/>}
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
    <div className="modal-retorno-senha">
      <Dialog open={openR} onClose={handleCloseR} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{titleMessage}</DialogTitle>
        <DialogContent>
          <DialogContentText>
              {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseR} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </div>
  );
}