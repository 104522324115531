import React,{useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Logo from '../../../../Assets/images/logo.fw.svg';
import api from '../../../../Services/api';
import '../../css/App.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalApp(openApp) {
  const [open, setOpen] = React.useState(false);
  const id = openApp.id;
  const token =localStorage.getItem('tokenUser');
  const [message, setMessage] = React.useState('Essa integração está em desenvolvimento pela equipe PAYHOW!');
  const [result, setResult] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    openApp.setStateModal(false);
    setMessage('Essa integração está em desenvolvimento pela nossa equipe!');
    setResult(true);
  };
  
  async function interesseInte(){
    const data = {};
    const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
    await api.post(`/v1/notifications/integration_unavailable/${id}`,data, {
      headers: headers
    })
    .then((response) => {
     setResult(false);
     setMessage('Informações enviadas com sucesso. Fique tranquilo(a), que muito em breve iremos lhe avisar sobre essa nova integração. Obrigado!')
    })
    .catch((error) => {
      console.error(error)
      setResult(false);
      setMessage('Ocorreu um erro ao solicitar o aviso, por favor tente mais tarde!')
    })
  }
  

  useEffect(() => {
    setOpen(openApp.open);
   }, [openApp]);

  return (
    <div>
      <Dialog
      className="modal-app"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"><img src={Logo}/></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose} >
            Fechar
          </Button>
          {result ?
          <Button onClick={interesseInte} variant="contained" color="secondary">
            Avise-me quando estiver disponível
          </Button>
          :''}
        </DialogActions>
      </Dialog>
    </div>
  );
}