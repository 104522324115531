import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BlocoApp from './Components/blocoApp';
import api from '../../Services/api';
import './css/App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
}));

export default function App() {
  const classes = useStyles();
  const [token, setToken] = useState(null);
  const [idCompanies, setIdCompanies] = useState(null);
  const [dadosApps, setDadosApps] = useState('');

  async function listaApps(){
        
    try{
      const response = await api.get('/v1/integrations/company/'+idCompanies+'', 
      {
       headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json',
          'Authorization': 'Bearer' + token
       }
      });
      setDadosApps(response.data);

    }catch(error){
    
    }  
  }

  function pegaToken(){
    setToken(localStorage.getItem('tokenUser'));
    setIdCompanies(localStorage.getItem('companiesId'));
    if(token !== null && idCompanies !== null){
      listaApps();
    }
  } 

  useEffect(() => {
    setTimeout(() => {
      pegaToken();
    },200);
  },[idCompanies]) 

  return (
    <div className={classes.root}>
      <div className="App-divEnvolve">
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <div className="App-envolve-conteudo">
                <div>
                    <h2>Apps</h2> 
                </div>
                <div className="App-envolve-apps">
                  <BlocoApp props={dadosApps}/>
                </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}