

import React, { createContext, useState, useContext } from "react";
//import { StateGlobal } from '../../ContextAPI/context';
//const {count ,setCount} = StateGlobal();

const CountContext = createContext();

export default function CountProvider({ children }) {
    const [confingfrete, setConfigfrete] = useState();
    const [app, setApp] = useState();
    const [pedidos, setPedidos] = useState();
    const [lojaativa, setSetlojaativa] = useState();

   

    return (
        <CountContext.Provider
            value={{
                confingfrete,//usando para teste
                setConfigfrete, //usando para teste  
                app, 
                setApp , 
                pedidos, 
                setPedidos,
                lojaativa, 
                setSetlojaativa            

            }}
        >
            {children}
        </CountContext.Provider>
    );
}

export function StateGlobal() {
    const context = useContext(CountContext);

    const { confingfrete,
        setConfigfrete,
        app, 
        setApp , 
        pedidos, 
        setPedidos,
        lojaativa, 
        setSetlojaativa
    } = context;

    return {
        confingfrete,
        setConfigfrete,
        app, 
        setApp , 
        pedidos, 
        setPedidos,
        lojaativa, 
        setSetlojaativa
    };
}
{/* <ContextAPI>
<App />
</ContextAPI> */}