import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import api from '../../../Services/api';
import ImgGratis from '../../../Assets/images/meuplano1.png';
import ImgPlus from '../../../Assets/images/meuplano2.png';
import ImgPro from '../../../Assets/images/meuplano3.png';
import {useHistory} from 'react-router-dom';
import EventIcon from '@material-ui/icons/Event';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '47ch',
     
      },
    },
    iconEvent:{
        color: "#8b8b8b",
        width: "2rem",
        height: "2rem",
        marginRight: "1rem"
    },
  }));

export default function MeuPlano(props) {
    const history = useHistory();
    const classes = useStyles();
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [infoPlanos, setInfoPlano] = React.useState('');
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');

function modalPlanos(){
    history.push('/planos')
}


  function FormataStringData(data) {
    var dia  = data.split("-")[2];
    var mes  = data.split("-")[1];
    var ano  = data.split("-")[0];
  
    return dia + '/' + (mes) + '/' + (ano);
  }
  async function getDadosPlano(){
    const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
      await api.get('/v1/plans', {
          headers: headers
      })
      .then((response) => {
        var dados = response.data;
        setInfoPlano(dados);
        // if(props.modalPlano === 'modal'){
        //     setOpenPlanos(true);
        //   }
      }) 
}
useEffect(() => {
    getDadosPlano();
  
  },[]) 

  async function cancelarPlano(){
        var data = {
            "company_id": idCompanies
        }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
        await api.post('/v1/plans/request/cancel', data, {
            headers: headers
          })
          .then((response) => {
            setMessageSuccess('Solicitação de cancelamento enviada com sucesso. Em breve nossa equipe entrará em contato!');
            setTimeout(() => {
                setMessageSuccess('');
          }, 1500);
          })
          .catch((error) => {
            setMessageErro('Ocorreu um erro ao cancelar seu plano. Por favor, tente novamente mais tarde!');
            setTimeout(() => {
                setMessageErro('');
          }, 1500);
             
          })
  }

return (
    <div className="Apps-form-style">
        {messageErro !== ''?
                    <Alert className="margin-topbot messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                       {messageSuccess !== ''?
                    <Alert className="margin-topbot messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                    :''}
            {infoPlanos !== ''?
        infoPlanos.map((infoPlano) => ( 
    <div className="Apps-form-style">
        {infoPlano.current?
    <div key={infoPlano.id} className="App-form-style meus-planos">
        <div className="meu-plano-header">
            <h2 className="title-abas-plano">Meu plano</h2>
            <Button type="button" variant="outlined" color="secondary" onClick={modalPlanos}>Trocar plano</Button>
        </div>
        <div className="meu-plano-envolve-conteudo">
            <div className="meus-planos-envolve-img-text">
                <div className="meu-plano-img">
                    <img src={
                    infoPlano.name ==='free' ? ImgGratis: 
                    infoPlano.name ==='pro' ? ImgPro : 
                    infoPlano.name ==='plus'? ImgPlus :
                    infoPlano.name ==='basic'? ImgGratis :
                    infoPlano.name ==='freelancers'? ImgPro :
                    infoPlano.name ==='agencias'? ImgPlus :''
                    }/>
                </div>
                <div  className="meu-plano-p">
                    <p className="meus-planos-p-plano">{
                    infoPlano.name ==='free' ? 'GRATUITO': 
                    infoPlano.name ==='pro' ? 'PRO' : 
                    infoPlano.name ==='plus'? 'PLUS' :
                    infoPlano.name ==='basic'? 'BASIC' :
                    infoPlano.name ==='freelancers'? 'FREELANCERS' :
                    infoPlano.name ==='agencias'? 'AGÊNCIAS' :''
                    }</p>
                    <p>{infoPlano.price_monthly !== 0?'R$':''}
                    <span className="valor-plano">
                    {infoPlano.price_monthly === 0?'Grátis': 
                    infoPlano.price_monthly_mask
                    }</span>
                    {infoPlano.price_monthly !== 0?'/mês':''}</p>
                </div>
            </div>
            <div className="meu-plano-envolve-card-data">
            {infoPlano.expires_in !== '' && infoPlano.expires_in !== null && infoPlano.expires_in !== undefined ?
                <div className="meu-plano-card-data">
                    <EventIcon className={classes.iconEvent}/>
                <div>
                    <p className="meu-plano-p-dataex">data de expiração:</p>
                    <p className="meu-plano-p-data">{FormataStringData(infoPlano.expires_in)}</p>
                    </div>
                </div>
                      :''}
            </div>
        </div>
        <div className="meu-plano-footer">
            <div>
                <p className="meu-plano-status-footer">status da assinatura</p>
                <h2 className="title-footer-plano">ATIVO</h2>
            </div>
            <div>
            {infoPlano.name !=='free' ?
            <Button color="secondary" onClick={cancelarPlano}>Cancelar Plano</Button>
            :''}
            </div>
        </div>
    </div>
    :''}
    </div>
            )):''}
    </div>
);

}
