import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './css/Login.css';
import Logo from '../../Assets/images/payhow_logo.fw.png';
import FormLogin from './components/Form/formulario';
import Btnfacebook from '../../Components/facebook';
import Btngoogle from '../../Components/google';
import Termos from '../../Assets/pdf/Termos_De_Uso.pdf';
import Politica from '../../Assets/pdf/Politica_De_Privacidade.pdf';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,   
  }
}));

export default function Login() {
  const classes = useStyles();
  const History = useHistory();

  const string = History.location.search;
  if (string === '?load') {
    History.push("/");
    window.location.reload();
  }
  

  return (
    <div className="login-envolve-div__login">
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} sm={6} className="login-esconde-mob">
                    <Paper className="login-leteral_image">
                      <div className="login-background_image">
                      <div className="login-image_logo">
                        <img src={Logo} alt="Logo PAYHOW" text="Logo PAYHOW"/>
                      </div>
                      </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper className="login-lateral_form">
                        <div className="login-envolve_link_form">
                        <div className="login-image_logo_mobile">
                        <img src={Logo} alt="Logo PAYHOW" text="Logo PAYHOW"/>
                      </div>
                            <div className="login-link_cadastro">
                                <p>Não tem uma conta?</p>
                                <Link to="/cadastro"><button>Cadastre-se</button></Link>
                            </div>
                            <div>
                              <h1 className="login-title">Entrar</h1>
                            </div>
                            <div>
                            <div className="cadastro-redes">      
                    <Btnfacebook/>
                    <Btngoogle/>
                </div>  
                <div className="cadastro-ou">
                    <span>ou</span>
                </div>
                            </div>
                          <div className="login-envolve_form">
                           <FormLogin/>
                          </div>
                        </div>
                        <div className="login-footer">
                          <a className="footer-link" target="_blank" href={Termos}>Termos</a>
                          <a className="footer-link" target="_blank" href={Politica}>Política de Privacidade</a>
                          <p>PAYHOW©2020</p>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    </div>
  );
}