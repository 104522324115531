import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../../Assets/images/logo.fw.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    minWidth: 300,
    
  },
  titulo:{
    display:'flex',
    justifyContent:'center',
    fontSize: 18,
    color: '#000000'
  },
  btn:{
    display: 'flex',
    justifyContent:'space-around'
  },
  
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  

  useEffect(() => {

        setOpen(props.open)
     
  },[props])
 
  const handleDelete = () => {
    props.change(true)
    props.onChange(false)
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    props.onChange(false)
    props.change(false)
  };

  return (
    <div className={classes.container}>
     
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-slide-title" className={classes.titulo}><img src={logo} /></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" className={classes.titulo}>
          Deseja excluir essa loja?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btn}>
        <Button onClick={handleClose} color="secondary" variant="contained">
            Cancelar
          </Button>
          <Button variant="contained"  onClick={handleDelete}>
              Sim, excluir
          </Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}