import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import api from '../../../Services/api';
import {useHistory} from 'react-router-dom';
import { ContactsOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    }
  }));


  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={true}
        isNumericString
         format='(##) # ####-####'
      />
    );
  }
  
  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

export default function FormMeusDados(infoUser) {

    const history = useHistory();
    const classes = useStyles();
    const [nameUser, setNameUser] = React.useState(infoUser.nameUser);
    const [emailUser, setEmailUser] = React.useState('');
    const [telUser, setTelUser] = React.useState('');
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [verificaVazioName, setVerificaVazioName] = React.useState(false);
    const [verificaVazioEmail, setVerificaVazioEmail] = React.useState(false);
    const [verificaVazioTel, setVerificaVazioTel] = React.useState(false);
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [photoUser, setPhotoUser] = React.useState(infoUser.photoUser);
    const [phoneUser, setPhoneUser] = React.useState('');

    const capturaNameUser = (prop) => (event) => {
        setNameUser(event.target.value );
        infoUser.setStateDoPapaizineo(event.target.value)   
    };
    const capturaEmailUser = (prop) => (event) => {
        setEmailUser(event.target.value );
    };
    const capturaTelUser = (prop) => (event) => {
        setTelUser(event.target.value );
        let phone = event.target.value.replace(/[^\d]+/g, '');
        setPhoneUser(phone);
    };


  useEffect(() => {
    setPhotoUser(infoUser.photoUser);
    setNameUser(infoUser.nameUser);
  },[infoUser]) 
  useEffect(() => {
    recebeMeusDados();
  },[]) 

  useEffect(() => {
    capturaNameUser()
  },[nameUser]) 


  useEffect(() => {
    setPhotoUser(infoUser.photoUser);

  },[infoUser.photoUser]) 

async function enviaFormMeusDados(event){
    event.preventDefault();
        var data = {
            "name": nameUser,
            "email": emailUser,
            "phone": phoneUser,
            "photo": photoUser
        }

        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
          if(nameUser !=='' && emailUser !=='' && telUser !==''){
        await api.put('/v1/customers/profile', data, {
            headers: headers
          })
          .then((response) => {
            setMessageSuccess('Perfil atualizado com sucesso');
            setTimeout(() => {
              // window.location.reload();
            setMessageSuccess('');
          }, 1500);
          })
          .catch((error) => {
            setMessageErro(error.response.data.errors.message);
              setTimeout(() => {
              setMessageErro('');
            }, 1500);
          })
        }else{
            if(nameUser ==='' ){
                setVerificaVazioName(true);
            } 
            if(emailUser ===''){
                setVerificaVazioEmail(true);
            }
            if(telUser ===''){
                setVerificaVazioTel(true);
            }
            return true;
        }
  }
  async function recebeMeusDados(){
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
        await api.get('/v1/customers/profile',  {
            headers: headers
          })
          .then((response) => {
           if(response.data.email !==''){
            setEmailUser(response.data.email);
           }
           if(response.data.phone !== null){
            setTelUser(response.data.phone);
            setPhoneUser(response.data.phone);
           }
           return true;
          })
  }


return (
    <div className="Apps-form-style">
    <div className="App-form-style">
    <h2 className="title-abas">Meus Dados</h2>
    <form onSubmit={enviaFormMeusDados} className="FormMeusDados" noValidate autoComplete="off">
    {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                       {messageSuccess !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                    :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                    <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="nome"
                        label="Nome"
                        type="text"
                        value={nameUser}  
                        onChange={capturaNameUser()}
                        variant="outlined" 
                        required
                        error={verificaVazioName && nameUser ==='' ? true:false}
                        helperText={verificaVazioName && nameUser ==='' ?"*Campo obrigatório":''}
                         />
                    <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="email" 
                        label="E-mail" 
                        type="email" 
                        value={emailUser} 
                        onChange={capturaEmailUser()} 
                        variant="outlined" 
                        required
                        error={verificaVazioEmail && emailUser ==='' ? true:false}
                        helperText={verificaVazioEmail && emailUser ==='' ?"*Campo obrigatório":''}
                        />
                         <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="tel" 
                        label="Celular" 
                        type="tel" 
               
                        value={telUser} 
                        onChange={capturaTelUser()} 
                        variant="outlined" 
                        required
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          }}
                        error={verificaVazioTel && telUser ==='' ? true:false}
                        helperText={verificaVazioTel && telUser ==='' ?"*Campo obrigatório":''}
                        />
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button type="submit" variant="contained" color="secondary">Salvar alterações</Button>
            </div>
        </form>
    </div>
    </div>
);
}