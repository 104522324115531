import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../../Services/api';
import FormLabel from '@material-ui/core/FormLabel';
import clsx from 'clsx';
import axios from 'axios';
import '../../../../../App/Components/Form/Forms_Apps/PicPay/css/PicPay.css';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    },
    textLeft:{
        textAlign: 'left',
    }
  }));


export default function FormCorreios() {
    const history = useHistory();
    const classes = useStyles();
    const [id, setId] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [name, setName] = React.useState('');
    const [statusApp, setStatusApp] = React.useState('');
    const [enableApp, setEnableApp] = React.useState('');
    const [checked, setChecked] = React.useState('');
    const [environment_sandbox, setEnvironmentSandbox] = React.useState('');
    const [cep, setCep] = React.useState('');
    const [uf, setUf] = React.useState('');
    const [bairro, setBairro] = React.useState('');
    const [numero, setNumero] = React.useState('');
    const [cidade, setCidade] = React.useState('');
    const [cepfsm, setCepSm] = React.useState('');
    const [complemento, setComplemento] = React.useState('');
    const [rua, setRua] = React.useState('');
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [verificaVazioCep, setVerificaVazioCep] = React.useState(false);
    const [verificaVazioUF, setVerificaVazioUF] = React.useState(false);
    const [verificaVazioBairro, setVerificaVazioBairro] = React.useState(false);
    const [verificaVazioNumero, setVerificaVazioNumero] = React.useState(false);
    const [verificaVazioRua, setVerificaVazioRua] = React.useState(false);
    const [verificaVazioCidade, setVerificaVazioCidade] = React.useState(false);

    
   function mostraStatus(){
    var alert = document.getElementById("AlertStatus");
       alert.style.display = 'block';
   }
   
   function removeStatus(){
    var alert = document.getElementById("AlertStatus");
       alert.style.display = 'none';
   }

   function checkEnable(){
    if(checked === true) {
        setChecked(false);
        setEnableApp(0);
    }
    else{
        setChecked(true);
        setEnableApp(1); 
    }
   }

  function cancelarApp(){
    history.push('/configuracoes-frete');
  }

  const capturaBairro = (prop) => (event) => {
    setBairro(event.target.value );
};

const capturaCep= (prop) => (event) => {
    setCep(event.target.value );
    let cepSm = event.target.value.replace(/\D/g, '');
    setCepSm(cepSm);
};

const capturaUF = (prop) => (event) => {
    setUf(event.target.value );
};
const capturaRua = (prop) => (event) => {
    setRua(event.target.value );
};
const capturaNumero = (prop) => (event) => {
    setNumero(event.target.value );
};
const capturaCidade = (prop) => (event) => {
    setCidade(event.target.value );
};
const capturaComplemento = (prop) => (event) => {
    setComplemento(event.target.value );
};

const [state, setState] = React.useState({
    sedex: true,
    pac: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { sedex, pac } = state;
  const error = [sedex, pac].filter((v) => v).length === 0;

async function buscaApp(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/shipping/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
     
        for(let i = 0; dados.length > i; i++){
            if(dados[i].slug === 'correios'){
                setId(dados[i].slug);
                setPhoto(dados[i].photo);
                setName(dados[i].name);
                setStatusApp(dados[i].authenticated);
                setEnableApp(dados[i].enable);
                setChecked(dados[i].enable);
                setEnvironmentSandbox(dados[i].environment_sandbox);
                buscaDadosApp(dados[i].credentials);
                if(dados[i].postal_code!==null){  
                    setCep(dados[i].postal_code_mask);
                  }
            }   
        }
    })
    .catch((error) => {

    })
}
 
  useEffect(() => {
    buscaApp();
  },[]) 

    async function enviaFormApp(event){
    event.preventDefault();

    var ceP = cep.replace(/[^\d]+/g, '');

        var pac ='';
        var sedex ='';
        var correiosType = '';

        if(state.pac){
             pac = 'pac';
        }
        if(state.sedex){
             sedex = 'sedex';
        }
        if(pac !=='' && sedex !==''){
            correiosType = [pac,sedex];
        } else if(pac !=='' && sedex ===''){
            correiosType = [pac];
        } else if(pac ==='' && sedex !==''){
            correiosType = [sedex];
        }else if(pac ==='' && sedex ===''){
            correiosType = [];
        }
        var data = {
            "enable": enableApp,
            "company_id" : idCompanies,
            "integration_slug" : "correios",
            "street" : rua,
            "number" : numero,
            "city" : cidade,
            "postal_code" : ceP,
            "neighborhood" : bairro,
            "state" : uf,
            "complement" : complemento,
            "correios_type" : correiosType
        }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
          if(pac ==='' && sedex ===''){
          }else{
          if(uf !=='' && bairro !=='' && numero !=='' && cidade  !=='' && rua !=='' && cep !==''){
        await api.post('/v1/integrations/shipping', data, {
            headers: headers
          })
          .then((response) => {
            setMessageErro('');
            setMessageSuccess('Informações salvas com sucesso');
            setTimeout(() => {
                setMessageSuccess('');
          }, 1500);
            setTimeout(() => {
            history.push('/configuracoes-frete');
        }, 2000);
          })
          .catch((error) => {
            setMessageSuccess('');
              setMessageErro('Erro ao ativar app, verifique se todas as informações solicitadas estão corretas!');
              setTimeout(() => {
                setMessageErro('');
          }, 1500);
          })
        }else{
            if(bairro ==='' ){
                setVerificaVazioBairro(true);
            } 
            if(uf ===''){
                setVerificaVazioUF(true);
            }
            if(numero ===''){
                setVerificaVazioNumero(true);
            }
            if(cidade ===''){
                setVerificaVazioCidade(true);
            }
            if(rua ===''){
                setVerificaVazioRua(true);
            }
            if(cep ===''){
                setVerificaVazioCep(true);
            }
            return true;
        }
    }
  }

  function buscaDadosApp(credentials){
   setUf(credentials.shipping.address.state);
   setBairro(credentials.shipping.address.neighborhood);
   setNumero(credentials.shipping.address.number);
   setCidade(credentials.shipping.address.city);
   setRua(credentials.shipping.address.street); 
   setComplemento(credentials.shipping.address.complement);  

   var tipoEntrega = credentials.shipping.correios_type;
   for(let i = 0; tipoEntrega.length > i; i++){
        if(tipoEntrega.length===2){
            setState({
                sedex: true,
                pac: true,
              });  
        }
        if(tipoEntrega.length===1){
       if(tipoEntrega[i] === 'pac'){
            setState({
                sedex: false,
                pac: true,
              }); 
        } else if(tipoEntrega[i] === 'sedex'){
            setState({
                sedex: true,
                pac: false,
              }); 
        }
    }
   }
 }

// via Cep
function limpa_formulário_cep() {
    //Limpa valores do formulário de cep.
    setRua('');
    setBairro('');
    setUf('');
    setCidade('');
}

function meu_callback(conteudo) {
    //Atualiza os campos com os valores.
    setRua(conteudo.data.logradouro);
    setBairro(conteudo.data.bairro);
    setUf(conteudo.data.uf);
    setCidade(conteudo.data.localidade);
} 
 
const pesquisacep= (prop) => (event) => {

//Nova variável "cep" somente com dígitos.
var cep = event.target.value.replace(/\D/g, '');

//Verifica se campo cep possui valor informado.
if (cep != "") {

    //Expressão regular para validar o CEP.
    var validacep = /^[0-9]{8}$/;

    //Valida o formato do CEP.
    if(validacep.test(cep)) {

        //Preenche os campos com "..." enquanto consulta webservice.
        setRua('...');
        setBairro('...');
        setUf('...');
        setCidade('...');

        //chama a api do via cep
        dadosViaCep();

    } //end if.
    else {
        //cep é inválido.
        limpa_formulário_cep();
       setMessageErro('Formato de CEP inválido.');
       setMessageSuccess('');
       setTimeout(() => {
        setMessageErro('');
  }, 1500);
    }
} //end if.
else {
    //cep sem valor, limpa formulário.
    limpa_formulário_cep();
}
};

async function dadosViaCep(){
   await axios.get('https://viacep.com.br/ws/'+ cepfsm + '/json')
      .then((response) => {
      meu_callback(response)
      })
      .catch((error) => {
            //CEP não Encontrado.
    limpa_formulário_cep();
    setMessageErro('CEP não encontrado.');
    setMessageSuccess('');
    setTimeout(() => {
        setMessageErro('');
  }, 1500);
      })
}

const mascaraCEP = (prop) =>  (object) => {
  
    var cnpj = object.target;
            if(cnpj.value.length == 5)
                cnpj.value = cnpj.value + '-'; 
}



return (
    <div className="Apps-form-style">
        <div className="App-suporte">
            <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft}/></a>
        </div>
    <div className="App-form-style">
    <form onSubmit={enviaFormApp} className="PicPay-form" noValidate autoComplete="off">
        <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
            <div className={checked ? "App-circle-status App-circle-status-true": "App-circle-status"} onMouseEnter={mostraStatus} onMouseOut={removeStatus}></div>
                <img className="PicPay-logo" src={photo}/>
                    <h1 className="PicPay-title">{name}</h1>
            </div>
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    onClick={checkEnable}
                    value={enableApp}
                    checked={checked}
                    control={<Switch color="secondary" />}
                    label="Ativo:"
                    labelPlacement="start"
                />
            </FormGroup>
        </div>
        <div id="AlertStatus" className="AlertStatus">
            { checked ?
             <Alert variant="filled" severity="success">
                Status: Ativo
            </Alert>
                :
            <Alert variant="filled" severity="error">
                Status: Inativo
            </Alert>
            }
        </div>
        <div className="PicPay-link-tutoriais">
        <p className="PicPay-p-form">Informações da aplicação:</p>
            <a target="_blank" href="https://blog.payhow.com.br/2021/04/13/configuracoes-de-frete-com-os-correios/">Como obter as informações do Frete Correios?</a>
        </div>
        {messageErro !== ''?
                    <Alert className="messagensFixed"  variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                     {messageSuccess!== ''?
                     <Alert className="messagensFixed"  variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                     :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                        <h4 className="mt-1 mb-1">1. Escolha o tipo de entrega:</h4>
                        <FormControl required error={error} component="fieldset" className={classes.formControl}>
                        <FormLabel className={classes.textLeft} component="legend">Selecione no mínimo uma opção</FormLabel>
                        <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={sedex} onChange={handleChange} name="sedex" />}
                            label="Sedex"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={pac} onChange={handleChange} name="pac" />}
                            label="PAC"
                        />
                 </FormGroup>
                 </FormControl>
                    <h4 className="mt-2">2. Preencha os campos com os dados correspondentes:</h4>
                    <TextField
                        className={clsx(classes.textStyle,classes.inputCep)} 
                        color="secondary" 
                        id="cep" 
                        label="CEP" 
                        type="tel" 
                        maxLength = "9"
                        inputProps ={{maxLength:9}}
                        onKeyPress={mascaraCEP()}
                        value={cep} 
                        onChange={capturaCep()} 
                        onBlur={pesquisacep()}
                        variant="outlined" 
                        required
                        error={verificaVazioCep ? true:false}
                        helperText={verificaVazioCep && cep ==='' ?"*Campo obrigatório":''}
                        helperText={verificaVazioCep && cep !=='' ?"*CEP inválido":''}
                        />
                            <TextField
                        className={clsx(classes.textStyle,classes.inputEstado)} 
                        color="secondary"
                        id="estado"
                        label="UF"
                        type="text"
                        value={uf} 
                        onChange={capturaUF}
                        variant="outlined" 
                        required
                        disabled
                        error={verificaVazioUF ? true:false}
                        helperText={verificaVazioUF && uf ==='' ?"*Campo obrigatório":''}
                         />
                            <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="bairro"
                        label="Bairro"
                        type="text"
                        value={bairro}  
                        onChange={capturaBairro()}
                        variant="outlined" 
                        required
                        error={verificaVazioBairro && bairro ==='' ? true:false}
                        helperText={verificaVazioBairro && bairro ==='' ?"*Campo obrigatório":''}
                         /> 
                                 <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="cidade"
                        label="Cidade"
                        type="text"
                        value={cidade}  
                        onChange={capturaCidade()}
                        variant="outlined" 
                        required
                        disabled = {cidade !=='' ? true:false} 
                        error={verificaVazioCidade && cidade ==='' ? true:false}
                        helperText={verificaVazioCidade && cidade ==='' ?"*Campo obrigatório":''}
                         />
                                  <TextField
                        className={clsx(classes.textStyle,classes.inputCep)} 
                        color="secondary" 
                        id="rua" 
                        label="Rua" 
                        type="text"
                        value={rua} 
                        onChange={capturaRua()} 
                        variant="outlined" 
                        required
                        error={verificaVazioRua ? true:false}
                        helperText={verificaVazioRua && rua ==='' ?"*Campo obrigatório":''}
                        />
                            <TextField
                        className={clsx(classes.textStyle,classes.inputEstado)} 
                        color="secondary"
                        id="numero"
                        label="Número"
                        type="tel"
                        value={numero} 
                        onChange={capturaNumero()}
                        variant="outlined" 
                        required
                        error={verificaVazioNumero ? true:false}
                        helperText={verificaVazioNumero && numero ==='' ?"*Campo obrigatório":''}
                         />  
                     <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="complemento"
                        label="Complemento"
                        type="text"
                        value={complemento}  
                        onChange={capturaComplemento()}
                        variant="outlined" 
                         />
                </FormGroup>
            </FormControl>
            <div className="PiPay-button">
                <Button variant="contained" onClick={cancelarApp}>Cancelar</Button>
                <Button type="submit" variant="contained" color="secondary">Salvar informações</Button>
            </div>
        </form>
    </div>
    </div>
);
}