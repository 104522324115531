import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import './css/gridConfiguracoes.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },

}));

export default function AutoGrid() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className="GridMensagem-divEnvolve">
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <div className="GridMensagem-envolve-conteudo">
              <div className="GridMensagem-envolve-title">
              <h2>Configurações</h2> 
             </div>
             <div className="GridConfiguracao-envolve-lista">
               {/* <div className="GridConfiguracao-cards">
                <StarBorder className="iconConfig"/> 
                <h4>Carrinhos abandonados</h4>
               </div>
                <div className="GridConfiguracao-cards">
                <StarBorder className="iconConfig"/> 
                <h4>Notificações automáticas</h4>
               </div>
                <div className="GridConfiguracao-cards">
                <StarBorder className="iconConfig"/> 
                <h4>Configuração de frete</h4>
               </div> */}
               <Link to='/configuracoes-frete'>
                <div className="GridConfiguracao-cards">
                <LocalShippingIcon className="iconConfig"/> 
                <h4>Configurações de frete</h4>
               </div>
               </Link>
                {/* <div className="GridConfiguracao-cards">
                <StarBorder className="iconConfig"/> 
                <h4>Domínio</h4>
               </div> */}
             </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}