import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '@material-ui/lab/Alert';
import {useHistory} from 'react-router-dom';
import api from '../../../../Services/api';
import Modal from '../../../Cadastro/components/modal';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,   
    }
}));
export default function FormLoginRedeSocial(nome) {
    const termosRS = <span>Declaro que li e aceito os <span>Termos</span> e <span>Política de Privacidade</span></span>
    const [erroConfirma, setErroConfirma] = React.useState();
    const [errosCheck, setErrosCheck] = React.useState(false);
    const [msgErroSenha, setMsgErroSenha] = React.useState('');
    const [msgErroConfirm, setMsgErroConfirm] = React.useState('');
    const history = useHistory();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [dados,setDados] = React.useState(false);

    const [checked, setChecked] = React.useState({
        checkedA: true,
      });
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });
    const [valuesC, setValuesC] = React.useState({
        password: '',
        showPassword: false,
    });
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleChangeC = (prop) => (event) => {
        setValuesC({ ...valuesC, [prop]: event.target.value });
    };

    const handleClickShowPasswordC = () => {
        setValuesC({ ...valuesC, showPassword: !valuesC.showPassword });
    };

    const handleMouseDownPasswordC = (event) => {
        event.preventDefault();
    };

    const handleChangeChecked = (event) => {
        setChecked({ ...checked, [event.target.name]: event.target.checked });
    };
    const [errors, setErrors] = React.useState({
        check: true,
    });
    const [erroCadastroRedeSocial, setErroCadastroRedeSocial] = React.useState();
    var alert = document.getElementById('loginErroId');
    const handleSubmit = (event) => {
        event.preventDefault();

     

        if(values.password !== valuesC.password){

            setErroConfirma(true) 
            setMsgErroSenha('As senhas não são idênticas')
            setMsgErroConfirm('As senhas não são idênticas')
        }else{
            setErroConfirma(false)
        } 


        if(checked.checkedA){
            setErrosCheck(false)
        }else{

            setErrosCheck(true)

        }    
        if(values.password === valuesC.password && checked.checkedA){
        
    let campaign = history.location.search
    setErrors({ ...errors, check: true });
    if (campaign){
      let campaignPrev = campaign.indexOf("[")
      let campaignNext = campaign.indexOf("]")
      campaign = campaign.slice(campaignPrev +1, campaignNext )
    }
    let redeSocial = nome.props.login;
   
    let tokenUser = '';
    if(redeSocial === 'facebook'){
        tokenUser = nome.props.params.accessToken;
    }else{
        tokenUser = nome.props.params.accessToken;
    }
    let email = document.getElementById("emailLogin").value;
    const cadastroRedeSocial = {
        access_token: tokenUser,
        email: email,
        password: values.password,
        campaign_origin: campaign,
    }
    
      async function loadCadastroRedeSocial(){
        
          try{
            const response = await api.post('/v1/customers/register/'+redeSocial, 
            cadastroRedeSocial,
            {
              headers:{
                'Accept': 'application/json', 
                'Content-Type': 'application/json',
              }
            });
        
            alert.style.display = 'none';
            setModalOpen(true);
            setDados(response.data);
            
          }catch(error){

            alert.style.display = 'block';
            setErroCadastroRedeSocial(error.response);
          }  
      }

      loadCadastroRedeSocial()
    }

  };

    const classes = useStyles();
    // Declara objetos que vão receber valor no input email
    const disable = {};
    const email = {};
    // Valida se o retorno veio vazio para desativar o input e exibir ou não o email no input
    if(nome.props.login === 'facebook'){
        if (nome.props.email === '') {
            email.value='';
            disable.disabled = false;
        } else {
            email.value=nome.props.params.email;
        disable.disabled = true;
        }
    }else{
        if (nome.props.params.profileObj.email === '') {
            email.value='';
            disable.disabled = false;
        } else {
            email.value=nome.props.params.profileObj.email;
        disable.disabled = true;
        }
    }

    return (
        <div>
        <form className={classes.root} onSubmit={handleSubmit} autoComplete="off">
        <div id="loginErroId" className="login-erro">
        <Alert variant="filled" severity="error">
            {erroCadastroRedeSocial}
        </Alert>
        </div> 
            <TextField
                id="emailLogin" 
                label="E-mail"
                autoComplete="username"
                // Trazendo os valores para o input
               {...email}
                {...disable}
                variant="outlined" />
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" error={erroConfirma}>
            <InputLabel required htmlFor="password">Senha</InputLabel>
            <OutlinedInput
                id="password"
                autoComplete="current-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                required
                onChange={handleChange('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            />
            {erroConfirma ? <FormHelperText id="component-error-text">{msgErroSenha}</FormHelperText> : ''}
            </FormControl>
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined" error={erroConfirma}>
            <InputLabel required htmlFor="confirmaPassword">Confirmar senha</InputLabel>
            <OutlinedInput
                id="confirmaPassword"
                autoComplete="current-password"
                type={valuesC.showPassword ? 'text' : 'password'}
                value={valuesC.password}
                required
                onChange={handleChangeC('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordC}
                            onMouseDown={handleMouseDownPasswordC}
                            edge="end"
                        >
                    {valuesC.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
                
            />
            {erroConfirma ? <FormHelperText id="component-error-text">{msgErroConfirm}</FormHelperText> : ''}
            </FormControl>
            <div className="cadastro-termos cadastro-termos-redesocial">
                  <FormControlLabel
                    control={<Checkbox checked={checked.checkedA} onChange={handleChangeChecked} name="checkedA" />}
                    label={termosRS}
                  />
                  {errosCheck ? <Alert variant="outlined" severity="error">
                                          Aceite os termos para continuar!
                                </Alert> : ''
                  }
                </div>
                <div className="login-ajuste-center">
                    <button  type="submit" className="login-btn_entrar">ENTRAR</button>
                </div>
        </form>
        <Modal open={modalOpen} user={dados}/>  
        </div>
        
  );
}