import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../gridMensagem/css/gridMensagem.css';
import api from '../../Services/api';
import Load from '../Load';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },

}));

export default function AutoGrid() {
  const classes = useStyles();
  const [notificacoes, setNotificacoes] = useState('');
  const [modalNotificacoes, setModalNotificacoes] = useState(false);
  const [titleNotificacao, setTitleNotificacao] = useState('');
  const [descriptionNotificacao, setDescriptionNotificacao] = useState('');
  const [hourNotificacao, setHourNotificacao] = useState('');
  const [dateNotificacao, setDateNotificacao] = useState('');
  const [idNotificacao, setIdNotificacao] = useState();
  const [readNotificacao, setReadNotificacao] = useState();
  const token = localStorage.getItem('tokenUser');

  const abreNotificacao = (notificacao) => {
    setModalNotificacoes(true);
    setTitleNotificacao(notificacao.title);
    setDescriptionNotificacao(notificacao.description);
    setHourNotificacao(notificacao.hour);
    setDateNotificacao(notificacao.date);
    setIdNotificacao(notificacao.id);
    setReadNotificacao(notificacao.read);
  
  };

  const fechaNotificacao = (idNotificacao) => {
    setModalNotificacoes(false);
    visualizouNotificacoes(idNotificacao);
  };

  useEffect(() => {
    recarregaNotificacoes()
  },[]) 

  async function recarregaNotificacoes() {

    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": 'Bearer'+token
      }
      await api.get('/v1/notifications', {
        headers: headers
      })
      .then((response) => {
        var listNotificacoes = response.data.general;
        setNotificacoes(listNotificacoes);
      })
  }

  async function visualizouNotificacoes(idNotificacao) {
    var data = {
      'read': 1
      }
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": 'Bearer'+token
      }
      await api.put('/v1/notifications/' + idNotificacao, data, {
        headers: headers
      })
      .then((response) => {
        recarregaNotificacoes();
      })
  }
  return (
    <div className={classes.root}>
      <div className="GridMensagem-divEnvolve">
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
          {notificacoes!==''?
            <div className="GridMensagem-envolve-conteudo">
            {notificacoes===null?<Load/>:''} 
              <div className="GridMensagem-envolve-title">
              {notificacoes.length === 0?<h2>Você não possui notificações</h2>:<h2>Notificações</h2> }
             </div>
             <div className="GridMensagem-envolve-lista">
               { notificacoes.map((notificacao) => (
               <div key={notificacao.id} id={notificacao.id} className="GridMensagem-lista" onClick={()=>abreNotificacao(notificacao)}>
                 {notificacao.read==1 ?
                 <div read = {notificacao.read} className="GridMensagem-circle"></div>
                 :
                 <div read = {notificacao.read} className="GridMensagem-circle active"></div>
                  }
                 <div className="GridMensagem-envolv-text">
                   <h3 dangerouslySetInnerHTML={{ __html:notificacao.title.length > 150 ? notificacao.title.substring(0,55) + '...' : notificacao.title}}/>
                 <p dangerouslySetInnerHTML={{ __html:notificacao.description.length > 150 ? notificacao.description.substring(0,150) + '...' : notificacao.description}}/>
                 <p className="GridMensagem-date">{notificacao.date} às {notificacao.hour}</p>
                 </div>
               </div>
               ))}
                 <Dialog
                    open={modalNotificacoes}
                    keepMounted
                    id={idNotificacao}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <p className="GridMensagem-date modal"></p>
                    <DialogTitle>{titleNotificacao}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description"  dangerouslySetInnerHTML={{ __html:descriptionNotificacao}}>
                      </DialogContentText>
                      <DialogContentText className="GridMensagem-date" id="alert-dialog-description-date">
                        {dateNotificacao} às {hourNotificacao}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={()=>fechaNotificacao(idNotificacao)} color="secondary">
                        Fechar
                      </Button>
                    </DialogActions>
                  </Dialog>
             </div>
            </div>
            :''} 
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}