import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../../Services/api';
import Loading from '../../../../../../Components/Load';
import InputAdornment from '@material-ui/core/InputAdornment';
import ModalIntNaoDisp from '../ModalIntNaoDisp';
import ModalDeleteDNS from './ModalDeleteDNS';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import '../PicPay/css/PicPay.css';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import {useHistory} from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CachedIcon from '@material-ui/icons/Cached';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
        width: '100%'
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    }
  }));

export default function FormMercadoPago(params) {
    const history = useHistory();
    const classes = useStyles();
    const [photo, setPhoto] = React.useState('');
    const [name, setName] = React.useState('');
    const [statusApp, setStatusApp] = React.useState('');
    const [enableApp, setEnableApp] = React.useState('');
    const [checked, setChecked] = React.useState('');
    const [consumerKey, setConsumerKey] = React.useState('');
    const [consumerSecret, setConsumerSecret] = React.useState('');
    const [urlObrigado, setUrlObrigado] = React.useState('');
    const [urlEcommerce, setUrlEcommerce] = React.useState('');
    const [urlCarrinho, setUrlCarrinho] = React.useState('');
    const [dnsTransparente, setDnsTransparente] = React.useState('');
    const [environment, setEnvironment] = React.useState('');
    const [environment_sandbox, setEnvironmentSandbox] = React.useState('');
    const idCompanies = localStorage.getItem('companiesId');
    const token = localStorage.getItem('tokenUser');
    const [verificaVazioToken, setVerificaVazioToken] = React.useState(false);
    const [verificaVazioSaller, setVerificaVazioSaller] = React.useState(false);
    const [verificaVazioUrlCarrinho, setVerificaVazioUrlCarrinho] = React.useState(false);
    const [verificaVazioUrlObrigado, setVerificaVazioUrlObrigado] = React.useState(false);
    const [verificaVazioUrlEcommerce, setVerificaVazioUrlEcommerce] = React.useState(false);
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [typeAmbient, setTypeAmbient] = React.useState('');
    const [messageModal, setMessageModal] = React.useState('');
    const [Load, setLoad] = React.useState(false);
    const [modal, setModal] = React.useState(false);
    const [vazio, setVazio] = React.useState(false);
    const [loadDNS, setLoadDNS] = React.useState(false);
    const [loadGetDNS, setLoadGetDNS] = React.useState(false);
    const [valueUrlDNS, setValueUrlDNS] = React.useState('');
    const [verificaDNS, setVerificaDNS] = React.useState(false);
    const [modalDelete, setModalDelete] = React.useState(false);

    const capturaConsumerKey = (prop) => (event) => {
        setConsumerKey(event.target.value );
    };

    const capturaConsumerSecret = (prop) => (event) => {
        setConsumerSecret(event.target.value );
    };

    const capturaUrlCarrinho = (prop) => (event) => {
        setUrlCarrinho(event.target.value );
    };
    const capturaUrlEcommerce = (prop) => (event) => {
        setUrlEcommerce(event.target.value );
    };
    const capturaUrlObrigado = (prop) => (event) => {
        setUrlObrigado(event.target.value );
    };
    const capturaDnsTransparente = (prop) => (event) => {
        setDnsTransparente(event.target.value );
    };

    const openModal = () => {
        setModal(true)
    }
    const changeModal = (modal) => {
        setModal(modal)
    }

    const changeModalDNS = (value) => {
        if(value){
            deletaDNST()
        }else{
            setModalDelete(false)
        }
    }

   function checkEnable(){
    if(checked === true) {
        setChecked(false);
        setEnableApp(0);
    }
    else{
        setChecked(true);
        setEnableApp(1); 
    }
   }

   function clickRadio(radio){
    if(radio ==='dev'){
        setEnvironment('development');
        setTypeAmbient('(Teste)');
    }else{
        setEnvironment('production');
        setTypeAmbient('(Produção)');
    }
   }
  function cancelarApp(){
    history.push('/apps');
  }

async function buscaApp(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/company/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
     
        for(let i = 0; dados.length > i; i++){
            if(dados[i].name === 'WooCommerce'){
                setPhoto(dados[i].photo);
                setName(dados[i].name);
                setStatusApp(dados[i].authenticated);
                setEnableApp(dados[i].enable);
                setChecked(dados[i].enable);
                if(!dados[i].environment){
                    setEnvironment('production');
                }else{
                    setEnvironment(dados[i].environment);
                }
                setEnvironmentSandbox(dados[i].environment_sandbox);
                buscaDadosApp(dados[i].id);
            }
              
        }
    })
    .catch((error) => {

    })
}
 
  useEffect(() => {
    buscaApp();
    buscarDadosDNST()
  },[]) 

    async function enviaFormApp(event){
        setLoad(true);
    event.preventDefault();

    let urlEcommerceHttp = '';
    let urlObrigadoHttp = '';
    let urlCarrinhoHttp = '';

    if(!urlEcommerce.includes('https://')){
        urlEcommerceHttp = `https://${urlEcommerce}`;
    }else{
        urlEcommerceHttp = urlEcommerce;
    }

    if(!urlObrigado.includes('https://')){
        urlObrigadoHttp = `https://${urlObrigado}`;
    }else{
        urlObrigadoHttp = urlObrigado;
    }

    if(!urlCarrinho.includes('https://')){
        urlCarrinhoHttp = `https://${urlCarrinho}`;
    }else{
        urlCarrinhoHttp = urlCarrinho;
    }


        var data = {
            "company_id": idCompanies,
            "name" : "Woocommerce loja 1",
            "enable": checked,
            "consumer_secret": consumerSecret,
            "consumer_key": consumerKey,
        	"merchant_url" : urlEcommerceHttp,
  	        "merchant_url_obrigado" : urlObrigadoHttp,
	        "merchant_url_cart" : urlCarrinhoHttp,
            "configs" : {
                "modo": "sem teste",
                "cart_enable" : 1,
                "redirect_url" : "https://woocommerce.payhow.com.br?ref=payhow"
            }
        }
        
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
          if(consumerKey !=='' && consumerSecret !=='' && urlCarrinho !=='' && urlEcommerce !=='' && urlObrigado !==''){
        await api.post('/v1/integrations/ecommerce/woocommerce', data, {
            headers: headers
          })
          .then((response) => {
            setLoad(false);
            setMessageErro('');
            setMessageSuccess('Informações salvas com sucesso');
           setTimeout(() => {
                setMessageSuccess('');
          }, 1500);
          setTimeout(() => {
            history.push('/apps');
        }, 2000);
          })
          .catch((error) => {
            openModal();
            setMessageModal(error.response.data.errors.message);
            setLoad(false);
          })
        }else{
            setLoad(false);
            if(consumerSecret ==='' ){
                setVerificaVazioToken(true);
            } 
            if(consumerKey ===''){
                setVerificaVazioSaller(true);
            }
            if(urlCarrinho ===''){
                setVerificaVazioUrlCarrinho(true);
            }
            if(urlObrigado ===''){
                setVerificaVazioUrlObrigado(true);
            }
            if(urlEcommerce ===''){
                setVerificaVazioUrlEcommerce(true);
            }

            return true;
        }
  }

  async function enviaDNST(){
      setLoadDNS(true)
    var data = {
        "company_id" : idCompanies,
        "transparent_dns" : dnsTransparente
    }
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
      }
    await api.post('/v1/integrations/ecommerce/woocommerce/gocache/create', data, {
        headers: headers
      })
      .then((response) => {
        setLoadDNS(false)
        if(response.data.status==false){
            setLoad(false);
            setMessageErro(response.data.error);
           setTimeout(() => {
                setMessageErro('');
          }, 2500);
        }else if(response.data==''){
            setLoad(false);
            setMessageErro('Ocorreu um erro ao verificar o domínio. Verifique se o domínio é válido!');
           setTimeout(() => {
                setMessageErro('');
          }, 2500);
        }else{
            window.location.reload()
        }
      })
      .catch((error) => {
        setLoad(false);
        setMessageErro('Ocorreu um erro ao verificar o domínio. Tente novamente!');
       setTimeout(() => {
            setMessageErro('');
      }, 2500);
      })
}

async function deletaDNST(){
    setLoad(true)
  const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
  await api.delete(`/v1/integrations/ecommerce/woocommerce/gocache/delete/${idCompanies}`, {
      headers: headers
    })
    .then((response) => {
        setLoad(false)
        setModalDelete(false)
        setMessageSuccess('Domínio deletado com sucesso!');
        setTimeout(() => {
             setMessageSuccess('');
             window.location.reload()
       }, 2500);
        
    })
    .catch((error) => {
        setLoad(false)
      setMessageErro('Erro ao deletar, tente novamente!');
     setTimeout(() => {
          setMessageErro('');
    }, 2500);
    })
}

async function buscarDadosDNST(){
    setLoadGetDNS(true)
  const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
  await api.get(`/v1/integrations/ecommerce/woocommerce/gocache/status/${idCompanies}`, {
      headers: headers
    })
    .then((response) => {
        setLoadGetDNS(false)
        setVerificaDNS(response.data.status)
        setMessageSuccess('Status do domínio atualizado com sucesso');
       setTimeout(() => {
            setMessageSuccess('');
      }, 2500);
    })
    .catch((error) => {
        setLoadGetDNS(false)
            setMessageErro('Erro ao atualizar o status do domínio');
           setTimeout(() => {
                setMessageErro('');
          }, 2500);
    })
}


  async function buscaDadosApp(idApp){
      if(environment==='development'){
        setTypeAmbient('(Teste)');
      }else{
        setTypeAmbient('(Produção)');
      }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
        await api.get('/v1/integrations/ecommerce/woocommerce/' + idApp + "/" + idCompanies,  {
            headers: headers
          })
          .then((response) => {
              if(!response.data){
                setVazio(true)
              }else{
                setVazio(false)  
              }
            if(response.data.consumer_key !==''){
                setConsumerKey(response.data.consumer_key);
            }
            if(response.data.consumer_secret !==''){
                setConsumerSecret(response.data.consumer_secret);
            }
            if(response.data.merchant_url !==''){
                setUrlEcommerce(response.data.merchant_url);
            }
            if(response.data.merchant_url_cart !==''){
                setUrlCarrinho(response.data.merchant_url_cart);
            }
            if(response.data.merchant_url_obrigado !==''){
                setUrlObrigado(response.data.merchant_url_obrigado);
            }
            if(response.data.transparent_dns !==''){
                setDnsTransparente(response.data.transparent_dns)
            }
            if(response.data.transparent_dns_gocache !==''){
                setValueUrlDNS(response.data.transparent_dns_gocache)
            }
            return true;
          })
          .catch((error) => {
              setMessageErro(error.response.data.errors.message);
             setTimeout(() => {
                setMessageErro('');
          }, 1500);
          })
  }


return (
    <div className="Apps-form-style">
        <div className="App-suporte">
            <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft}/></a>
        </div>
    <div className="App-form-style">
    <form onSubmit={enviaFormApp} className="PicPay-form" noValidate autoComplete="off">
        <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
            <div className={checked ? "App-circle-status App-circle-status-true": "App-circle-status"}></div>
                <img className="PicPay-logo" src={photo}/>
                    <h1 className="PicPay-title">{name}</h1>
            </div>
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    onClick={checkEnable}
                    value={enableApp}
                    checked={checked}
                    control={<Switch color="secondary" />}
                    label="Ativo:"
                    labelPlacement="start"
                />
            </FormGroup>
        </div>
        <div className="esonde-pagamento">
        <div id="AlertStatus" className="AlertStatus">
            { statusApp ?
             <Alert variant="filled" severity="success">
                Status: Online, conectado com a empresa de integração
            </Alert>
                :
            <Alert variant="filled" severity="error">
                Status: Offline, desconectado.
                Talvez alguma informação esteja incorreta, verifique os campos preenchidos
            </Alert>
            }
        </div>
        <div className="PicPay-link-tutoriais">
        <p className="PicPay-p-form">Informações da aplicação:</p>
            <a target="_blank" href="https://blog.payhow.com.br/2021/05/07/baixando-plugin-e-integrando-com-a-sua-loja-no-woocommerce/">Como obter as informações do Woocommerce?</a>
        </div>
        {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                     {messageSuccess!== ''?
                     <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                     :''}
            <FormControl className={classes.root} component="fieldset">
                <FormGroup aria-label="position" row>
                    <div className="PicPay-envolve-radio-teste-prod">
                        <h4 className="mt-1">1. Escolha o ambiente que sua aplicação irá utilizar:</h4>
                    <RadioGroup  aria-label="position" name="position" defaultValue="top">
                    {environment_sandbox === 1?
                        <FormControlLabel
                            onClick={()=>clickRadio('dev')}
                            name="environment"
                            checked={environment === 'development' ? true : false}
                            value="teste" control={<Radio color="secondary" />} 
                            label="Teste"
                        />
                        :''}
                        <FormControlLabel 
                            onClick={()=>clickRadio('prod')}
                            name="environment"
                            checked={environment === 'production' ? true : false} 
                            value="producao" 
                            control={<Radio color="secondary" />} 
                            label="Produção" 
                        />
                    </RadioGroup>
                    </div>
                    <h4 className="mt-2">2. Preencha os campos com os dados correspondentes:</h4>
                    <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="consumerKey" 
                        label={`Consumer Key ${typeAmbient}`} 
                        type="text" 
                        value={consumerKey} 
                        onChange={capturaConsumerKey()} 
                        variant="outlined" 
                        required
                        error={verificaVazioSaller && consumerKey ==='' ? true:false}
                        helperText={verificaVazioSaller && consumerKey ==='' ?"*Campo obrigatório":''}
                        />
                    <TextField
                        className={classes.textStyle}
                        color="secondary"
                        id="consumerSecret"
                        label={`Consumer Secret ${typeAmbient}`}
                        type="text"
                        value={consumerSecret}  
                        onChange={capturaConsumerSecret()}
                        variant="outlined" 
                        required
                        error={verificaVazioToken && consumerSecret ==='' ? true:false}
                        helperText={verificaVazioToken && consumerSecret ==='' ?"*Campo obrigatório":''}
                         />
                    <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="urlEcommerce" 
                        label={`URL do ecommerce ${typeAmbient}`} 
                        type="text" 
                        value={urlEcommerce} 
                        onChange={capturaUrlEcommerce()} 
                        variant="outlined" 
                        required
                        error={verificaVazioUrlEcommerce && urlEcommerce ==='' ? true:false}
                        helperText={verificaVazioUrlEcommerce && urlEcommerce ==='' ?"*Campo obrigatório":''}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{urlEcommerce ===''? 'https://' : '' }</InputAdornment>,
                        }}
                    />
                    <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="urlObrigado" 
                        label={`URL da página de obrigado ${typeAmbient}`} 
                        type="text" 
                        value={urlObrigado} 
                        onChange={capturaUrlObrigado()} 
                        variant="outlined" 
                        required
                        error={verificaVazioUrlObrigado && urlObrigado ==='' ? true:false}
                        helperText={verificaVazioUrlObrigado && urlObrigado ==='' ?"*Campo obrigatório":''}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{urlObrigado ===''? 'https://' : '' }</InputAdornment>,
                        }}
                    />
                    <TextField
                        className={classes.textStyle} 
                        color="secondary" 
                        id="urlCarrinho" 
                        label={`URL do carrinho ${typeAmbient}`} 
                        type="text" 
                        value={urlCarrinho} 
                        onChange={capturaUrlCarrinho()} 
                        variant="outlined" 
                        required
                        error={verificaVazioUrlCarrinho && urlCarrinho ==='' ? true:false}
                        helperText={verificaVazioUrlCarrinho && urlCarrinho ==='' ?"*Campo obrigatório":''}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{urlCarrinho ===''? 'https://' : '' }</InputAdornment>,
                        }}
                    />
                    <h4 className="mt-2">3. Utilizar checkout em modo DNS transparente:</h4>
                    <FormControl color="secondary" className={clsx(classes.margin, classes.textStyle)} variant="outlined">
                    <InputLabel htmlFor="dnsTransparente">{`URL do checkout ${typeAmbient}`}</InputLabel>
                    <OutlinedInput
                        id="dnsTransparente"
                        type='text'
                        value={dnsTransparente} 
                        disabled={valueUrlDNS ? true : false}
                        color="secondary" 
                        onChange={capturaDnsTransparente()} 
                        startAdornment={
                            <InputAdornment position="start">
                                {dnsTransparente ===''? 'https://' : '' }
                            </InputAdornment>
                            }
                        endAdornment={
                        <InputAdornment position="end">
                            <Button variant="contained" onClick={enviaDNST} disabled={valueUrlDNS || vazio ? true : false} color="secondary" >{loadDNS?<Loading/>:'Verificar domínio'}</Button>
                        </InputAdornment>
                        }
                        labelWidth={210}
                    />
                    </FormControl>
                    {valueUrlDNS ?
                        <div className="text-left">
                        <h4 className="mt-1 mb-0">Verificação de domínio:</h4>
                            <div className="div-dns-transparente-pp">
                                <div className={!verificaDNS ? "dns-transparente-pp-false": "dns-transparente-pp-true"}><FiberManualRecordIcon/> <p className="mb-0">{valueUrlDNS} {!verificaDNS ?'(Em propagação...)':'(Pronto para uso)'}</p></div>
                                <IconButton className="btn-busca-dados" onClick={buscarDadosDNST}>{!loadGetDNS?<CachedIcon/>:<Loading/>}</IconButton>
                                <IconButton onClick={()=>setModalDelete(true)}><DeleteIcon/></IconButton>
                            </div>
                            <p className="prpagacao-dv">Propagação de DNS podem demorar até 48 horas</p>
                            <div className="link-duvidas">
                                <p>Tem dúvidas em como criar as configurações para utilizar o DNS transparente?</p>
                                <a className="" target="_blank" href="https://blog.payhow.com.br/2021/04/27/dns-transparente/">Clique aqui</a>
                            </div>
                        </div>
                    :''}
                </FormGroup>
                <a className="link-plugin-woocommerce" href="https://payhow.com.br/plugins/payhow-woocommerce-plugin.zip"> <GetAppIcon/> Download do plugin Woocommerce</a>
            </FormControl>
            <div className="PiPay-button">
                <Button variant="contained" onClick={cancelarApp}>Cancelar</Button>
                {!Load ?
                <Button type="submit" variant="contained" color="secondary">Salvar informações</Button>
                :
                <Button type="submit" variant="contained" color="secondary"><Loading/></Button>
                }
               </div>
            </div>
        </form>
    </div>
    <ModalIntNaoDisp open={modal} onChange={changeModal.bind(modal)} message={messageModal}/>
    <ModalDeleteDNS open={modalDelete} onChangeDNS={changeModalDNS.bind(modal)} load={Load}/>
    </div>
);
}