import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../../Services/api';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../PicPay/css/PicPay.css';
import Loading from '../../../../../../Components/Load';
import Logo from '../../../../../../Assets/images/logo.fw.svg';
import LogoAme from '../../../../../../Assets/images/pay/ame.png';
import LogoPicPay from '../../../../../../Assets/images/pay/picpay.png';
import LogoBoleto from '../../../../../../Assets/images/pay/boleto.png';
import LogoCartao from '../../../../../../Assets/images/pay/cartao.png';
import LogoMercadoPago from '../../../../../../Assets/images/pay/mercado_pago.png';
import LogoPayPal from '../../../../../../Assets/images/pay/paypal.png';
import {useHistory} from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(3),
        width: '100%',
        marginLeft:0,
        marginRight:0,
      },
    },
    textStyle: {
        marginTop: '1rem',
    },
    iconLeft:{
        width: '11px',
        height: '11px',
    }
  }));

export default function FormMercadoPago() {
    const history = useHistory();
    const classes = useStyles();
    const [id, setId] = React.useState('');
    const [photo, setPhoto] = React.useState('');
    const [name, setName] = React.useState('');
    const [enableApp, setEnableApp] = React.useState('');
    const [checked, setChecked] = React.useState('');
    const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
    const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
    const [messageErro, setMessageErro] = React.useState('');
    const [messageSuccess, setMessageSuccess] = React.useState('');
    const [Load, setLoad] = React.useState(false);
    const [integracoesAtivas, setIntegracoesAtivas] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);

   function checkEnable(){
    if(checked === true) {
        setChecked(false);
        setEnableApp('manual');
    }
    else{
        setChecked(true);
        setEnableApp('auto'); 
    }
   }

  function cancelarApp(){
    history.push('/apps');
  }

async function buscaApp(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/mode/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
                setEnableApp(dados.integration_mode);
                if(dados.integration_mode ==='auto'){
                    setChecked(true);
                }else{
                    setChecked(false);
                }
    })
    .catch((error) => {

    })
}


const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setMessage('Você já possui outros apps de pagamento ativos no momento. Ativando este app os apps de pagamentos serão atualizados para esta nova integração, deseja continuar?');
  };
  
  const handleOpenFalse = () => {
    setOpen(true);
    setMessage('Desativando este App você estará sem nenhum forma de pagamento ativa, para reativar as formas de pagamento novamente será necessário ativar os Apps de pagamento. Deseja realmente continuar?');
  };
 
async function buscaDadosApp(){
    const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/company/' + idCompanies,  {
        headers: headers
    })
    .then((response) => {
        var dados = response.data;
        for(let i = 0; dados.length > i; i++){
            if(dados[i].enable === 1){
                setIntegracoesAtivas(dados[i].name);
            }
            if(dados[i].id === 38){
                setId(dados[i].id);
                setPhoto(dados[i].photo);
                setName(dados[i].name);
            }    
        }
    })
    .catch((error) => {
    })
}

  useEffect(() => {
    buscaApp();
    buscaDadosApp();
  },[]) 

    async function postAPI(){
        setOpen(false);
        setLoad(true);
        var data = {
            "company_id": idCompanies,
            "integration_mode": enableApp,
            "enable": checked
        }
        const headers = { 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer' + token,
          }
        
        await api.post('/v1/integrations/payhow/v2', data, {
            headers: headers
          })
          .then((response) => {
            setLoad(false);
            setMessageErro('');
            setMessageSuccess('Informações salvas com sucesso');
           setTimeout(() => {
                setMessageSuccess('');
          }, 1500);
            setTimeout(() => {
            history.push('/apps');
        }, 2000);
          })
          .catch((error) => {
            setLoad(false);
            setMessageSuccess('');
              setMessageErro(error.response.data.errors.message);
             setTimeout(() => {
                setMessageErro('');
          }, 1500);
          })
    }

return (
    <div className="Apps-form-style">
        <div className="App-suporte">
            <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft}/></a>
        </div>
    <div className="App-form-style">
    <form className="PicPay-form" noValidate autoComplete="off">
        <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
            <div className={checked ? "App-circle-status App-circle-status-true": "App-circle-status"}></div>
                <img className="PicPay-logo payhow-logo" src={photo}/>
                    <h1 className="PicPay-title">{name}</h1>
            </div>
                <FormGroup aria-label="position" row>
                    <FormControlLabel
                        onClick={checkEnable}
                        value={enableApp}
                        checked={checked}
                        label="Ativo:"
                        control={<Switch color="secondary" />}
                        labelPlacement="start"
                    />
                </FormGroup>
        </div>
        {/* <div id="AlertStatus" className="AlertStatus">
            { statusApp ?
             <Alert variant="filled" severity="success">
                Status: Online, conectado com a empresa de integração
            </Alert>
                :
            <Alert variant="filled" severity="error">
                Status: Offline, desconectado.
                Talvez alguma informação esteja incorreta, verifique os campos preenchidos
            </Alert>
            }
        </div> */}
        <div className="PicPay-link-tutoriais">
        <p className="PicPay-p-form">Informações da aplicação:</p>
            <a target="_blank" href="https://app.payhow.lionsoft.com.br/tutoriais">Como funciona a intregração automática PAYHOW?</a>
        </div>
        {messageErro !== ''?
                    <Alert className="messagensFixed" variant="filled" severity="error">
                        {messageErro}
                    </Alert>
                    :''}
                     {messageSuccess!== ''?
                     <Alert className="messagensFixed" variant="filled" severity="success">
                        {messageSuccess}
                    </Alert>
                     :''}
            <div className="div-conteudo-payhow">
                <h3>Benefícios de utilizar o PAYHOW Pagamentos</h3>
                <p>Utilizando o App da PAYHOW você ativa em sua loja virtual automaticamente mais de 6 formas de pagamento diferentes e sem a necessidade de cadastro, nós cuidaremos dos recebimentos para você. Basta ativar o App e quando quiser solicitar o saque das suas vendas através do painel "Meu Perfil".</p>
                <div className="div-conteudo-img-payhow">
                    <img src={LogoAme}/>
                    <img src={LogoPicPay}/>
                    <img src={LogoMercadoPago}/>
                    <img src={LogoPayPal}/>
                    <img src={LogoCartao}/>
                    <img src={LogoBoleto}/>
                </div>
            </div>
            <div className="PiPay-button">
                <Button variant="contained" onClick={cancelarApp}>Cancelar</Button>
                
                {!Load ?
                <Button type="button" onClick={ checked ? handleOpen : handleOpenFalse }  variant="contained" color="secondary">{!checked ?"Ativar integração manual":"Ativar integração automática"}</Button>
                :
                <Button type="button" variant="contained" color="secondary"><Loading/></Button>
                }
            </div>
        </form>
    </div>
    {/* Modal aviso integração payhow */}
    <div>
      <Dialog
      className="modal-app"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"><img src={Logo}/></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} >
            Não
          </Button>
          <Button onClick={postAPI} variant="contained" color="secondary">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </div>
);
}