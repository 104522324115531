import React from 'react';
import 'antd/dist/antd.css';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import api from '../../../Services/api';
import '../css/index.css'



function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}


class PicturesWall extends React.Component {

  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    imagem:'',
    fileList: [
     
    ],
    editList:'',
  };


    constructor(props){
      super(props)
      
    }

   componentDidMount(){

    const params = this.props.params
   
    async function buscar(){   
      const token = localStorage.getItem('tokenUser')

      const headers = { 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": 'Bearer'+token
      }
      await api.get('/v1/customers/profile', {
        headers: headers
      })
      .then((response) => {
    
        listar(localStorage.getItem('companiesId'))
      
       
        })
        .catch((error) => {
        
        
        })
      }
      buscar()
      const att = this
      async function listar(id){   
        const token = localStorage.getItem('tokenUser')
        

        const headers = { 
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          "Authorization": 'Bearer'+token
        }
        await api.get(`/v1/company/${id}/payment_link/${params.id}`, {
          headers: headers
        })
        .then((response) => {
         
        if(response.data.photo){  
          
       
         const imagem = [{
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: response.data.photo,
        }]
  
          att.setState({fileList: imagem})
        }  
          
         
          })
          .catch((error) => {
           
          
          })
        }

        


   }


  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  handleChange = ({ fileList }) => {
    this.setState({ fileList })
    this.handleChangePai()
  } ;

  handleChangePai = () => {
    const {fileList} = this.state
   
    if(fileList[0]){
    this.props.onChange(fileList[0].thumbUrl)
    }
    
  }

  render() {
    
    const { previewVisible, previewImage, fileList, previewTitle, editList } = this.state;
  
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        <h2>Adicionar imagem</h2>
        <div className="imagens-edit">
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
        
        </div>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
       
      </>
    );
  }
}

export default PicturesWall;