import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AppPicPay from './Forms_Apps/PicPay/picpay';
import AppMercadoPgo from './Forms_Apps/MercadoPago/MercadoPago';
import AppAme from './Forms_Apps/Ame/Ame';
import AppPayPal from './Forms_Apps/PayPal/PayPal';
import AppWoocommerce from './Forms_Apps/Woocommerce/Woocommerce';
import AppCartao from './Forms_Apps/Cartao/Cartao';
import AppBoleto from './Forms_Apps/Boleto/Boleto';
import AppPix from './Forms_Apps/Pix/Pix';
import AppGoogleAnalytics from './Forms_Apps/GoogleAnalytics/GoogleAnalytics';
import AppFacebookPix from './Forms_Apps/FacebookPixel/FacebookPix';
import AppPayhow from './Forms_Apps/Payhow/Payhow';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
}));

export default function FormApp(id) {
  const classes = useStyles();
  const [idApp, setIdApp] = React.useState('');
  
  useEffect(() => {
 var infoApp = localStorage.getItem('idApp');
 var idAplicativos = JSON.parse(infoApp);
 setIdApp(idAplicativos.name);
},[]) 
const alteraId = (value) => {
  setIdApp(value);
};
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
            <Paper className={classes.paper}>
              { idApp === 'Picpay' ? <AppPicPay atualizaId={alteraId}/> 
              : idApp === 'Mercado Pago' ? <AppMercadoPgo atualizaId={alteraId}/>
              : idApp === 'Ame' ? <AppAme atualizaId={alteraId} />
              : idApp === 'Paypal Express' ? <AppPayPal atualizaId={alteraId}/>
              : idApp === 'WooCommerce' ? <AppWoocommerce atualizaId={alteraId}/>
              : idApp === 'Facebook Pixel' ? <AppFacebookPix atualizaId={alteraId}/>
              : idApp === 'Google Analytics' ? <AppGoogleAnalytics atualizaId={alteraId}/>
              : idApp === 'Cartão de crédito' ? <AppCartao atualizaId={alteraId}/>
              : idApp === 'Boleto Bancário' ? <AppBoleto atualizaId={alteraId}/>
              : idApp === 'PIX' ? <AppPix atualizaId={alteraId}/>
              : idApp === 'PAYHOW Pagamentos' ? <AppPayhow />
              :'Ainda não possui formulário!'}
            </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
   
    </div>
  );
}