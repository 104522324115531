import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useHistory, useLocation } from "react-router-dom";
import ImgPedidos from "../../../Assets/images/pedidos.png";
import "../css/Pedidos.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    minHeight: "100vh",
    backgroundColor: "#F3F3F3",
  },
}));

export default function InfoPedidos() {
  const classes = useStyles();
  const location = useLocation();
  const [comprador, setComprador] = React.useState("");
  const [data, setData] = React.useState("");
  const [lastUpdated, setLastUpdated] = React.useState("");
  const [lastUpdatedHora, setLastUpdatedHora] = React.useState("");
  const [loja, setLoja] = React.useState("");
  const [valor, setValor] = React.useState("");
  const [formaPagamento, setFormaPagamento] = React.useState("");
  const [hora, setHora] = React.useState("");
  const [plataforma, setPlataforma] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [codigoPedido, setCodigoPedido] = React.useState("");
  const [codigoParceiro, setCodigoParceiro] = React.useState("");
  const [telefone, setTelefone] = React.useState("");
  const info = location?.state?.data;

  function setFields() {
    setComprador(info?.comprador);
    setCodigoPedido(info?.codigo);
    setCodigoParceiro(info?.codigo_parceiro);
    setData(info?.data);
    setLastUpdated(info?.data_aprovacao);
    setLastUpdatedHora(info?.hora_aprovacao);
    setFormaPagamento(info?.forma_pagamento);
    setHora(info?.hora);
    setLoja(info?.loja);
    setPlataforma(info?.plataforma);
    setTelefone(info?.telefone);
    setValor(info?.valor);
    setEmail(info?.email);
  }

  useEffect(() => {
    setFields()
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <div className="informacoesPedido-envolveTitle">
              <div style={{width: '100%', display: 'flex', flexDirection: "column", textAlign: 'center', alignItems: 'center', justifyContent:'center'}}>

              <h1 style={{textAlign: 'center'}}>Informações do Pedido: </h1><h2 style={{textAlign: 'center'}}>{codigoPedido}</h2>
              </div>

              <div className="info-pedidos informacoesPedidos-envolveDados">
                <div className="informacoesPedidos-dados">
                  <p>
                    Código do pedido: <span>{codigoPedido}</span>
                  </p>
                  <p>
                    Loja: <span>{loja}</span>
                  </p>
                  <p>
                    Data e hora que foi gerado:{" "}
                    <span>
                      {data} às {hora}
                    </span>
                  </p>
                  <p>
                    Data e hora da ultima atualização:{" "}
                    <span>
                      {lastUpdated} às {lastUpdatedHora}
                    </span>
                  </p>
                  <p>
                    Valor: <span>{valor}</span>
                  </p>
                  <p>
                    Forma de pagamento: <span>{formaPagamento}</span>
                  </p>
                  <p>
                    Nome do comprador: <span>{comprador}</span>
                  </p>
                  <p>
                    Telefone: <span>{telefone}</span>
                  </p>
                  <p>
                    E-mail: <span>{email}</span>
                  </p>
                  <p>
                    Plataforma do E-commerce: <span>{plataforma}</span>
                  </p>
                  <p>
                    Código do parceiro: <span>{codigoParceiro}</span>
                  </p>
                </div>
                <div>
                  <img src={ImgPedidos} />
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
    </div>
  );
}
