import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import './css/gridHome.css';
import api from '../../Services/api';
import ImageBoasVindas from '../../Assets/images/bemvindo.png';
import ExtensionOutlinedIcon from '@material-ui/icons/ExtensionOutlined';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
     
  },
  paper: {
    padding: theme.spacing(2),
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  btnApps:{
    width: "30%",
    height: '45px',
    marginLeft: '2rem',
    marginTop: '.7rem',
  }, iconApp:{
    marginRight: "1rem",
    color: "#f50057",
    width: "2rem",
    height: "2rem"
}
}));

export default function Home() {
  const History = useHistory();
  const classes = useStyles();
  const [nome,setNome] = useState('');
  const [data,setData] = useState('');
  const [hora,setHora] = useState('');
  const [token, setToken] = React.useState(null);

function pegaToken(){
  setToken(localStorage.getItem('tokenUser'));
  if(token !== null){
    topoInformacoes();
  }
} 

useEffect(() => {
  setTimeout(() => {
  pegaToken();
  },200);
},[token]) 

const string = History.location.search;
if (string === '?load') {
  History.push("/home");
  window.location.reload();
}

async function topoInformacoes() {
  const headers = { 
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Authorization": 'Bearer'+token
    }
    await api.get('/v1/customers/profile', {
      headers: headers
    })
    .then((response) => { 
       var nomeF = response.data.name;
       if(nomeF.includes(' ')){
       var tmp = nomeF.split(" ");
       nomeF = tmp[0] + " " + tmp[1];
       }
       var data = response.data.last_login;
       var date = data.split(" ")[0];
       var hour = data.split(" ")[1];

       setNome(nomeF);
       setData(date);
       setHora(hour);

    })
}

 function redirecionaApp(){
    History.push('/apps');
  }
  return (
    <div className={classes.root}>
      <div className="GridHome-divEnvolve">
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <div className="border-home">
            <div className="GridHome-envolve-conteudo">
            <div className="GridHome-img">
                <img src={ImageBoasVindas}/>
              </div>
              <div className="GridHome-txt">
                <h1>Olá, {nome}, como vai?</h1>
              <p>Último acesso: {data} às {hora}</p>
              </div>
            </div>
            <h1 className="utilize-app">
              Eu sou a Karina, sua assistente de conta. Vi aqui que você não tem nenhuma integração / aplicativo ativa (o), vamos começar?
              
            </h1>
            <Button className={classes.btnApps} variant="contained" color="secondary" onClick={redirecionaApp}>Começar agora</Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}