import React from 'react';
import './css/index.css'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tabela from './components/tabela';
import AddIcon from '@material-ui/icons/Add';
import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 0,
    paddingTop: 100,
    backgroundColor:'#F3F3F3',
    minHeight: '100vh',
    height: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
  espacamento:{
      padding: 20,
  },
  container:{
    margin: 15,
  },
  icon:{
    marginRight: ".5rem"
  },
  btnIcon:{
    height: "50px",
    marginLeft: "1rem"
  }
}));

export default function AutoGrid(props) {
  const classes = useStyles();

  



  return (
    <div className={classes.root}>
      <div className={classes.container}>
      <Grid container className={classes.espacamento}>
        <Grid item xs={12}>
        
        <div className="cabecalho-personalizado">
            <h1>Links Personalizados</h1>
            <div>
            <Button className={classes.btnIcon} variant="contained"  onClick={() => {props.history.push('/historico-vendas')}}>
                <HistoryIcon className={classes.icon}/>  Ver histórico de vendas
            </Button>
            <Button  className={classes.btnIcon} variant="contained" color="secondary" onClick={() => {props.history.push('/links-personalizados/novo')}}>
                <AddIcon className={classes.icon}/>  Novo Ultra Link
            </Button>
            </div>
        </div>  
        <div className="tabela-personalizada">
          <Tabela/>
        </div>
        </Grid>
      </Grid>
      </div>
   
    </div>
  );
}