import React from 'react';
import Topo from '../../Components/Topo'
import SideMenu from '../../Components/Menu'; 
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Inicial(){



  return(
      <>

        <Topo/>
        <SideMenu/>
        
      </>

  )



}