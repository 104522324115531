import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BoletoPagarme from '../Pagarme/Pagarme';
import BoletoPagSeguro from '../PagSeguro/PagSeguro';
import BoletoMercadoPago from '../MercadoPago/MercadoPago';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
}));

export default function FormApp(infoBoleto) {

  const classes = useStyles();
  const [idApp, setIdApp] = React.useState(infoBoleto.location.state.params.id);
  const [infoApp, setInfoApp] = React.useState(infoBoleto.location.state.params);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
            <Paper className={classes.paper}>
              { idApp=== 23 ? <BoletoPagarme infoApp={infoApp}/>
              : idApp=== 24 ? <BoletoPagSeguro infoApp={infoApp}/>
              : idApp=== 39 ? <BoletoMercadoPago infoApp={infoApp}/>
              :
              'Ainda não possui formulário!'}
            </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
   
    </div>
  );
}