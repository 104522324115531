import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import BlocoFrete from './Components/blocoFrete';
import api from '../../../Services/api';
import '../../App/css/App.css';
import { StateGlobal } from '../../../ContextAPI/context';



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 15,
    marginTop: 100,
   
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    minHeight: '100vh',
    backgroundColor: '#F3F3F3'
  },
}));

export default function Frete() {
  const classes = useStyles();
  const [token, setToken] = useState(null);
  const [idCompanies, setIdCompanies] = useState(null);
  const [dadosFrete, setDadosFrete] = useState('');

  const {confingfrete , app} = StateGlobal();

  async function listaFrete(){

        
    try{
      const response = await api.get('/v1/integrations/shipping/'+confingfrete+'', 
   
      {
       headers:{
          'Accept': 'application/json', 
          'Content-Type': 'application/json',
          //'Authorization': 'Bearer' + token
          'Authorization': 'Bearer' + localStorage.getItem('tokenUser')
       }
      });
      setDadosFrete(response.data);

    }catch(error){
    
    }  
  }



  function pegaToken(){
    setToken(localStorage.getItem('tokenUser'));




    setIdCompanies(confingfrete); //  aqui
    if(token !== null && confingfrete !== null){
      listaFrete();
    }
  } 

  useEffect(() => {
    setTimeout(() => {
      pegaToken();
      listaFrete();
     
    },200);
  },[confingfrete]) 

  return (
    <div className={classes.root}>
     {/* {confingfrete ? confingfrete :"nada chegando"} */}
      <div className="App-divEnvolve">
      <Grid container spacing={2}>
        <Grid item xs={1}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <div className="App-envolve-conteudo">
                <div>
                    <h2>Frete</h2> 
                </div>
                <div className="App-envolve-apps">
                  <BlocoFrete props={dadosFrete}/>
                </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}></Paper>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}