import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Load from '../../../../Components/Load';
import {useHistory} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import '../../../App/css/App.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

export default function BlocoApp(dadosFretes) {
  const dadosFrete = dadosFretes.props;
  const history = useHistory();
  const [search, setSearch] = useState('');


  function abreFormulario(idApp){
    history.push('/app-frete');
    var infoApp = JSON.stringify(idApp);

    localStorage.setItem('idApp',infoApp);
  }
  useEffect(() => {
}, [search]);

return (
    <>
        {dadosFrete !== ''?
     <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={dadosFrete.map((option) => option.name)}
        onSelect={(ev) => setSearch(ev.target.value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Buscar tipo de frete"
            margin="normal"
            variant="outlined"
            color="secondary" 
            InputProps={{
              ...params.InputProps, type: 'search',
              endAdornment: <InputAdornment position="start"><SearchIcon className="iconSearchColor"/></InputAdornment>,
            }}
          />
        )}
      />
      :''}
    {dadosFrete !== ''?
     dadosFrete.map((infoApp) => (
         <div>
             {search ==='' || search ===infoApp.name?
             <div> 
        <div key={infoApp.slug} className="App-envolve-app" >
        <div className="App-envolve-title-circle">
        <div className="App-img-title">
        <div className={infoApp.enable ? "App-circle-status App-circle-status-true": "App-circle-status"}></div>
            <img src={infoApp.photo} className="App-img-logo-empresa"/> 
            <h5>{infoApp.name}</h5>
        </div>
        
            <Button variant="contained" color="secondary" onClick={()=>abreFormulario(infoApp)}>Visualizar</Button> 
        </div>
        <div className="App-envolve-p">
            <p>{infoApp.description}</p>
        </div>
        </div>
         </div>
         :''}
         </div>
     ))
     :
     <div>
         <Load/>
    </div>
}

    </>
);
}