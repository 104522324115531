import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../../../Services/api';
import clsx from 'clsx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CircularLoad from '../../../../../../Components/Load';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import '../../../../../App/Components/Form/Forms_Apps/PicPay/css/PicPay.css';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(3),
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  textStyle: {
    marginTop: '1rem',
  },
  iconLeft: {
    width: '11px',
    height: '11px',
  },
  pTempoRetirada: {
    marginBottom: '0',
    marginLeft: '.5rem',
  },
  divTempoPrazo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%'
  },

}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      isNumericString
      prefix="R$"

      format={currencyFormatter}
    />
  );
}

function retiradaPrazo(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={true}
      isNumericString
      format="##"
    />
  );
}

function currencyFormatter(value) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  }).format(value / 100);

  return `${amount}`;
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

retiradaPrazo.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export default function FormPersonalizado() {
  const history = useHistory();
  const classes = useStyles();
  const [id, setId] = React.useState('');
  const [photo, setPhoto] = React.useState('');
  const [name, setName] = React.useState('');
  const [load, setLoad] = React.useState(false);
  const [statusApp, setStatusApp] = React.useState('');
  const [enableApp, setEnableApp] = React.useState('');
  const [checked, setChecked] = React.useState('');
  const [tempoEntrega, setTempoEntrega] = React.useState('');
  const [estado, setEstado] = React.useState([
    { title: 'Acre', sigla: 'AC', selected: false },
    { title: 'Alagoas', sigla: 'AL', selected: false },
    { title: 'Amapá', sigla: 'AP', selected: false },
    { title: 'Amazonas', sigla: 'AM', selected: false },
    { title: 'Bahia', sigla: 'BA', selected: false },
    { title: "Ceará", sigla: 'CE', selected: false },
    { title: 'Distrito Federal', sigla: 'DF', selected: false },
    { title: 'Espírito Santo', sigla: 'ES', selected: false },
    { title: 'Goiás', sigla: 'GO', selected: false },
    { title: 'Maranhão', sigla: 'MA', selected: false },
    { title: 'Mato Grosso', sigla: 'MT', selected: false },
    { title: 'Mato Grosso do Sul', sigla: 'MS', selected: false },
    { title: 'Minas Gerais', sigla: 'MG', selected: false },
    { title: 'Pará', sigla: 'PA', selected: false },
    { title: 'Paraíba', sigla: 'PB', selected: false },
    { title: "Paraná", sigla: 'PR', selected: false },
    { title: 'Pernambuco', sigla: 'PE', selected: false },
    { title: 'Piauí', sigla: 'PI', selected: false },
    { title: 'Rio de Janeiro', sigla: 'RJ', selected: false },
    { title: 'Rio Grande do Norte', sigla: 'RN', selected: false },
    { title: 'Rio Grande do Sul', sigla: 'RS', selected: false },
    { title: 'Rondônia', sigla: 'RO', selected: false },
    { title: 'Roraima', sigla: 'RR', selected: false },
    { title: "Santa Catarina", sigla: 'SC', selected: false },
    { title: 'São Paulo', sigla: 'SP', selected: false },
    { title: 'Sergipe', sigla: 'SE', selected: false },
    { title: 'Tocantins', sigla: 'TO', selected: false },
  ]);
  const [verificaVazioTempoEntrega, setVerificaVazioTempoEntrega] = React.useState(false);
  const [verificaVazioEstado, setVerificaVazioEstado] = React.useState(false);
  const [listaEstado, setListaEstado] = React.useState([]);
  const [idCompanies, setIdCompanies] = React.useState(localStorage.getItem('companiesId'));
  const [token, setToken] = React.useState(localStorage.getItem('tokenUser'));
  const [messageErro, setMessageErro] = React.useState('');
  const [messageSuccess, setMessageSuccess] = React.useState('');
  const [ufatual, setUfatual] = useState(false);
  const [controle, setControle] = useState(false)
  const [onchangeUf , setOnchangeUf] = useState();


  function mostraStatus() {
    var alert = document.getElementById("AlertStatus");
    alert.style.display = 'block';
  }

  function removeStatus() {
    var alert = document.getElementById("AlertStatus");
    alert.style.display = 'none';
  }

  function checkEnable() {
    if (checked === true) {
      setChecked(false);
      setEnableApp(0);
    }
    else {
      setChecked(true);
      setEnableApp(1);
    }
  }

  function cancelarApp() {
    history.push('/configuracoes-frete');
  }

  const capturaTempoEntrega = (prop) => (event) => {
    setTempoEntrega(event.target.value);
  };


  async function buscaApp() {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
    await api.get('/v1/integrations/shipping/' + idCompanies, {
      headers: headers
    })
      .then((response) => {
        var dados = response.data;
        for (let i = 0; dados.length > i; i++) {
          if (dados[i].slug === 'frete-gratis') {
            setId(dados[i].slug);
            setPhoto(dados[i].photo);
            setName(dados[i].name);
            setStatusApp(dados[i].authenticated);
            setEnableApp(dados[i].enable);
            setChecked(dados[i].enable);
            buscaDadosApp(dados[i].credentials);
          }
        }
      })
      .catch((error) => {

      })
  }

  useEffect(() => {
    buscaApp();
  }, [controle])


  useEffect(() => {
  }, [ufatual])


  async function enviaFormApp(event) {
    event.preventDefault();
    setLoad(true)
    if(onchangeUf =='' || !onchangeUf || onchangeUf==undefined || tempoEntrega === '' || tempoEntrega === undefined){
      setLoad(false)
      if(onchangeUf=='' || !onchangeUf || onchangeUf==undefined){
        setVerificaVazioEstado(true);
      }if(tempoEntrega==='' || tempoEntrega === undefined){
        setVerificaVazioTempoEntrega(true);
      } 
    }else{
      setVerificaVazioEstado(false);
      setVerificaVazioTempoEntrega(false);
    var data = {
      "enable": enableApp,
      "delivery_time": tempoEntrega,
      "company_id": idCompanies,
      "integration_slug": "frete-gratis",
      "state_filter": onchangeUf,
      "state_city_filter": ["todos"]
    }

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer' + token,
    }
    await api.post('/v1/integrations/shipping', data, {
      headers: headers
    })
      .then((response) => {
        setLoad(false)
        setMessageErro('');
        setMessageSuccess('Informações salvas com sucesso');
        setTimeout(() => {
          setMessageSuccess('');
        }, 1500);
        setTimeout(() => {
          history.push('/configuracoes-frete');
        }, 2000);
      })
      .catch((error) => {
        setLoad(false)
        setMessageSuccess('');
        setMessageErro(error.response.data.errors.message);
        setTimeout(() => {
          setMessageErro('');
        }, 1500);
      })
    }
  }

  function buscaDadosApp(credentials) {
    
    const arr = [];
    if(credentials.length === 0){
      setUfatual(credentials)
    }
   
    setTempoEntrega(credentials.delivery_time);
    setOnchangeUf(credentials.state_filter)
    var listaEstado = credentials.state_filter;

    

    listaEstado.map(function (item) {
      estado.map(function (itemS, index) {

        if (itemS.sigla === item) {
          estado[index].selected = true
          arr.push(itemS)
         

        }
        var array = estado;
        if(array.length > 0){
          setEstado(array)

        }else{
          setEstado([])

        }
      
      })


    })
    setUfatual(arr)
  }


  return (
    <div className="Apps-form-style">
      <div className="App-suporte">
        <a target="_blank" href="https://lsbr-portalcliente.atlassian.net/servicedesk/customer/portal/6">Preciso de ajuda <ArrowForwardIosIcon className={classes.iconLeft} /></a>
      </div>
      <div className="App-form-style">
        <form onSubmit={enviaFormApp} className="PicPay-form" noValidate autoComplete="off">
          <div className="PicPay-envolve-img-text-switch">
            <div className="PicPay-envolve-img-text">
              <div className={checked ? "App-circle-status App-circle-status-true" : "App-circle-status"} onMouseEnter={mostraStatus} onMouseOut={removeStatus}></div>
              <img className="PicPay-logo" src={photo} />
              <h1 className="PicPay-title">{name}</h1>
            </div>

           
            <FormGroup aria-label="position" row>
              <FormControlLabel
                onClick={checkEnable}
                value={enableApp}
                checked={checked}
                control={<Switch color="secondary" />}
                label="Ativo:"
                labelPlacement="start"
              />
            </FormGroup>

          </div>
          <div id="AlertStatus" className="AlertStatus">
            {checked ?
              <Alert variant="filled" severity="success">
                Status: Ativo
            </Alert>
              :
              <Alert variant="filled" severity="error">
                Status: Inativo
            </Alert>
            }
          </div>
          <div className="PicPay-link-tutoriais">
        <p className="PicPay-p-form">Informações da aplicação:</p>
            <a target="_blank" href="https://blog.payhow.com.br/2021/06/07/frete-gratis/">Como obter as informações do Frete Grátis?</a>
        </div>
          {messageErro !== '' ?
            <Alert className="messagensFixed" variant="filled" severity="error">
              {messageErro}
            </Alert>
            : ''}
          {messageSuccess !== '' ?
            <Alert className="messagensFixed" variant="filled" severity="success">
              {messageSuccess}
            </Alert>
            : ''}
          <FormControl className={classes.root} component="fieldset">
            <FormGroup aria-label="position" row>
              <div className={classes.divTempoPrazo}>
                <TextField
                  className={clsx(classes.textStyle)}
                  color="secondary"
                  id="tempoEntrega"
                  label="Prazo mínimo de entrega"
                  type="tel"
                  value={tempoEntrega}
                  onChange={capturaTempoEntrega()}
                  variant="outlined"
                  required
                  InputProps={{
                    inputComponent: retiradaPrazo,
                  }}
                  error={verificaVazioTempoEntrega? true : false}
                  helperText={verificaVazioTempoEntrega? "*Campo obrigatório" : ''}
                />
                <p className={classes.pTempoRetirada}>dia(s)</p>
              </div>

            </FormGroup>
            {ufatual ? 
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={estado}
              color="secondary"
              defaultValue={ufatual}
              getOptionLabel={(option) => option.title}
              
              closeIcon={false}
              onChange={(event,value) => {
                const siglasuf = []
                value.map((item)=>{
                  siglasuf.push(item.sigla)

                })
                setOnchangeUf(siglasuf)
              }}
              renderInput={(params) => (
                <TextField id="inputEstado"  {...params}  error={verificaVazioEstado ? true : false}
                helperText={verificaVazioEstado ? "*Campo obrigatório" : ''} variant="outlined" color="secondary" label="Estados disponíveis para frete grátis" placeholder="Escolha os estados disponíveis para frete grátis" />
              )}
            />
            :''}
          </FormControl>
          <div className="PiPay-button">
            <Button variant="contained" onClick={cancelarApp}>Cancelar</Button>
            <Button type="submit" variant="contained" color="secondary">{!load?'Salvar informações':<CircularLoad/>}</Button>
          </div>
        </form>
      </div>
    </div>
  );
}
