import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import '../../css/Form.css';
import BtnEsqueciSenha from '../Modal/Modal';
import api from '../../../../Services/api';
import Alert from '@material-ui/lab/Alert';
import CircularLoad from '../../../../Components/Load';
import {useHistory} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,   
    }
}));
export default function FormLogin() {
    const history = useHistory();
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [email, setEmail] = React.useState();
    const capturaEmail = (prop) => (event) => {
        setEmail(event.target.value );
    };
    const [resultado, setResultado] = React.useState(true);
    const [erroLogin, setErroLogin] = React.useState();
    
    async function Logar(event) {
        event.preventDefault();
        setResultado(false);
        var data = {
          'email': email,
          'password': values.password
          }
          const headers = { 
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
            var alert = document.getElementById('loginErroId');
            await api.post('/v1/customers/login', data, {
              headers: headers
            })
            .then((response) => {
            alert.style.display = 'none';
           
            var dados = response.data;
            setResultado(true);
            history.push('/home?load');   
            const token = dados.token
            const companiesId = localStorage.getItem('companiesId')
            localStorage.setItem('tokenUser',token);
            })
            .catch((error) => {
              
            alert.style.display = 'block';
            setErroLogin(error.response.data.errors.message);
            setResultado(true);
            })
      }

    const classes = useStyles();
    return (
        <div>
        <form className={classes.root} onSubmit={Logar}  autoComplete="off">
                <div id="loginErroId" className="login-erro">
                   <Alert variant="filled" severity="error">
                        {erroLogin}
                    </Alert>
                </div> 
            <TextField
                id="emailLogin" 
                label="E-mail"
                type="email"
                value={email}
                autoComplete="username"
                onChange={capturaEmail()}
                required
                variant="outlined" />
            <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
            <InputLabel required htmlFor="password">Senha</InputLabel>
            <OutlinedInput
                id="password"
                required
                autoComplete="current-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                
                onChange={handleChange('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                labelWidth={70}
            />
            </FormControl>
                <div className="login-ajuste-center">
                    <button type="submit" className="login-btn_entrar"> {resultado ? 'ENTRAR' : <CircularLoad/>}</button>
                </div>
        </form>
         <div className="login-ajuste-center">
           <BtnEsqueciSenha/>
           </div>
           </div>
  );
}